<script>
import ModalDiscountComponent from '../../features/modals/ModalDiscountComponent';
export default {
  extends: ModalDiscountComponent,
  name: 'ModalDiscountView',
};
</script>

<template>
  <el-dialog
    title="Descuento"
    :visible.sync="conf.isVisible"
    width="30%"
    custom-class="discount-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Descuento</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Plan de Inscripción" prop="subscriptionPlan">
              <el-select v-model="formData.subscriptionPlan" remote clearable filterable placeholder="Buscar..." :remote-method="onSearchSubscriptionPlans" value-key="id" size="small">
                <el-option v-for="item in subscriptionPlans" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col>
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-row class="row-bg" :gutter="20">
          <el-col>
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col text-align="center">
            <el-form-item label="Disponibilidad" prop="reportDate">
              <el-date-picker
                v-model="formData.reportDate"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="-"
                start-placeholder="F. Inicio"
                end-placeholder="F. Fin"
                value-format="yyyy-MM-dd"
                format="dd MMM yyyy"
                size="small">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" text-align="center">
            <el-form-item label="Cantidad (%)" prop="amount">
              <el-input v-model="formData.amount" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" text-align="center">
            <el-form-item label="Capacidad de Usuarios" prop="max">
              <el-input v-model="formData.max" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>