import _ from 'lodash'
import FileHelper from '../../../shared/helpers/FileHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import Career from '../../setting/models/Career';
import HighSchool from '../../setting/models/HighSchool';
import University from '../../setting/models/University';
import Student from '../models/Student'

export default class StudentViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new Student({
      state: "create",
      userProfile: {
        status: 1
      }
    });
    return item;
  }

  onDelete(data) {
    let item = new Student(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new Student(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.students.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Student(data));
  }

  onSearchItem(id) {
    return this.api.students.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.students.create(this.mappingFormDataToSend(data.toSend(), this.view.profileImageFile))
    else
      return this.api.students.update(data.id, this.mappingFormDataToSend(data.toSend(), this.view.profileImageFile));
  }

  onDestroy(context, id) {
    context.api.students.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.students.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.students.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {
    let profileUrlBase = '/images/left-imgs/img-1.jpg';
    this.api.universities.find()
      .then((response) => {
        this.view.universities = response.data.data;
      })
    if (this.view.formData.state === 'update') {
      this.view.profileImageUrl = (_.isNil(this.view.formData.userProfile.profileImageUrl)) ? profileUrlBase : this.view.formData.userProfile.profileImageUrl;
      //this.view.universities = (this.view.formData.universityToApply) ? [this.view.formData.universityToApply] : []
      this.view.universitiesToApply = this.mapUniversities(this.view.formData.studentContentUniversities)
      this.view.careers = (this.view.formData.careerToApply) ? [this.view.formData.careerToApply] : []
      this.view.highSchools = (this.view.formData.highSchool) ? [this.view.formData.highSchool] : []
    }
  }

  // #endregion

  // #region CREATES
  /**
   * Metodo para crear universidad
   * @param {String} data Query string para parametros de creación
   */
  
  createUniversities(data) {
    this.api.universities.createInStudent(new University ({name: data}))
      .then((response) => this.view.onLoadUniversity(response.data.data))
      .catch(this.view.onError)
  }
  /**
   * Metodo para crear preparatoria
   * @param {String} data Query string para parametros de creación
   */
  
  createHighSchools(data) {
    this.api.highSchools.createInStudent(new HighSchool ({name: data}))
      .then((response) => this.view.onLoadHighSchool(response.data.data))
      .catch(this.view.onError)
  }
  /**
   * Metodo para crear universidad
   * @param {String} data Query string para parametros de creación
   */
  
  createCareers(data) {
    this.api.careers.createInStudent(new Career ({name: data}))
      .then((response) => this.view.onLoadCareer(response.data.data))
      .catch(this.view.onError)
  }
  // #endregion
  // #region QUERIES

  /**
   * Metodo para buscar preparatorias
   * @param {String} data Query string para parametros de búsqueda
   */
  findHighSchools(data) {
    this.api.highSchools.find(data)
      .then((response) => this.view.onSearchHighSchoolsResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar universidad
   * @param {String} data Query string para parametros de búsqueda
   */
  findUniversities(data) {
    this.api.universities.find(data)
      .then((response) => this.view.onSearchUniversitiesResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar carrera
   * @param {String} data Query string para parametros de búsqueda
   */
  findCareers(data, universityName) {
    this.api.careers.find(data)
      .then((response) => this.view.onSearchCareersResponse(response.data.data, universityName))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar estados de usuario
   * @param {String} data Query string para parametros de búsqueda
   */
  findUserStatuses() {
    this.api.userStatuses.find()
      .then((response) => this.view.onSearchUserStatusesResponse(response.data.data))
      .catch(this.view.onError)
  }

  doChangeStatus(data) {
    this.api.students.doChangeStatus(data)
      .then((response) => this.view.onChangeStatusResponse(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  //#region MAPPING DATA

  mappingFormDataToSend(data, profileImageFile) {
    const fd = new FormData()
    fd.append('profileImageFile', profileImageFile)
    fd.append('student', JSON.stringify(data))
    return fd;
  }

  convertFileToBase64(file) {
    return FileHelper.toBase64(file);
  }

  mapUniversities(universitiesToApply) {
    return _.map(universitiesToApply, (item) => {
      return item.university.name
    });
  }

  
  // #endregion

  // #region VALIDATIONS
  /**
   * Metodo para verificar si password con password de confirmación son iguales
   * @param {String} value Password a confirmar 
   * @param {*} callback Metodo de respuesta a la verificación
   */
  verifyConfirmationPassword(value, callback) {
    if (value === '') {
      callback(new Error('Favor de ingresar información.'))
    } else if (value !== this.view.formData.userProfile.password) {
      callback(new Error('Las contraseñas no coinciden.'))
    } else {
      callback()
    }
  }

  // #endregion
}