import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import CourseSubjectActivityViewModel from '../../../../core/features/course/viewmodels/CourseSubjectActivityViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            parentFilter: '',
            parents: [],
            viewModel: null,
            permissions: null,
            modules: [],
            courseSubjectActivityTypes: [],
            hasFile: false,
            hasVideo: false,
            editorDescription: null,
            activityFile: null,
            activityFileUrl: null,
            isActivityFileTemp: false,
            answerEditors: [],
            isAnswerLoading: false,
            rules: {
                courseSubjectActivityType: [
                    { required: true, message: 'Tipo de actividad requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                duration: [
                    { required: true, message: 'Duración requerido.', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ]
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectActivityViewModel(this);
        this.onActive();
    },
    mounted() { },
    methods: {
        onActive() {
            this.conf.isLoading = true;
            this.viewModel.onBindFormView();
        },
        onBindFormViewResponse() {
            this.formData.bindAnswers()
            this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
            this.conf.isLoading = false;
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.viewModel.onBindFormDataToSend();
                    EventBus.$emit('onSubmitedCourseSubjectActivity', this.formData.toSend(this.answerEditors));
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedCourseSubjectActivity', null);
        },
        onSearchCourseSubjectActivityTypesResponse(response) {
            this.courseSubjectActivityTypes = response.data;
        },
        onActivityTypeChange(activityType) {
            console.log('on change activity type: ', activityType);
        },
        onCreateCourseSubjectActivityAnswer() {
            this.isAnswerLoading = true
            setTimeout(() => {
                this.formData.addAnswer()
            }, 200);
            setTimeout(() => {
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                this.isAnswerLoading = false
            }, 200);
        },
        onDeleteCourseSubjectActivityAnswer(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning' })
                .then(() => {
                    this.isAnswerLoading = true
                    setTimeout(() => {
                        this.formData.removeAnswer(index)
                        this.viewModel.removeAnswerEditor(this.answerEditors, index)
                        this.$forceUpdate()
                    }, 200);
                    setTimeout(() => {
                        this.$forceUpdate()
                        this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                        this.isAnswerLoading = false
                    }, 200);
                })
                .catch(() => {

                });
        },
        onSuccessUploadActivityFile(response) {
            // console.log('on success: ', response)
        },
        async onBeforeUploadActivityFile(file) {
            //console.log('on before', file)
            const isPdf = file.type === 'application/pdf';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isPdf) {
                this.$message.error('El Archivo debe estar en formato PDF!');
            }
            if (!isLt2M) {
                this.$message.error('El Archivo excede los 2MB!');
            }
            if (isPdf & isLt2M) {
                let dataUrl = await this.viewModel.convertFileToBase64(file)
                this.activityFileUrl = (dataUrl) ? dataUrl : '/images/courses/add_img.jpg'
                this.activityFile = file;
                this.isActivityFileTemp = true;
            }
            return isPdf && isLt2M;
        },
        onRemoveActivityFile() {
            // this.activityFileUrl = '/images/courses/add_img.jpg'
            this.activityFile = null;
            this.isActivityFileTemp = false;
            this.formData.fileUrl = null;
        },
        onShowActivityFile(fileUrl) {
            var link = document.getElementById('someLink')
            if (link) {
                link.id = 'someLink'; //give it an ID!
                link.href = (fileUrl) ? fileUrl : this.activityFileUrl;
                link.target = "_blank"
            } else {
                link = document.createElement("a")
                link.id = 'someLink'; //give it an ID!
                link.href = (fileUrl) ? fileUrl : this.activityFileUrl;
                link.target = "_blank"
                document.body.appendChild(link);
            }

            document.getElementById('someLink').click();
        },
        onChangeMultipleResponse(isMultiple) {
            this.isAnswerLoading = true
            
            setTimeout(() => {
                if(isMultiple)  
                    this.formData.initDetaultAnswers() 
                else 
                    this.formData.removeAllAnswers()
            }, 200);

            setTimeout(() => {
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                this.isAnswerLoading = false
            }, 200);
            
        },
        onUpdateAnswer(answer) {
            this.viewModel.updateAnswer(answer);
        }
    }
}