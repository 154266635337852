<script>
import ModalSubscriptionPlanComponent from '../../features/modals/ModalSubscriptionPlanComponent';
export default {
  extends: ModalSubscriptionPlanComponent,
  name: 'ModalSubscriptionPlanView',
};
</script>

<template>
  <el-dialog
    title="Plan de Inscripción"
    :visible.sync="conf.isVisible"
    width="30%"
    custom-class="subscription-plan-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información del Plan</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Categoria" prop="type">
              <el-select v-model="formData.type" clearable filterable placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in types" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" text-align="center">
            <el-form-item label="Cantidad de Pagos" prop="paymentQuantity">
              <el-input-number v-model.number="formData.paymentQuantity" size="small"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" text-align="center">
            <el-form-item label="Precio" prop="amount">
              <el-input-number v-model="formData.amount" :precision="2" :step="0.1" size="small"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" maxlength="70" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Descripción" prop="description">
              <el-input v-model="formData.description" maxlength="155" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>