<script>
import ModalStudentInfoComponent from '../features/ModalStudentInfoComponent';
export default {
  extends: ModalStudentInfoComponent,
  name: 'ModalStudentInfoView',
};
</script>
<template>
  <el-dialog
    title="Información de Inscripción"
    custom-class="subscription-account-modal"
    :visible.sync="conf.isVisible"
    width="30%"
    :before-close="onCancel"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Datos de Inscripción</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Universidades a la que desea aplicar" prop="university" class="universities">
            <el-select v-model="formData.university" placeholder="Seleccionar"
            value-key="id"
            filterable
            clearable
            size="medium"
            @change="onChangeUniveristy">
              <el-option
                v-for="item in universities"
                :key="item.id"
                :label="item.name"
                :value="item">
              </el-option>
            </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Carreras a las que deseas aplicar" prop="careersToApply" v-if="formData.university">
              <el-checkbox-group v-model="formData.careersToApply">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(career) in careers" :key="career.id">
                  <el-checkbox :label="career.id" size="small" align="center">{{career.name}}</el-checkbox>
                </el-col>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="Preparatoria que cursa o cursó"
              prop="highSchool"
            >
              <el-select
                v-model="formData.highSchool"
                allow-create
                clearable
                filterable
                remote
                :remote-method="onSearchHighSchools"
                @change="onChangeSelectHighSchools"
                placeholder="Seleccionar"
                value-key="id"
                size="small"
              >
                <el-option
                  v-for="item in highSchools"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="¿Por qué quiere estudiar la(s) carrera(s) seleccionada(s)?"
              prop="selectedCareerObservation"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.selectedCareerObservation"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="¿Qué espera de esta asesoría?"
              prop="advisoryObservation"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.advisoryObservation"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="¿Qué materia se le dificulta más entre matemáticas y español? y ¿Por qué?"
              prop="subjectComplicationObservation"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="formData.subjectComplicationObservation"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="¿Padece de Alguna Enfermedad o Condición Especial?">
                  <el-switch
                    style="display: block"
                    v-model="formData.hasDiseaseOrSpecialCondition"
                    active-color="#13ce66"
                    inactive-text="No"
                    active-text="Si"
                    :inactive-value="false"
                    :active-value="true"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Dirección" prop="homeAddress">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.homeAddress"
                    maxlength="255"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Acerca de ti" prop="about">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.about"
                    maxlength="255"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        class="btn btn-default steps_btn btn-primary finish"
        size="medium"
        >Enviar</el-button
      >
    </span>
  </el-dialog>
</template>
