import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import CourseSubjectTestViewModel from '../../../../core/features/course/viewmodels/CourseSubjectTestViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            answerEditors: [],
            questionEditor: null,
            isAnswerLoading: false,
            rules: {
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                description: [
                    { validator: this.validateDescription }
                ],
            },
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectTestViewModel(this);
        this.onActive();
    },
    mounted() {
        this.$root.$on('update-question-editor', this.onUpdateEditor);
    },
    beforeDestroy() {

    },
    methods: {
        onActive() {
            this.conf.isLoading = true;
            this.viewModel.bindQuestionFormView(this.formData);
        },
        bindQuestionFormViewResponse() {
            setTimeout(() => {
                this.formData.bindAnswers()
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                this.conf.isLoading = false;
            }, 200);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    EventBus.$emit('onSubmitedCourseSubjectQuestion', this.viewModel.saveQuestion(this.formData));
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onAddAnswer() {
            this.isAnswerLoading = true
            setTimeout(() => {
                this.formData.addAnswer()
            }, 200);
            setTimeout(() => {
                this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                this.isAnswerLoading = false
            }, 200);
            // this.viewModel.addNewAnswer(this.formData.courseSubjectTestQuestionAnswers);

        },
        onDeleteAnswer(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning' })
                .then(() => {
                    this.isAnswerLoading = true
                    setTimeout(() => {
                        this.formData.removeAnswer(index)
                        this.viewModel.removeAnswerEditor(this.answerEditors, index)
                        this.$forceUpdate()
                    }, 200);
                    setTimeout(() => {
                        this.$forceUpdate()
                        this.answerEditors = this.viewModel.renderAnswerEditors(this.formData.getAnswers(), this.answerEditors)
                        this.isAnswerLoading = false
                    }, 200);
                })
                .catch(() => {

                });
        },
        onUpdateEditor(data) {
            this.$set(this.formData, 'description', data);
        },
        validateDescription(rule, value, callback) {
            this.viewModel.isDescriptionValid(rule, value, callback)
        }
    }
}