import _ from 'lodash';
import QuillEditorHelper from '../../../shared/helpers/QuillEditorHelper'
import FileHelper from '../../../shared/helpers/FileHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import Post from '../models/Post';

export default class PostViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new Post({
      state: "create",
      userProfile: {
        status: 1
      }
    });
    return item;
  }

  onDelete(data) {
    let item = new Post(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new Post(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.posts.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Post(data));
  }

  onSearchItem(id) {
    return this.api.posts.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.posts.create(this.mappingFormDataToSend(data.toSend(), this.view.postImageFile))
    else
      return this.api.posts.update(data.id, this.mappingFormDataToSend(data.toSend(), this.view.postImageFile));
  }

  onDestroy(context, id) {
    context.api.posts.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.posts.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.posts.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {
    let profileUrlBase = '/images/left-imgs/img-1.jpg';
    if (this.view.formData.state === 'update') {
      this.view.editorAbout.root.innerHTML = this.view.formData.description;
      this.view.postImageUrl = (_.isNil(this.view.formData.imageUrl)) ? profileUrlBase : this.view.formData.imageUrl;
    }
  }

  onBindEditor(selector) {
    this.view.editorAbout = QuillEditorHelper.buildEditor(selector);
  }

  /**
   * Metodo para actualizar estado de publicado
   * @param {String} data Query string para parametros de búsqueda
   */
  doPublish(data) {
    this.api.posts.doPublish(data)
      .then((response) => this.view.onDoPublishResponse(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region QUERIES
  /**
   * Metodo para buscar publishedStatuses
   * @param {String} data Query string para parametros de búsqueda
   */
  findPublishedStatuses() {
    this.api.publishedStatuses.find()
      .then((response) => this.view.onSearchPublishedStatusesResponse(response.data.data))
      .catch(this.view.onError)
  }
  

  // #endregion

  //#region MAPPING DATA

  mappingFormDataToSend(data, postImageFile) {
    const fd = new FormData()
    fd.append('postImageFile', postImageFile)
    fd.append('post', JSON.stringify(data))
    return fd;
  }

  convertFileToBase64(file) {
    return FileHelper.toBase64(file);
  }

  // #endregion

  // #region VALIDATIONS
  
  // #endregion
}