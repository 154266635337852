import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import Evaluation from '../models/Evaluation'

export default class EvaluationViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new Evaluation({
      state: "create",
      status: 1
    });
    return item;
  }

  onDelete(data) {
    let item = new Evaluation(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new Evaluation(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.evaluations.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Evaluation(data));
  }

  onSearchItem(id) {
    return this.api.evaluations.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.evaluations.create(data.toSend())
    else
      return this.api.evaluations.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.evaluations.delete(id)
        .then(response => context.view.onSuccess(response.data))
        .catch(context.view.onError)
}

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.evaluations.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.evaluations.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {
    if (this.view.formData.state === 'update') {
      this.view.students = [this.view.formData.student]
      this.view.courses = [this.view.formData.course]
    }
  }

  // #endregion

  // #region QUERIES
  /**
   * Metodo para buscar estudiantes
   * @param {String} data Query string para parametros de búsqueda
   */
  findStudents(data) {
    this.api.students.find(data)
      .then((response) => this.view.onSearchStudentsResponse(response.data.data))
      .catch(this.view.onError)
  }
  /**
   * Metodo para buscar cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findCourses(data) {
    this.api.courses.find(data)
      .then((response) => this.view.onSearchCoursesResponse(response.data.data))
      .catch(this.view.onError);
  }

  // #endregion

  //#region MAPPING DATA

  
  // #endregion

  // #region VALIDATIONS

  // #endregion
}
