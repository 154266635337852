import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import SimulatorOfficialSectionViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialSectionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            editors: [],
            questionEditor: null,
            isQuestionsLoading: false,
            courseCategories: [],
            modalItemQuestion: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
                questionQuantity: [
                    { validator: this.validateQuestionQuantity }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                courseCategory: [
                    { required: true, message: 'Área de Conocimiento requerido.', trigger: 'change' }
                ],
                description: [
                    { validator: this.validateDescription }
                ],
            },
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorOfficialSectionViewModel(this);
        this.onActive();
    },
    mounted() { 
        EventBus.$on('onSubmitedSimulatorSectionQuestions', this.onSaveSimulatorSectionQuestions);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSimulatorSectionQuestions', this.onSaveSimulatorSectionQuestions);
    },
    methods: {
        onActive() {
            this.viewModel.bindQuestionFormView(this.formData);
            console.log(this.formData)
        },
        collectionCourseSubjectTestQuestionAnswers(collection) {
            return _.filter(collection, function(o) { 
                if(o.updateState !== 4) return o; 
            });
        },
        onFindCourseCategory(data) {
            if (data) {
                this.viewModel.findCourseCategories(`Name=${data}`)
            } else {
                this.courseCategories = []
            }
        },
        onFindCourseCategoriesResponse(response) {
            this.courseCategories = response;
        },
        onFocus() {
            this.$forceUpdate()
        },
        onAddQuestion(){
            if(this.viewModel.validateFormQuestion(this.formData.questionQuantity)) {
                this.itemQuestion = this.viewModel.createQuestion(this.formData.sectionSimulatorOfficialQuestions);
                this.itemQuestion.questionQuantity = this.formData.questionQuantity;
                this.viewModel.showModal(this.modalItemQuestion, true);
            }
        },
        onSaveSimulatorSectionQuestions(collection) {
            this.viewModel.addNewQuestions(this.formData.simulatorQuestions, collection);
            this.viewModel.showModal(this.modalItemQuestion, false);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    EventBus.$emit('onSubmitedSimulatorSection', this.viewModel.saveQuestion(this.formData));
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onAddAnswer() {
            this.viewModel.addNewAnswer(this.formData.courseSubjectTestQuestionAnswers);
        },
        onDeleteAnswer(index) {
            this.formData.courseSubjectTestQuestionAnswers[index].updateState = 4;
            this.editors[index].updateState = 4;
            this.$forceUpdate()
            //this.viewModel.removeAnswer(this.formData.courseSubjectTestQuestionAnswers, index);
        },
        onUpdateEditor(data) {
            this.$set(this.formData, 'description', data);
        },
        validateDescription(rule, value, callback) {
            this.viewModel.isDescriptionValid(rule, value, callback)
        },
        validateQuestionQuantity(rule, value, callback) {
            this.viewModel.isQuestionQuantityValid(rule, value, callback, this.formData.totalPartialQuestion, this.formData.limitQuestion)
        },
        getCouseCategoryName(courseCategory) {
            return this.viewModel.getCouseCategoryName(courseCategory);
        },
        sortSimulatorSectionQuestions(collection) {
            return this.viewModel.filterAndSortSectionQuestions(collection);
        },
        onChangeDrag(collection) {
            this.viewModel.changeDrag(collection);
        }
    }
}