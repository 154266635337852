import _ from 'lodash'
import QuillEditorHelper from '../../../shared/helpers/QuillEditorHelper'
import FileHelper from '../../../shared/helpers/FileHelper'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import Course from '../models/Course'
import Instructor from '../../instructors/models/Instructor'
import CourseSubject from '../models/CourseSubject'
import University from '../../setting/models/University'
import Student from '../../students/models/Student'

export default class CourseViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  static UPDATE_STATES = {
    ADD: 2,
    EDIT: 3,
    REMOVE: 4,
  }

  //#region CRUD

  onCreate() {
    let item = new Course({
      state: "create",
      status: 1,
      courseSubjects: [],
      instructors: [],
    });
    return item;
  }

  onCreateCourseSubject(nextIndex) {
    let item = new CourseSubject({
      state: "create",
      status: 1,
      sort: nextIndex,
      updateState: CourseViewModel.UPDATE_STATES.ADD,
      courseSubjectActivities: []
    });
    return item;
  }
  onUpdateCourseSubject(data) {
    let item = new CourseSubject(data);
    item.updateState = (!item.id) ? CourseViewModel.UPDATE_STATES.ADD : CourseViewModel.UPDATE_STATES.EDIT;
    return item;
  }

  onCreateStudentInfo(data) {
    var item = new Student(data);
    item.state = 'create';
    return item
  }

  onDelete(data) {
    let item = new Course(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new Course(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.courses.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Course(data));
  }

  onSearchItem(id) {
    return this.api.courses.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.courses.create(this.mapDataToFormData(data.toSend(), this.view.coverImageFile, data.courseSubjects));
    else
      return this.api.courses.update(data.id, this.mapDataToFormData(data.toSend(), this.view.coverImageFile, data.courseSubjects));
  }

  onDestroy(context, id) {
    context.api.courses.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.courses.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.courses.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  copyRegister(id) {
    this.api.courses.copyRegister(id)
      .then(response => this.view.onCopyResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {
    if (this.view.formData.state === 'update') {
      this.view.courseCategories = [this.view.formData.courseCategory]
      this.view.coverImageUrl = (this.view.formData.coverImageUrl) ? this.view.formData.coverImageUrl : this.view.coverImageUrl;
      this.view.editorAbout.root.innerHTML = this.view.formData.about;
      this.view.dynamicTags = (this.view.formData.tags) ? this.view.formData.tags.split(',') : [];
      this.view.formData.instructors = this.mapInstructors(this.view.formData.instructors);
      this.view.universities = [this.view.formData.university]
    }
  }

  onBindEditor(selector) {
    this.view.editorAbout = QuillEditorHelper.buildEditor(selector);
  }

  // #endregion

  // #region CREATES
  /**
   * Metodo para crear universidad
   * @param {String} data Query string para parametros de creación
   */

  createUniversities(data) {
    this.api.universities.createInStudent(new University({ name: data }))
      .then((response) => this.view.onLoadUniversity(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region QUERIES

  /**
   * Metodo para buscar categorias de cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findCourseCategories(data) {
    this.api.courseCategories.find(data)
      .then((response) => this.view.onSearchCourseCategoriesResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar autores
   * @param {String} data Query string para parametros de búsqueda
   */
  findAuthors(data) {
    this.api.users.find(data)
      .then((response) => this.view.onSearchAuthorsResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar universidad
   * @param {String} data Query string para parametros de búsqueda
   */
  findUniversities(data) {
    this.api.universities.find(data)
      .then((response) => this.view.onSearchUniversitiesResponse(response.data.data))
      .catch(this.view.onError)
  }

  /**
   * Metodo para buscar publishedStatuses
   * @param {String} data Query string para parametros de búsqueda
   */
  findPublishedStatuses() {
    this.api.publishedStatuses.find()
      .then((response) => this.view.onSearchPublishedStatusesResponse(response.data.data))
      .catch(this.view.onError)
  }

  findByAuthor() {
    this.view.showLoading(true, 'Actualizando registros...');
    this.api.courses.findByAuthor(this.getSearchQuery())
      .then((response) => this.view.onSearchResponse(response.data, response.headers))
      .catch(this.view.onError)
  }

  findPublished() {
    this.api.courses.findPublished(this.getSearchQuery())
      .then((response) => this.view.onSearchResponse(response.data, response.headers))
      .catch(this.view.onError)
  }

  doPublish(data) {
    this.api.courses.doPublish(data)
      .then((response) => this.view.onDoPublishResponse(response.data.data))
      .catch(this.view.onError)
  }

  findInstructors() {
    this.api.instructors.find(this.getSearchQuery())
      .then((response) => this.view.onSearchInstructorsResponse(response.data.data))
      .catch(this.view.onError);
  }

  doLike(courseId) {
    this.api.courses.doLike(courseId)
      .then(response => this.view.onDoLikeResponse(response.data))
      .catch(this.view.onError)
  }

  mapInstructors(collection) {
    return _.map(collection, (item) => {
      return new Instructor(item)
    })
  }


  // #endregion

  //#region MAPPING DATA

  mapDataToFormData(data, coverImageFile, courseSubjects) {
    const fd = new FormData()
    fd.append('coverImageFile', coverImageFile)
    fd.append('course', JSON.stringify(data))
    _.forEach(courseSubjects, (subject) => {
      this.setActivityFilesToFormData(fd, subject.courseSubjectActivities);
    })
    return fd;
  }

  setActivityFilesToFormData(fd, activities) {
    _.forEach(activities, (activity) => {
      if (activity.file) {
        if (!activity.id) {
          fd.append('activityFiles', activity.file, `activity_${activity.fileUrl}.${FileHelper.getFilenameExtension(activity.file.name)}`)
        } else {
          fd.append('activityFiles', activity.file, `activity_${activity.id}.${FileHelper.getFilenameExtension(activity.file.name)}`)
        }
      }
      this.setTestActivityFilesToFormData(fd, activity, activity.courseSubjectTestActivities)
    })
  }

  setTestActivityFilesToFormData(fd, activity, testActivities) {
    _.forEach(testActivities, (testActivity) => {
      if (testActivity.file) {
        var fileName = FileHelper.getFilenameExtension(testActivity.file.name);
        if (!testActivity.id) {
          fd.append('activityTestFiles', testActivity.file, `test_${testActivity.fileUrl}.${fileName}`)
        } else {
          fd.append('activityTestFiles', testActivity.file, `test_${testActivity.id}.${fileName}`)
        }
      }
    });
  }

  convertFileToBase64(file) {
    return FileHelper.toBase64(file);
  }

  // #endregion

  // #region VALIDATIONS

  getTimeFromMins(mins) {
    return Math.floor(mins / 60) + ':' + (mins % 60)
  }

  sortSubjects(collection) {
    let array = _.filter(collection, function (o) {
      if (o.updateState !== 4) return o;
    });
    return _.sortBy(array, 'sort');
  }

  // #endregion
}
