import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent'
import CourseViewModel from '../../../../core/features/course/viewmodels/CourseViewModel'

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            courseCategories: [],
            universities: [],
            viewModel: null,
            permissions: null,
            modules: [],
            itemCourseSubject: null,
            courseSubjectSelectedIndex: null,
            coverImageFile: null,
            coverImageUrl: '/images/courses/add_img.jpg',
            isCoverImageTemp: false,
            editorAbout: null,
            instructorSelected: null,
            modalItemCourseSubject: {
                isVisible: false,
                isLoading: false
            },
            dynamicTags: [],
            inputTagsVisible: false,
            inputTagsValue: '',
            instructors: [],
            currentUniversity: null,
            rules: {
                code: [
                    { required: true, message: 'Código requerido.', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Titulo requerido.', trigger: 'blur' }
                ],
                courseCategory: [
                    { required: true, message: "Selecciona una Categoria", trigger: "change" }
                ],
                university: [
                    { required: true, message: "Universidad requerida.", trigger: "blur" },
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseViewModel(this)
        this.onActive()

    },
    mounted() {
        EventBus.$on('onSubmitedCourseSubject', this.onAddCourseSubject);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedCourseSubject', this.onAddCourseSubject);
    },
    methods: {
        onActive() {
            setTimeout(() => {
                $('#add-course-tab').steps();
                this.viewModel.onBindEditor('.about-editor');
                this.viewModel.bindFormView();
            }, 300);
        },
        onSearchCourseCategory(data) {
            if (data) {
                this.viewModel.findCourseCategories(`Name=${data}`)
            } else {
                this.courseCategories = []
            }
        },
        onSearchCourseCategoriesResponse(response) {
            this.courseCategories = response;
        },
        onFocusCourseCategory() {
            this.$forceUpdate()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_.isString(this.formData.university)) this.formData.university = this.currentUniversity;
                    this.formData.about = this.editorAbout.root.innerHTML;
                    this.formData.tags = this.dynamicTags.join(',');
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedCourse', null);
        },
        onCanCreateCourseSubject(permissions) {
            return this.canCreate(permissions) || this.canUpdate(permissions)
        },
        onCreateCourseSubject() {
            this.itemCourseSubject = this.viewModel.onCreateCourseSubject(this.formData.getNextCourseSubjectIndex());
            this.viewModel.showModal(this.modalItemCourseSubject, true);
        },
        onUpdateCourseSubject(item) {
            this.itemCourseSubject = this.viewModel.onUpdateCourseSubject(item);
            this.viewModel.showModal(this.modalItemCourseSubject, true);
        },
        onDeleteCourseSubject(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning' })
                .then(() => {
                    this.courseSubjectSelectedIndex = null;
                    this.formData.removeCourseSubject(index);
                    this.$forceUpdate();
                    this.formData.updateSortPositionCourseSubjects();
                })
                .catch(() => {

                });
        },
        onAddCourseSubject(item) {
            if (this.courseSubjectSelectedIndex != null) {
                if (item.id) {
                    var index = _.findIndex(this.formData.courseSubjects, { id: item.id })
                    if (index != -1) {
                        this.$set(this.formData.courseSubjects, index, item);
                    }
                } else {
                    this.$set(this.formData.courseSubjects, this.courseSubjectSelectedIndex, item);
                }
            } else {
                let nextPosition = _.size(this.formData.courseSubjects)
                this.$set(this.formData.courseSubjects, nextPosition, item);
            }
            this.courseSubjectSelectedIndex = null;
            this.viewModel.showModal(this.modalItemCourseSubject, false);
        },
        onCanReadCourseSubject(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteCourseSubject(permissions) {
            return this.canDelete(permissions);
        },
        onChangeItemOptions(option) {
            console.log(option)
            this.courseSubjectSelectedIndex = option.index
            if (option.command === 'edit') this.onUpdateCourseSubject(option.item);
            if (option.command === 'delete') this.onDeleteCourseSubject(option.index);
        },
        getSubjectActivitiesTotal(collection) {
            return _.size(collection)
        },
        getSubjectActivitiesDurationTotal(totalHours, collection) {
            return (totalHours) ? totalHours : this.viewModel.getTimeFromMins(_.sumBy(collection, (item) => item.duration));
        },
        orderCourseSubjects() {
            return this.formData.getNoRemovedCourseSubjectsOrdered();
        },
        onRemoveCoverImage(data) {
            this.coverImageUrl = '/images/courses/add_img.jpg'
            this.coverImageFile = null;
            this.isCoverImageTemp = false;
            this.formData.coverImageUrl = null;
        },
        onSuccessUploadCoverImage(data) {
            return false;
        },
        async onBeforeUploadCoverImage(file) {
            let dataUrl = await this.viewModel.convertFileToBase64(file)
            this.coverImageUrl = (dataUrl) ? dataUrl : '/images/courses/add_img.jpg'
            this.coverImageFile = file;
            this.isCoverImageTemp = true;
            /* console.log('on before', file)
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('La imagen debe estar en formato JPG!');
            }
            if (!isLt2M) {
            this.$message.error('La imagen excede los 2MB!');
            }
            return isJPG && isLt2M; */
            return true;
        },
        handleTagClose(tag) {
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },
        showTagInput() {
            this.inputTagsVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleTagConfirm() {
            let tagValue = this.inputTagsValue;
            if (tagValue) {
                this.dynamicTags.push(tagValue);
            }
            this.inputTagsVisible = false;
            this.inputTagsValue = '';
            return false;
        },
        onSearchUniversities(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onSearchUniversitiesResponse(response) {
            this.universities = response;
        },
        onChangeSelectUniversities(data) {
            if (!_.isEmpty(data) && _.isString(data)) {
                this.viewModel.createUniversities(data)
            }
        },
        onLoadUniversity(response) {
            this.currentUniversity = response;
        },
        onFocusUniversity() {
            this.$forceUpdate()
        },
        onSearchInstructors(data) {
            if (data) {
                this.viewModel.updateFilters(data, 'FullName', '=');
                this.viewModel.findInstructors()
            } else {
                this.instructors = []
            }
        },
        onSearchInstructorsResponse(response) {
            this.instructors = this.viewModel.mapInstructors(response);
        },
        onSelectInstructor(instructor) {
            var item = _.find(this.formData.instructors, { id: instructor.id })
            if (!item) {
                this.formData.instructors.push(instructor)
            }
            this.instructorSelected = null;
        },
        onRemoveInstructor(index) {
            this.formData.instructors.splice(index, 1);
        },
        mapInstructorImage(userProfile) {
            return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
        },
        onChangeDrag() {
            this.formData.updateSortPositionCourseSubjects()
        }
    }
}