export default class CourseSubjectTestActivity {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            description: this.description,
            instruction: this.instruction,
            file: this.file,
            fileUrl: this.fileUrl,
            duration: this.duration || 0,
            updateState: this.updateState,
            courseSubjectTestQuestions: this.mapQuestions(this.courseSubjectTestQuestions)
        };
    }

    mapQuestions(questions) {
        return _.map(questions, (question) => {
            return {
                id: question.id,
                description: question.description,
                sort: question.sort || 0,
                updateState: question.updateState,
                courseSubjectTestQuestionAnswers: this.mapAnswers(question.courseSubjectTestQuestionAnswers),
            };
        });
    }

    mapAnswers(answers) {
        return _.map(answers, (answer) => {
            return {
                id: answer.id,
                description: answer.description,
                success: answer.success,
                updateState: answer.updateState,
            }
        })
    }


    /**
     * Metodo para obtener las opciones de respuestas no eliminadas del examen actual
     */
    getNoRemovedTestQuestions() {
        return _.filter(this.courseSubjectTestQuestions, (question) => {
            return question.updateState !== 4;
        })
    }
}