import SystemHelper from '../../../../core/shared/helpers/SystemHelper';

export default {
    data() {
        return {
            isFileTemp: false,
            file: null,
            fileUrl: null,
        };
    },
    created() { },
    props: {
        section: {
            type: Object,
            required: true,
        }
    },
    methods: {
        onBeforeUploadSectionFile(file) {
            //console.log('on before', file)
            const isPdf = file.type === 'application/pdf';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isPdf) {
            this.$message.error('El Archivo debe estar en formato PDF!');
            }
            if (!isLt2M) {
            this.$message.error('El Archivo excede los 2MB!');
            }
            if(isPdf & isLt2M) {
                this.isFileTemp = true;
                this.file = file;
                this.fileUrl = SystemHelper.getGuidV4(file);
                this.onUpdateSectionFile(this.isFileTemp, this.file, this.fileUrl);
            }
            return isPdf && isLt2M;
        },
        onSuccessUploadSectionFile() {
            
        },
        onChangeUploadSectionFile() {
            
        },
        onShowSectionFile(fileUrl) {

        },
        onRemoveSectionFile() {
            this.isFileTemp = false;
            this.file = null;
            this.fileUrl = null;
            this.onUpdateSectionFile(this.isFileTemp, this.file, this.fileUrl);
        },
        onUpdateSectionFile(isFileTemp, file, fileUrl) {
            this.section.isSectionFileTemp = isFileTemp;
            this.section.file = file;
            this.section.fileUrl = fileUrl;
            this.$forceUpdate();
        },
    }
}