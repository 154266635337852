import * as signalR from '@microsoft/signalr'
import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import NotificationsViewModel from '../../../../core/features/notifications/viewmodels/NotificationsViewModel';

export default {
    extends: ListComponent,
    data() {
        return {
            modulePermissions: 'NotificationsContent-Notifications',
            item: null,
            searchName: null,
            searchCode: null,
            viewModel: null,
            limit: 10,
            notificationSelected: null,
            itemAnswerActivity: null,
            modalItemAnswerActivity:{
                isVisible: false,
                isLoading: false,
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR NOTIFICACIONES',
                onUploadImport: 'onUploadImportNotification',
                onCancelImport: 'onCancelImportNotification'
            },
            studentInfoItem: null,
            modalSubscriptionInfo: {
                isVisible: false,
                isLoading: false,
            },
        }
    },
    mounted() {
        EventBus.$on('onSubmitedNotification', this.onSearch);
        EventBus.$on('onUploadImportNotification', this.onUploadImport);
        EventBus.$on('onCancelImportNotification', this.onCancelImport);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedNotification', this.onSearch);
        EventBus.$off('onUploadImportNotification', this.onUploadImport);
        EventBus.$off('onCancelImportNotification', this.onCancelImport);
    },
    created() {
        this.connectoToSignalRHub();
        this.viewModel = new NotificationsViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.onSearch();
            this.onShowActivityDetails(this.$route.params.id)
            this.$root.$on('update-session', this.onUpdateSession);
        },
        onShowActivityDetails(id) {
            if(id) {
                this.viewModel.findResponseAnswerActivity(id)
            }
        },
        onSearch() {
            this.viewModel.findByAccount();
        },
        onAllFilterChange(data, field) {
            this.onFilterChange(data, field);
        },
        onShowImport() {
            this.viewModel.showModal(this.modalImport, true);
        },
        onUploadImport(file) {
            this.viewModel.showModalLoading(this.modalImport, true);
            this.viewModel.import(file);
        },
        onCancelImport() {
            this.viewModel.showModal(this.modalImport, false);
        },
        onExportToExcel() {
            this.viewModel.exportToExcel();
        },
        onCanCreate() {
            return this.canCreate(this.modulePermissions)
        },
        onCanRead() {
            return this.canRead(this.modulePermissions)
        },
        onCanUpdate() {
            return this.canUpdate(this.modulePermissions)
        },
        onCanDelete() {
            return this.canDelete(this.modulePermissions)
        },
        mapImage(user) {
            return (user && user.profileImageUrl) ? user.profileImageUrl : '/images/left-imgs/img-1.jpg';
        },
        onSelectNotification(notification) {
            this.notificationSelected = notification;
            this.viewModel.doMarkAsRead(notification);            
        },
        onMarkAsReadResponse() {
            if(this.notificationSelected.url){
                if(this.viewModel.isStudentActivity(this.notificationSelected.url)) {
                    var responseAnswerActivityId = this.viewModel.getStudentActivityId(this.notificationSelected.url);
                    this.viewModel.findResponseAnswerActivity(responseAnswerActivityId)
                } 
                else {
                    // this.$router.push(this.notificationSelected.url, () => this.$router.go(0))
                    this.$router.push(this.notificationSelected.url)
                }
            }
        },
        onFindResponseAnswerActivityResponse(response) {
            this.itemAnswerActivity = response;
            this.viewModel.showModal(this.modalItemAnswerActivity, true);
        },
        connectoToSignalRHub() {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl("/notifications-events")
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            connection.start()
                .then(() => { connection.on("RefreshNotifications", this.onRefreshNotifications); })
                .catch(this.onError);
        },
        onRefreshNotifications() {
            this.onSearch();
        },
        onUpdateSession(data){
            if(data.user.hasSubscriptions && !data.user.hasSubscriptionInfo){
                this.studentInfoItem = this.viewModel.onCreateStudentInfo()
                this.viewModel.showModal(this.modalSubscriptionInfo, true)
            }
        },
        isCollectionEmpty(collection) {
            return _.isEmpty(collection)
        }
    },
    computed: {}
}