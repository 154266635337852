export default class LiveClass {
    constructor(options) {
      Object.assign(this, options)
    }

    toSend() {
      console.log(this);
      return {
        id: this.id,
        title: this.title,
        courseId: (this.course) ? this.course.id : null,
        description: this.description,
        streamUrl: this.streamUrl,
        isFinished: this.isFinished
      }
    }
  }
  