import Quill from 'quill'
window.Quill = Quill;

export default {
    toolbarOptions: [
        {
            'header': [1, 2, 3, 4, 5, 6, false]
        },
        'bold',
        'italic',
        'underline',
        'strike',
        'link',
        'image',
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'color': [] },
        { 'background': [] },
        'formula'
    ],
    mathOperators: [
        ["\\sqrt[n]{x}", "\\nthroot"], ["\\frac{x}{y}", "\\frac"],
        ["\\exp{x}", "\\superscript"], ["\\_{x}", "\\subscript"],
        ["\\sum^{s}_{x}{d}", "\\sum"], ["\\prod^{s}_{x}{d}", "\\prod"],
        ["\\int^{s}_{x}{d}", "\\int"], ["\\binom{n}{k}", "\\binom"], ["\\cos^{n}", "\\cos"]
    ],
    buildEditor(selector) {
        var editor = new Quill(selector, {
            theme: 'snow',
            bounds: selector,
            modules: {
                formula: true,
                toolbar: this.toolbarOptions
            },
        })
        this.bindMathOperators(editor)
        this.bindEmptyStyles(editor);
        return editor;
    },
    bindMathOperators(editor) {
        var enableMathQuillFormulaAuthoring = mathquill4quill();
        enableMathQuillFormulaAuthoring(editor, {
            operators: this.mathOperators,
        });
    },
    bindEmptyStyles(editor) {
        editor.clipboard.addMatcher(Node.ELEMENT_NODE, function(node, delta){
            var plaintext = node.innerText;
            var Delta = Quill.import('delta');
            return new Delta().insert(plaintext);
          });
    },
}