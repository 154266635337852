<script>
import ListLiveClassComponent from '../../features/lists/ListLiveClassComponent';
import ModalLiveClassView from '../modals/ModalLiveClassView';
import ModalImportView from '../../../shared/views/ModalImportView';

export default {
  extends: ListLiveClassComponent,
  name: 'ListLiveClassView',
  components: {
    'modal-live-class-view': ModalLiveClassView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl liveClasses">
      <el-col :xs="24" :sm="10" :md="9" :lg="6" :xl="5" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Clase en Vivo</el-button
          >
          <!-- <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> M&aacute;s Opciones
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Clases en Vivo</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!--<el-dropdown-item>
                <el-select size="small" v-model="filterOptions.courseFilter" filterable remote clearable placeholder="Curso..."
                :remote-method="onSearchCourse" @change="onAllFilterChange(filterOptions.courseFilter.id, 'CourseId')" @focus="onFocusCourse"
                value-key="id">
                  <el-option v-for="item in courses" :key="item.id" :label="item.title" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>-->
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.publisherFilter" filterable remote clearable placeholder="Autores..."
                :remote-method="onSearchPublisher" @change="onAllFilterChange(filterOptions.publisherFilter.id, 'PublisherId')" @focus="onFocusPublisher"
                value-key="id">
                  <el-option v-for="item in publishers" :key="item.id" :label="item.firstName +' '+ item.lastName" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.isFinishedFilter" clearable placeholder="Fianlizado..." @change="onAllFilterChange(filterOptions.isFinishedFilter, 'IsFinished')" value-key="id">
                  <el-option v-for="item in finishedStatuses" :key="item.id" :label="item.name" :value="item.state"></el-option>
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip
          class="item"
          effect="dark"
          content="Buscar por titulo."
          placement="top"
        >
          <el-input
            size="small"
            placeholder="Buscar titulo"
            icon="search"
            v-model="filterOptions.titleFilter"
            @keyup.enter.native="onAllFilterChange(filterOptions.titleFilter, 'Title')"
            suffix-icon="el-icon-search"
          ></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-select size="small" v-model="filterOptions.courseFilter" filterable remote clearable placeholder="Curso..."
          :remote-method="onSearchCourse" @change="onAllFilterChange(filterOptions.courseFilter.id, 'CourseId')" @focus="onFocusCourse"
          value-key="id">
            <el-option v-for="item in courses" :key="item.id" :label="item.title" :value="item"></el-option>
          </el-select>
      </el-col>
    </el-row>
    <el-table
      :data="collection"
      empty-text="No se encontraron resultados."
      v-loading="isListLoading"
      element-loading-spinner="el-icon-loading"
      :element-loading-text="listLoadingMessage"
    >
      <el-table-column label="TITULO" min-width="300">
        <template slot-scope="scope">
          <div>
            {{ scope.row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="PUBLICADO POR" min-width="300">
        <template slot-scope="scope">
          <div>
            {{scope.row.publisher.firstName}} {{scope.row.publisher.lastName}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CURSO" min-width="250">
        <template slot-scope="scope">
          <el-tag type="default" effect="plain" v-if="scope.row.course">
            {{ scope.row.course }}
          </el-tag>
          <el-tag type="default" effect="plain" v-if="!scope.row.course">
            Sin Curso Asignado
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Url Stream" min-width="250">
        <template slot-scope="scope">
          {{scope.row.streamUrl}}
        </template>
      </el-table-column>
      <el-table-column label="FINALIZADO" min-width="140" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isFinished"
            active-color="#13ce66"
            active-text="SI"
            inactive-text="NO"
            size="small"
            @change="onUpdateFinished(scope.row.id, scope.row.isFinished)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <!--
      <el-table-column label="CURSO" min-width="250">
        <template slot-scope="scope">
          <div>
            {{ scope.row.curse }}
          </div>
        </template>
      </el-table-column>
      -->

      <!--
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column
        label="OPCIONES"
        :width="160"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'watch', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-video-play" aria-hidden="true"></i> Ver en Vivo
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-live-class-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-live-class-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
