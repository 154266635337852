import _ from 'lodash';
import BaseViewModel from '../../../infraestructure/BaseViewModel';

export default class AccountSubscriptionViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static PaymentMethodCash = 1;
    static DebitCreditCard = 2;
    static OxxoPay = 3;

    onBindSubscriptions() {
        this.api.subscriptionPlans.find()
            .then((response) => {
                this.view.onSearchSubscriptionPlansResponse(response.data.data)
            })
            .catch(this.view.onError)
    }
    
    formatMoney(amount) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(amount);
    }

    doSubscription(data) {
        this.api.subscriptions.doSubscription(data)
            .then((response) => this.view.onDoSubscriptionResponse(response.data))
            .catch(this.view.onDoSubscriptionError);
    }

    getPaymentMethod(method) {
        var result = null;
        switch (method) {
            case 'subscriptionCode':
                result = AccountSubscriptionViewModel.PaymentMethodCash;
                break;
            case 'subscriptionCard':
                result = AccountSubscriptionViewModel.DebitCreditCard;
                break;
            case 'subscriptionOxxo':
                result = AccountSubscriptionViewModel.OxxoPay;
                break;
            default:
                break;
        }
        return result;
    }
}