<script>
import ModuleSimulatorConfigurationsComponent from '../../features/modules/ModuleSimulatorConfigurationsComponent';
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuView from '../../../shared/views/MainMenuView';
import MainFooterView from '../../../shared/views/MainFooterView';
import PermissionHelper from '../../../../core/shared/helpers/PermissionHelper';

import ListSimulatorQuestionsView from '../lists/ListSimulatorQuestionsView';

export default {
  extends: ModuleSimulatorConfigurationsComponent,
  name: 'ModuleSimulatorConfigurationsView',
  components: {
    'header-view': HeaderView,
    'main-menu-view': MainMenuView,
    'footer-view': MainFooterView,
    'list-simulator-questions-view': ListSimulatorQuestionsView,
  },
  data() {
    return {};
  },
  methods: {
    onCanShow(moduleName) {
      return PermissionHelper.canShow(moduleName);
    },
  },
};
</script>

<template>
  <div>
    <header-view></header-view>
    <main-menu-view></main-menu-view>
      <div class="wrapper">
      <div class="sa4d25">
        <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="section3125">
                  <h4 class="item_title"><i class="fa fa-cogs"></i> Configuraciones de Simuladores</h4>
                  <div class="la5lo1">
                   <el-tabs type="border-card" v-model="mainActiveTab">
                    <el-tab-pane label="Preguntas" name="simulator-questions">
                      <list-simulator-questions-view v-if="mainActiveTab === 'simulator-questions'"></list-simulator-questions-view>
                    </el-tab-pane>
                  </el-tabs>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <footer-view></footer-view>
    </div>
  </div>
</template>