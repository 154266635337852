import BaseViewModel from '../../../infraestructure/BaseViewModel'
import CourseSubjectActivity from '../models/CourseSubjectActivity'

export default class CourseSubjectViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static ACTIVITY_TYPE_LECTURE = 1;
    static ACTIVITY_TYPE_TEST = 2;

    static UPDATE_STATES = {
        ADD: 2,
        EDIT: 3,
        REMOVE: 4,
    }

    onCreateCourseSubjectActivity(type) {
        let item = new CourseSubjectActivity({
            state: "create",
            status: 1,
            sort: _.size(this.view.formData.courseSubjectActivities) + 1,
            updateState: CourseSubjectViewModel.UPDATE_STATES.ADD,
            courseSubjectActivityType: type,
            courseSubjectActivityAnswers: [],
            courseSubjectTestActivities: []
        });
        return item;
    }

    onEditCourseSubjectActivity(data) {
        let item = new CourseSubjectActivity(data);
        item.updateState = (!item.id) ? CourseSubjectViewModel.UPDATE_STATES.ADD : CourseSubjectViewModel.UPDATE_STATES.EDIT;
        item.state = 'update';
        return item;
    }

    onBindForm() {
        this.api.courseSubjectActivityTypes.find()
            .then((response) => {
                this.view.onSearchCourseSubjectActivityTypesResponse(response.data);
                if (this.view.formData.state === 'update') {

                }
                this.view.onBindFormViewResponse()
            })
            .catch(this.view.onError)
    }

    getActivityTypeName(collection, activityType) {
        var item = _.find(collection, { id: activityType });
        return (_.isNil(item)) ? '' : item.name;
    }

    getTimeFromMins(mins) {
        return Math.floor(mins / 60) + ':' + (mins % 60)
    }


    bindSubjectActivitiesUpdateState(collection, updateState) {
        _.forEach(collection, (item) => {
            switch (updateState) {
                case CourseSubjectViewModel.UPDATE_STATES.ADD:
                    if (!item.id) item.updateState = updateState
                    break;
                case CourseSubjectViewModel.UPDATE_STATES.EDIT:
                    if (item.id) item.updateState = updateState
                    break;
                case CourseSubjectViewModel.UPDATE_STATES.REMOVE:
                    item.updateState = updateState
                    break;
                default:
                    break;
            }
        });
        return collection;
    }

    filterRemovedSubjectActivities(collection) {
        return _.filter(collection, (item) => {
            return item.updateState != CourseSubjectViewModel.UPDATE_STATES.REMOVE
        })
    }

    removeSubjectActivity(courseSubjectActivities, index) {
        this.filterRemovedSubjectActivities(courseSubjectActivities)[index].updateState = CourseSubjectViewModel.UPDATE_STATES.REMOVE;
        this.view.$forceUpdate()
    }

    filterAndSortSubjectActivities(collection) {
        let filteredResults = this.filterRemovedSubjectActivities(collection)
        return _.sortBy(filteredResults, 'sort');
    }

    changeDrag(collection) {
        var activities = this.filterRemovedSubjectActivities(collection)
        for (let index = 0; index < activities.length; index++) {
            const element = activities[index];
            element.sort = index + 1;
            element.updateState = (element.updateState == 2) ? element.updateState : 3;
        }
    }

}