<script>
import FooterView from '../../shared/views/MainFooterView';
import ContactPageComponent from '../features/pages/ContactPageComponent';
export default {
  extends: ContactPageComponent,
  name: 'ContactPageView',
  components: {
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div>
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="website-logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div class="nav nav-tabs tab_crse  justify-content-center">
                    <a class="nav-item nav-link" href="/">Inicio</a>
                    <a class="nav-item nav-link" href="/acerca">Acerca</a>
                    <a class="nav-item nav-link" href="/noticias"
                      >Noticias</a
                    >
                    <a class="nav-item nav-link active" href="/contacto"
                      >Contacto</a
                    >
                  </div>
                </nav>
              </div>
              <div class="title129 mt-35 mb-35">
                <h2>¡Queremos Conocerte!</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contact1256">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <a href="/soporte" class="contact_link_step">
                <img src="images/help_icon.svg" alt="" />
                <h4>Centro de Ayuda</h4>
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <a href="/noticias" class="contact_link_step">
                <img src="images/blog_icon.svg" alt="" />
                <h4>Publicaciones</h4>
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <a href="/mapa-del-sitio" class="contact_link_step">
                <img src="images/career_icon.svg" alt="" />
                <h4>Mapa del Sitio</h4>
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <a href="/terminos-y-condiciones" class="contact_link_step">
                <img src="images/developer_icon.svg" alt="" />
                <h4>Terminos y Pol&iacute;ticas</h4>
              </a>
            </div>
            <div class="col-lg-8">
              <div class="contact_map">
                <div id="mapMain" ref="mapElement"></div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="contact_info">
                <div class="checkout_title">
                  <h4>Informaci&oacute;n de Contacto</h4>
                  <img src="images/line.svg" alt="" />
                </div>
                <ul class="contact_list_info">
                  <li>
                    <div class="txt_cntct">
                      <span class="cntct_895"
                        ><i class="uil uil-location-point"></i>Direcci&oacute;n
                        :</span
                      >
                      <p>
                        Calzada Macristy de Hermosillo #881, República Mexicana,
                        Mexicali, B.C.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="txt_cntct">
                      <span class="cntct_895"
                        ><i class="uil uil-envelope"></i>Email :</span
                      >
                      <p>hola@appmisionate.com</p>
                    </div>
                  </li>
                  <li>
                    <div class="txt_cntct">
                      <span class="cntct_895"
                        ><i class="uil uil-mobile-android-alt"></i
                        >Tel&eacute;fono :</span
                      >
                      <p>686 125 3218</p>
                    </div>
                  </li>
                </ul>
                <div class="edututs_links_social">
                  <ul class="tutor_social_links">
                    <li>
                      <a href="#" class="fb"
                        ><i class="fab fa-facebook-f"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#" class="tw"><i class="fab fa-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#" class="ln"
                        ><i class="fab fa-linkedin-in"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#" class="yu"><i class="fab fa-youtube"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-view></footer-view>
    </div>
    <!-- Body End -->
  </div>
</template>

<style>
#mapMain {
  width: 100%;
  height: 350px;
}
</style>
