import _ from 'lodash'

import UrlRender from '../shared/helpers/UrlRender'
import DateTimeService from '../shared/services/DateTimeService'
import FileHelper from "../shared/helpers/FileHelper";
import ApiContext from './ApiContext'

export default class BaseViewModel {
  constructor(view) {
    this.view = view
    this.api = ApiContext
  }

  /// METODOS PARA LISTADOS

  /**
   * 
   * @param {*} headers 
   */
  getPagination(headers) {
    if (!headers) throw new Error('Parametro headers requerido.')
    const pagination = JSON.parse(headers['x-pagination'])
    return {
      totalItems: pagination.totalItems,
      totalPages: pagination.totalPages
    }
  }

  /**
   * 
   * @param {*} field 
   */
  removeFilter(field) {
    if (!field) throw new Error('Parametro field requerido.')
    _.remove(this.view.filters, { field: field })
  }

  /**
   * 
   * @param {*} data 
   * @param {*} field 
   * @param {*} condition 
   */
  updateFilters(data, field, condition) {
    this.removeFilter(field)
    if (!_.isNil(data) || !_.isEmpty(data)) {
      this.view.filters.push({ field: field, condition: condition, value: data })
    }
  }

  /**
   * 
   */
  getSearchQuery() {
    return UrlRender.getSearchQuery(
      (this.view.currentPage) ? this.view.currentPage - 1 : 0,
      this.view.limit,
      this.view.filters,
      []
    )
  }

  /**
   * 
   */
  generateFilterQuery() {
    return UrlRender.generateFilterParameter(
      this.view.filters,
      []
    )
  }

  /**
   * 
   * @param {*} modal 
   * @param {*} show 
   */
  showModal(modal, show) {
    if (!modal) throw new Error('Parametro modal requerido');
    modal.isVisible = show
  }

  /**
   * 
   * @param {*} modal 
   * @param {*} show 
   */
  showModalLoading(modal, show) {
    if (!modal) throw new Error('Parametro modal requerido');
    modal.isLoading = show
  }

  /**
   * 
   * @param {*} show 
   * @param {*} msj 
   */
  showMainTable(show, msj) {
    this.view.isTableLoading = show
    this.view.tableLoadingMessage = msj
  }

  /**
   * 
   * @param {*} show 
   * @param {*} message 
   */
  showFormLoading(show, message) {
    this.view.isFormProcessing = show
    this.view.formProcessingMessage = message
  }

  /**
   * 
   * @param {*} data 
   * @param {*} title 
   * @param {*} message 
   * @param {*} confirmCallback 
   * @param {*} cancelCallback 
   */
  showDeleteAlert(data, title, message, confirmCallback, cancelCallback) {
    var that = this;
    this.view.$confirm(message, title, { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning' })
      .then(() => confirmCallback(that, data.id))
      .catch(() => cancelCallback(that));
  }

  /**
   * 
   * @param {*} type 
   * @param {*} title 
   * @param {*} message 
   */
  showNotification(type, title, message) {
    this.view.$notify({
      title: title,
      message: message,
      type: type
    })
  }

  /**
   * Metodo para verificar que la fecha que tiene updateAt.
   * @param {*} date 
   */
  checkUpdatedAt(date) {
    return (!_.isUndefined(date) && _.isNil(date)) ? false : DateTimeService.checkWithInitDate(date);
  }

  /**
   * Metodo para guardar un stream de
   * @param {*} response 
   */
  saveFileToExcel(response) {
    if (response.fileStream) FileHelper.generateExcelDownload(response.fileStream, response.fileName)
  }
}
