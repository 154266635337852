export default class SimulatorQuestion {
    constructor(options) {
        Object.assign(this, options)
        
    }

    toSend(answerEditors) {
        return {
            simulatorQuestionId: this.id,
            code: this.code,
            name: this.name,
            description: this.description,
            type: this.type.id,
            difficultyLevel: this.difficultyLevel.id,
            courseCategoryId: this.courseCategory.id,
            universityId: (this.university) ? this.university.id : null,
            careerId: (this.career) ? this.career.id : null,
            simulatorAnswers: this.mapSimulatorAnswers(answerEditors, this.simulatorAnswers),
        }
    }

    mapSimulatorAnswers(answerEditors, answers) {
        let toDelete = _.filter(answers, (answer) => answer.updateState == 4)
        let toUpdate = _.filter(answers, (answer) => answer.updateState != 4)

        let mappedToUpdate = _.map(toUpdate, (answer, index) => {
            let answerEditor = _.find(answerEditors, {selector: `answer_editor_${index}`});
            answer.description = (answerEditor) ? answerEditor.component.root.innerHTML : '';
            return answer
        })

        return toDelete.concat(mappedToUpdate)
    }

    bindAnswers() {
        _.forEach(this.simulatorAnswers, (answer, index) => (answer.id) ? answer.updateState = 3 : answer.updateState = 2)
    }


    addAnswer() { 
        this.simulatorAnswers.push({
            description: '',
            success: false,
            updateState: 2
        })
    }


    removeAnswer(index) {
       this.getAnswers()[index].updateState = 4;
    }

    getAnswers() {
        return this.getFilteredAnswers(this.simulatorAnswers);
    }

    getFilteredAnswers(collection) {
        return _.filter(collection, (item) => item.updateState != 4)
    }

    
}