import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import CareerViewModel from '../../../../core/features/setting/viewmodels/CareerViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'Settings-Careers',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      parentFilter: '',
      parents: [],
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR CARRERAS',
        onUploadImport: 'onUploadImportCareer',
        onCancelImport: 'onCancelImportCareer',
      },
    };
  },
  mounted() {
    EventBus.$on('onSubmitedCareer', this.onSearch);
    EventBus.$on('onUploadImportCareer', this.onUploadImport);
    EventBus.$on('onCancelImportCareer', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedCareer', this.onSearch);
    EventBus.$off('onUploadImportCareer', this.onUploadImport);
    EventBus.$off('onCancelImportCareer', this.onCancelImport);
  },
  created() {
    this.viewModel = new CareerViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchCourseCategory(data) {
      if (data) {
        this.viewModel.findCourseCategories(`Name=${data}`)
    } else {
        this.parents = []
    }
    },
    onSearchCourseCategoriesResponse(response) {
      this.parents = response;
    },
    onFocusCourseCategory() {
      this.$forceUpdate()
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}