import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import LiveClass from '../models/LiveClass'
import Student from '../../students/models/Student'

export default class LiveClassViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new LiveClass({
      state: "create",
      status: 1
    });
    return item;
  }

  onCreateStudentInfo(data) {
    var item = new Student(data);
    item.state = 'create';
    return item
  }

  onDelete(data) {
    let item = new LiveClass(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new LiveClass(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.liveclass.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new LiveClass(data));
  }

  onSearchItem(id) {
    return this.api.liveclass.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.liveclass.create(data.toSend())
    else
      return this.api.liveclass.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.liveclass.delete(id)
        .then(response => context.view.onSuccess(response.data))
        .catch(context.view.onError)
}

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.liveclass.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.liveclass.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {
    if (this.view.formData.state === 'update') {
      let data = this.view.formData
      this.view.formData.reportDate = [data.startDate, data.endDate];
      this.view.courses = (this.view.formData.course) ? [this.view.formData.course] : []
    }
  }

  onBindProfile() {
    this.view.$root.$on('update-session', (data) => {
        this.view.isSubscribed = data.user.isSubscribed;            
    });
  }

  bindLiveClassDetails(liveClassId) {
    this.api.liveclass.findOne(liveClassId)
    .then((response) => this.view.onBindLiveClassDetailsResponse(response.data))
    .catch(this.view.onError);
  }

  findByLiveClass(liveClassId){
    this.api.liveChat.findByLiveClass(`liveClassId=${liveClassId}`)
    .then((response) => this.view.findByLiveClassResponse(response.data))
    .catch(this.view.onError);
  }

  onDoPublish(data) {
    this.api.liveChat.doPublish(data)
    .then((response) => this.view.doPublishResponse(response.data))
    .catch(this.view.onError);
  }

  // #endregion

  // #region QUERIES

  findLiveClassesByPublisher() {
    this.view.showLoading(true, 'Actualizando registros...');
    this.api.liveclass.findByPublisher(this.getSearchQuery())
      .then((response) => this.view.onSearchResponse(response.data, response.headers))
      .catch(this.view.onError);
  }

  findLiveClassesActives() {
    this.api.liveclass.findByActives(this.getSearchQuery())
      .then((response) => this.view.onSearchResponse(response.data, response.headers))
      .catch(this.view.onError);
  }

  findLiveClassesFinished() {
    this.api.liveclass.findByFinished(this.getSearchQuery())
      .then((response) => this.view.onSearchResponse(response.data, response.headers))
      .catch(this.view.onError);
  }

  /**
   * Metodo para buscar cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  findCourses(data) {
    this.api.courses.find(data)
      .then((response) => this.view.onSearchCoursesResponse(response.data.data))
      .catch(this.view.onError);
  }

  /**
   * Metodo para buscar finishedStatuses
   * @param {String} data Query string para parametros de búsqueda
   */
  findFinishedStatuses() {
    this.api.finishedStatuses.find()
      .then((response) => this.view.onSearchFinishedStatusesResponse(response.data.data))
      .catch(this.view.onError)
  }
  /**
   * Metodo para buscar autores
   * @param {String} data Query string para parametros de búsqueda
   */
  findPublishers(data) {
    this.api.users.find(data)
      .then((response) => this.view.onSearchPublishersResponse(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  //#region ACTIONS

  doFinish(liveClassId, data){
    this.api.liveclass.doFinish(liveClassId, { liveClassId: liveClassId, isFinished: data })
      .then((response) => this.view.onDoFinishResponse(response.data))
      .catch(this.view.onError)
  }
  
  
  // #endregion

  //#region MAPPING DATA

  
  // #endregion

  // #region VALIDATIONS

  // #endregion
}
