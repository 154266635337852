import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import CourseSubjectTestViewModel from '../../../../core/features/course/viewmodels/CourseSubjectTestViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            itemQuestion: null,
            sectionSelectedIndex: null,
            questionSelectedIndex: null,
            instructionEditors: [],
            descriptionEditors: [],
            isFileTemp: false,
            file: null,
            fileUrl: null,
            modalItemQuestion: {
                isVisible: false,
                isLoading: false
            },
            timerTypes: [],
            isTestTimeType: false,
            isSectionTimeType: false,
            rules: {
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectTestViewModel(this);
        this.onActive();
    },
    mounted() { 
        EventBus.$on('onSubmitedCourseSubjectQuestion', this.onAddCourseSubjectQuestion);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedCourseSubjectQuestion', this.onAddCourseSubjectQuestion);
    },
    methods: {
        onActive() {
            this.viewModel.bindTestFormView(this.formData);
        },
        collectionCourseSubjectTestActivities(collection) {
            return _.filter(collection, function(o) { 
                if(o.updateState !== 4) return o; 
            });
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    EventBus.$emit('onSubmitedCourseSubjectActivity', this.formData.toSend([], this.instructionEditors, this.descriptionEditors));
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onAddSection() {
            this.viewModel.addNewSection(this.formData.courseSubjectTestActivities)
        },
        onAddQuestion(indexSection){
            this.sectionSelectedIndex = indexSection;
            this.itemQuestion = this.viewModel.createQuestion(this.formData.courseSubjectTestActivities[indexSection]);
            this.viewModel.showModal(this.modalItemQuestion, true);
        },
        onAddCourseSubjectQuestion(item) {
            if(this.questionSelectedIndex != null) {
                this.viewModel.updateQuestion(this.formData.courseSubjectTestActivities[this.sectionSelectedIndex], this.questionSelectedIndex, item);
            } else {
                this.viewModel.addNewQuestion(this.formData.courseSubjectTestActivities[this.sectionSelectedIndex], item);
            }
            this.sectionSelectedIndex = null;
            this.questionSelectedIndex = null;
            this.viewModel.showModal(this.modalItemQuestion, false);
        },
        onChangeItemOptions(option) {
            this.sectionSelectedIndex = option.sectionIndex;
            this.questionSelectedIndex = option.index;
            if(option.command === 'edit') this.onUpdateQuestion(option.sectionIndex, option.index, option.item);
            if(option.command === 'delete') this.onDeleteQuestion(option.sectionIndex, option.index);
        },
        onDeleteQuestion(sectionIndex, questionIndex) {
            this.formData.courseSubjectTestActivities[sectionIndex].courseSubjectTestQuestions[questionIndex].updateState = 4;
            this.$forceUpdate()
            //this.viewModel.removeQuestion(this.formData.courseSubjectTestActivities[sectionIndex], questionIndex)
        },
        onUpdateQuestion(sectionIndex, questionIndex, question){
            this.sectionSelectedIndex = sectionIndex;
            this.questionSelectedIndex = questionIndex;
            this.itemQuestion = this.viewModel.editQuestion(question);
            this.viewModel.showModal(this.modalItemQuestion, true);
        },
        onCanReadCourseSubjectQuestion(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteCourseSubjectQuestion(permissions) {
            return this.canDelete(permissions);
        },
        onDeleteSection(indexSection) {
            this.formData.courseSubjectTestActivities[indexSection].updateState = 4;
            this.$forceUpdate()
            //this.viewModel.removeSection(this.formData.courseSubjectTestActivities, indexSection);
        },
        sortCourseSubjectTestQuestions(collection) {
            let array = _.filter(collection, function(o) { 
                if(o.updateState !== 4) return o; 
            });
            return _.sortBy(array, 'sort');
        },
        changeDrag(section) {
            for (let index = 0; index < section.courseSubjectTestQuestions.length; index++) {
                const element = section.courseSubjectTestQuestions[index];

                element.sort = index + 1;
                element.updateState = (element.updateState == 2) ? element.updateState : 3;
                
            }
        },
        isTimerChange(isTimer) {
            if(!isTimer) {
                this.formData.timerType = null;
                this.formData.duration = null;
                this.isTestTimeType = false;
                this.isSectionTimeType = false;
            }
        },
        isTimerTypeChange(type) {
            this.isTestTimeType = false;
            this.isSectionTimeType = false;
            if(type) {
                this.isTestTimeType = (type.id === 2);
                this.isSectionTimeType = (type.id === 3);
            }
            this.formData.duration = null;
        }
    }
}