<script>
import ModalSimulatorOfficialComponent from '../features/ModalStudentSimulatorComponent';
// import ModalSimulatorOfficialSectionView from './ModalSimulatorOfficialSectionView';
import draggable from 'vuedraggable';

export default {
  extends: ModalSimulatorOfficialComponent,
  name: 'ModalSimulatorOfficialOfficialView',
  components: {
    draggable,
  },
};
</script>

<template>
  <div>
    <el-dialog
      title="Simulador"
      :visible.sync="conf.isVisible"
      width="32%"
      custom-class="simulator-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Información de Simulador</legend>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" align="center">
              <el-form-item label="Nivel de Dificultad" prop="difficultyLevel">
                <el-select
                  v-model="formData.difficultyLevel"
                  clearable
                  filterable
                  placeholder="Seleccionar"
                  value-key="id"
                  size="small"
                >
                  <el-option
                    v-for="item in difficultyLevels"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" align="center">
              <el-form-item label="¿Es Temporizado?">
              <el-switch
                v-model="formData.isTimer"
                active-color="#13ce66"
                active-text="SI"
                inactive-text="NO"
                @change="isTimerChange"
              >
              </el-switch>
            </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" align="center">
              <el-form-item label="Tipo de Temporizado" prop="timerType">
                <el-select
                  v-model="formData.timerType"
                  clearable
                  filterable
                  placeholder="Seleccionar"
                  value-key="id"
                  size="small"
                  :disabled="!formData.isTimer"
                  @change="isTimerTypeChange"
                >
                  <el-option
                    v-for="item in timerTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="6" :md="12" :lg="12" :xl="12" align="center">
              <el-form-item label="Duración Total(Min.)" prop="duration">
                <el-input-number
                  v-model="formData.duration"
                  size="small"
                  :disabled="!formData.isTimer"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="8" :md="7" :lg="5" :xl="4">
              <el-button
                type="primary"
                size="small"
                class="btn-primary"
                icon="el-icon-circle-plus"
                style="margin-bottom:10px;"
                @click="onAddSection()"
                >Agregar Sección</el-button
              >
            </el-col>
          </el-row>
          <el-row class="row-bg" :gutter="20">
            <el-table
              :data="formData.simulatorSections"
              empty-text="No se encontraron resultados."
            >
              <el-table-column type="index" width="50"></el-table-column>
              <el-table-column
                label="ÁREA DE CONOCIMINETO"
                min-width="250"
                align="center"
                text-align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-form-item
                    required
                    :prop="
                      'simulatorSections.' + scope.$index + '.courseCategory'
                    "
                    :rules="{ required: true, message: 'Área requerida.' }"
                  >
                    <el-select
                      size="small"
                      v-model="scope.row.courseCategory"
                      filterable
                      remote
                      clearable
                      :remote-method="onSearchCourseCategory"
                      @focus="onFocus"
                      value-key="id"
                    >
                      <el-option
                        v-for="item in courseCategories"
                        :key="item.id"
                        :label="item.name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                prop="startDate"
                label="NO. PREGUNTAS"
                :width="130"
                align="center"
                text-align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-form-item
                    :prop="
                      'simulatorSections.' + scope.$index + '.questionQuantity'
                    "
                    :rules="{ validator: onQuestionNumberValidator }"
                  >
                    <el-input-number
                      v-model="scope.row.questionQuantity"
                      size="small"
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="OPCIONES"
                :width="110"
                align="center"
                text-align="center"
                header-align="center"
              >
                <template slot-scope="scope">
                  <el-dropdown @command="onChangeItemOptions">
                    <el-button size="small">
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="{ command: 'delete', item: scope.row }"
                      >
                        <i class="el-icon-delete" aria-hidden="true"></i>
                        Eliminar
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          class="btn-primary"
          size="small"
          >Generar simulador</el-button
        >
      </span>
    </el-dialog>
    <modal-simulator-official-section-view
      :formData="itemSection"
      :conf="modalItemSection"
      v-if="modalItemSection.isVisible"
    >
    </modal-simulator-official-section-view>
  </div>
</template>
