import * as signalR from '@microsoft/signalr'
import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import NotificationsViewModel from '../../../../core/features/notifications/viewmodels/NotificationsViewModel';

export default {
    extends: ListComponent,
    data() {
        return {
            modulePermissions: 'NotificationsContent-Notifications',
            item: null,
            searchName: null,
            searchCode: null,
            viewModel: null,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR NOTIFICACIONES',
                onUploadImport: 'onUploadImportNotification',
                onCancelImport: 'onCancelImportNotification'
            }
        }
    },
    mounted() {
        EventBus.$on('onSubmitedNotification', this.onSearch);
        EventBus.$on('onUploadImportNotification', this.onUploadImport);
        EventBus.$on('onCancelImportNotification', this.onCancelImport);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedNotification', this.onSearch);
        EventBus.$off('onUploadImportNotification', this.onUploadImport);
        EventBus.$off('onCancelImportNotification', this.onCancelImport);
    },
    created() {
        this.connectoToSignalRHub();
        this.viewModel = new NotificationsViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.onSearch();
        },
        onAllFilterChange(data, field) {
            this.onFilterChange(data, field);
        },
        onShowImport() {
            this.viewModel.showModal(this.modalImport, true);
        },
        onUploadImport(file) {
            this.viewModel.showModalLoading(this.modalImport, true);
            this.viewModel.import(file);
        },
        onCancelImport() {
            this.viewModel.showModal(this.modalImport, false);
        },
        onExportToExcel() {
            this.viewModel.exportToExcel();
        },
        onCanCreate() {
            return this.canCreate(this.modulePermissions)
        },
        onCanRead() {
            return this.canRead(this.modulePermissions)
        },
        onCanUpdate() {
            return this.canUpdate(this.modulePermissions)
        },
        onCanDelete() {
            return this.canDelete(this.modulePermissions)
        },
        connectoToSignalRHub() {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl("/notifications-events")
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            connection.start()
                .then(() => { connection.on("RefreshNotifications", this.onRefreshNotifications); })
                .catch(this.onError);
        },
        onRefreshNotifications() {
            this.onSearch();
        },
        mapImage(user) {
            return (user && user.profileImageUrl) ? user.profileImageUrl : '/images/left-imgs/img-1.jpg';
        },
    },
    computed: {}
}