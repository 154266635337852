import BaseComponent from "./BaseComponent";

export default {
    extends: BaseComponent,
    data() {
        return {
            filters: [],
            sortBy: [],
            filterOptions: {},
            options: {},
            collection: [],
            currentPage: 1,
            isListLoading: false,
            listLoadingMessage: "",
            limit: 20,
            fullLoader: false,
            pagination: {
                totalItems: 0,
                totalPages: 0
            },
            modalItem: {
                isVisible: false,
                isLoading: false
            }
        };
    },
    methods: {
        onSearch() {
            this.showLoading(true, 'Actualizando registros...');
            console.log('onSearchCollection1')
            this.viewModel
                .onSearchCollection()
                .then((response) => {
                    console.log('onSearchCollection2')                 
                    this.onSearchResponse(response.data, response.headers)
                })
                .catch(this.onSearchError);
        },
        onSearchResponse(response, headers) {
            console.log('hola')
            this.fullLoader = false;
            this.showLoading(false, '');
            this.pagination = this.viewModel.getPagination(headers)
            this.collection = this.viewModel.mapCollection(response.data);
        },
        onFilterChange(data, field) {
            this.viewModel.updateFilters(data, field, '=');
            this.onSearch();
        },
        onPageChange(data) {
            this.currentPage = data;
            this.onSearch();
        },
        onCreate() {
            this.item = this.viewModel.onCreate();
            this.viewModel.showModal(this.modalItem, true);
        },
        onEdit(id) {
            this.showLoading(true, 'Cargando registro...');
            this.viewModel
                .onSearchItem(id)
                .then(response => this.onEditResponse(response.data))
                .catch(this.onError);
        },
        onEditResponse(response) {
            this.showLoading(false, '');
            this.item = this.viewModel.onEdit(response.data);
            this.viewModel.showModal(this.modalItem, true);
        },
        onDelete(id) {
            this.showLoading(true, 'Cargando registro...');
            this.viewModel
                .onSearchItem(id)
                .then(response => this.onDeleteResponse(response.data))
                .catch(this.onError);
        },
        onDeleteResponse(response) {
            this.showLoading(false, '');
            this.item = this.viewModel.onDelete(response.data);
            this.onShowDeleteConfirmation(this.item);
        },
        onShowDeleteConfirmation(data) {
            this.viewModel.showDeleteAlert(data, 'Eliminar', '¿Desea eliminar el registro seleccionado?', this.viewModel.onDestroy, this.viewModel.onCancel);
        },
        onPrint(id) {
            this.viewModel.printReportToPdf(id)
            this.showLoading(false, "");
        },
        onExport(id) {
            this.showLoading(true, 'Exportando registro...');
            this.viewModel.exportReportToExcel(id)
        },
        onExportReportResponse(response) {
            this.onShowSuccess(response.message);
            this.showLoading(false, '');
            this.viewModel.saveFileToExcel(response);
        },
        onImportResponse(response) {
            this.onShowSuccess(response.message);
            this.viewModel.showModalLoading(this.modalImport, false);
            this.viewModel.showModal(this.modalImport, false);
        },
        onExportResponse(response) {
            this.onShowSuccess(response.message);
            this.viewModel.showModalLoading(this.modalItem, false);
            this.viewModel.saveFileToExcel(response);
        },
        onCopy(id) {
            this.showLoading(true, 'Copiando registro...');
            this.viewModel.copyRegister(id)
        },
        onCopyResponse(response) {
            this.onShowSuccess(response.message);
            this.showLoading(false, '');
            this.onSearch();
        },
        onSuccess(response) {
            this.onShowSuccess(response.message);
            this.onSearch();
        },
        onChangeItemOptions(event) {
            if (event.command === 'edit') this.onEdit(event.item.id);
            if (event.command === 'delete') this.onDelete(event.item.id);
            if (event.command === 'copy') this.onCopy(event.item.id);
            if (event.command === 'print') {
                this.showLoading(true, "Generando Reporte...");
                this.onPrint(event.item.id);
            }
            if (event.command === 'export') this.onExport(event.item.id);
            if (event.command === 'watch') this.onWatch(event.item.id);
        },
        onCheckUpdatedAt(date) {
            return this.viewModel.checkUpdatedAt(date);
        },
        showLoading(isLoading, message) {
            this.isListLoading = isLoading;
            this.listLoadingMessage = message;
        },
        onSearchError(error) {
            this.showLoading(false, '');
            this.onError(error);
        }
    }
};