import _ from 'lodash'
//import QuillEditorHelper from '../../../shared/helpers/QuillEditorHelper'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SimulatorOfficialQuestion from '../models/SimulatorOfficialQuestion'

export default class SimulatorOfficialQuestionViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }
    // INICIALIZADORES

    //#region CRUD

    onSearchCollection() {
        return this.api.simulatorQuestions.find(this.getSearchQuery())
    }

    mapCollection(collection) {
        return _.map(collection, (data) => new SimulatorOfficialQuestion(data));
    }

    onSearchItem(id) {
        return this.api.simulatorQuestions.findOne(id)
    }

    onSave(data) {
        if (data.state === 'create')
            return this.api.simulatorQuestions.create(data.toSend())
        else
            return this.api.simulatorQuestions.update(data.id, data.toSend());
    }

    onDestroy(context, id) {
        context.api.simulatorQuestions.delete(id)
            .then(response => context.view.onSuccess(response.data))
            .catch(context.view.onError)
    }

    onCancel(context) {
        context.view.item = null;
    }

    import(file) {
        this.api.simulatorQuestions.import(file)
            .then(response => this.view.onImportResponse(response.data))
            .catch(this.view.onError)
    }

    exportToExcel() {
        this.api.simulatorQuestions.exportToExcel()
            .then(response => this.view.onExportResponse(response.data))
            .catch(this.view.onError)
    }

    // #endregion

    // #region BINDING DATA
    bindQuestionFormView(formData) {
        setTimeout(() => {
            this.bindFormView();

            this.view.conf.isLoading = false;
        }, 300);
    }

    bindFormView() {
        this.api.difficultyLevels.find()
			.then((response) => {
				this.view.difficultyLevels = response.data.data;
			})
			.catch(this.view.onError)
    }

    saveQuestion(formData) {
        return formData.toSend();
    }

    // #endregion

    // #region QUERIES
    /**
     * Metodo para buscar categorias de cursos
     * @param {String} data Query string para parametros de búsqueda
     */
    findCourseCategories(data) {
        this.api.courseCategories.find(data)
            .then((response) => {
                this.view.onFindCourseCategoriesResponse(response.data.data)
            })
            .catch(this.view.onError)
    }
    /**
     * Metodo para buscar Universidades
     * @param {String} data Query string para parametros de búsqueda
     */
    findUniversities(data) {
        this.api.universities.find(data)
            .then((response) => {
                this.view.onFindUniversitiesResponse(response.data.data)
            })
            .catch(this.view.onError)
    }
    /**
     * Metodo para buscar Carreras
     * @param {String} data Query string para parametros de búsqueda
     */
    findCareers(data) {
        this.api.careers.find(data)
            .then((response) => {
                this.view.onFindCareersResponse(response.data.data)
            })
            .catch(this.view.onError)
    }
    /**
     * Metodo para buscar tipo de simulador
     */
    getSimulatorType(data, type) {
        return _.find(data, function (o) {
            return o.id == type;
        });
    }
    /**
     * Metodo para buscar categorias de cursos
     * @param {String} data Query string para parametros de búsqueda
     */
    findQuestions(filters) {
        let data = [];
        
        data = this.onMapFilters(filters);
        
        this.api.simulatorQuestions.find(data)
            .then((response) => this.view.onFindSimulatorQuestionsResponse(response.data.data))
            .catch(this.view.onError)
    }
    /**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	getRandomQuestions(filters) {
        let data = [];
        
        data = this.onMapFilters(filters);

		this.api.simulators.findRandomQuestions(data)
			.then((response) => this.view.onFindRandomQuestionsResponse(response.data.data))
			.catch(this.view.onError)
	}


    // #endregion

    //#region MAPPING DATA
    onMapFilters(filters) {
        let data = [];

        if (!_.isNil(filters.universityFilter) || !_.isEmpty(filters.universityFilter)) {
            data.push(this.mapFilters(filters.universityFilter, 'UniversityId', '='));
        }
        if (!_.isNil(filters.careerFilter) || !_.isEmpty(filters.careerFilter)) {
            data.push(this.mapFilters(filters.careerFilter, 'CareerId', '='));
        }
        if (!_.isNil(filters.courseCategoryFilter) || !_.isEmpty(filters.courseCategoryFilter)) {
            data.push(this.mapFilters(filters.courseCategoryFilter, 'CourseCategoryId', '='));
        }
        if (!_.isNil(filters.difficultyLevelFilter) || !_.isEmpty(filters.difficultyLevelFilter)) {
            data.push(this.mapFilters(filters.difficultyLevelFilter, 'DifficultyLevel', '='));
        }
        if (!_.isNil(filters.questionQuantity) || !_.isEmpty(filters.questionQuantity)) {
            data.push(this.mapFilters(filters.questionQuantity, 'QuestionQuantity', '='));
        }
        if (!_.isNil(filters.nameFilter) || !_.isEmpty(filters.nameFilter)) {
            data.push(this.mapFilters(filters.nameFilter, 'Name', '='));
        }

        return data;
    }
    /**
   * 
   * @param {*} data 
   * @param {*} field 
   * @param {*} condition 
   */
    mapFilters(data, field, condition) {
        //this.removeFilter(field)
        if(_.isUndefined(data.id)) {
            return `${field}${condition}${data}`
        } else {
            return `${field}${condition}${data.id}&`
        }
    }

    // #endregion

    // #region VALIDATIONS

    /**
   * Metodo para validación de formulario de recepcion para verificar que vengan kits.
   */
    validateForm(valid) {
        var isValid = false
        if (_.size(this.view.multipleSelection) != 0) {
            if (valid) isValid = true
        } else {
            if (valid) {
                this.view.onError(new Error(
                    'Ocupa al menos seleccionar una pregunta'
                ))
            }
        }
        return isValid
    }

    isDescriptionValid(rule, value, callback) {
        if (!_.isNil(value) || !_.isEmpty(value)) {
            callback();
        } else {
            callback(new Error("Descripción requerido."));
        }
    }

    isCourseCategoryValid(filterOption) {
		return (filterOption.courseCategoryFilter && filterOption.courseCategoryFilter.id);
	}

	isQuestionsQuantityValid(filterOption) {
		return (filterOption.questionQuantity && (filterOption.questionQuantity > 0));
	}

    // #endregion
}
