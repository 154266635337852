import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import SimulatorOfficialQuestionViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialQuestionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            careers: [],
            courseCategories: [],
            types: [],
            universities: [],
            difficultyLevels: [],
            isQuestionsLoading: false,
            filterOptions: {
                careerFilter: null,
                courseCategoryFilter: null,
                nameFilter: null,
                universityFilter: null,
                questionQuantity: null,
                difficultyLevelFilter: null,
            },
            rules: {
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
                description: [
                    { validator: this.validateDescription }
                ],
            },
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorOfficialQuestionViewModel(this);
        this.onActive();
    },
    mounted() {
        this.$root.$on('update-question-editor', this.onUpdateEditor);
    },
    beforeDestroy() {

    },
    methods: {
        onActive() {
            console.log(this.formData)
            this.viewModel.bindQuestionFormView(this.formData);
        },
        onAllFilterChange(data, field) {
            this.onFilterChange(data, field);
        },
        onSearchCourseCategory(data) {
            if (data) {
                this.viewModel.findCourseCategories(`Name=${data}`)
            } else {
                this.courseCategories = []
            }
        },
        onFindCourseCategoriesResponse(response) {
            this.courseCategories = response;
        },
        onFocus() {
            this.$forceUpdate()
        },
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val;
            console.log(this.multipleSelection)
        },
        onFindQuestions() {
            this.viewModel.findQuestions(this.filterOptions);
            //this.viewModel.findQuestions(`CourseCategoryId=${this.filterOptions.courseCategoryFilter.id}&Name=${this.filterOptions.nameFilter}`);
        },
        onFindSimulatorQuestionsResponse(response) {
            console.log(response)
            this.formData.simulatorsQuestions = response;
            this.$forceUpdate()
        },
        onFindUniversity(data) {
            if (data) {
                this.viewModel.findUniversities(`Name=${data}`)
            } else {
                this.universities = []
            }
        },
        onFindUniversitiesResponse(response) {
            this.universities = response;
        },
        onFindCareer(data) {
            if (data) {
                this.viewModel.findCareers(`Name=${data}`)
            } else {
                this.careers = []
            }
        },
        onFindCareersResponse(response) {
            this.careers = response;
        },
        onAddRandomQuestions() {
            if(this.viewModel.isQuestionsQuantityValid(this.formData)) {
                this.isQuestionsLoading = true
                this.filterOptions.questionQuantity = this.formData.questionQuantity;
                this.viewModel.getRandomQuestions(this.filterOptions);
            } else {
                this.onShowWarning('Numero de preguntas requerido')
            }
        },
        onFindRandomQuestionsResponse(response) {
            console.log(response)
            setTimeout(() => {
                this.formData.simulatorsQuestions = response;
            }, 200);
            setTimeout(() => {
                this.isQuestionsLoading = false
            }, 200);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    EventBus.$emit('onSubmitedSimulatorSectionQuestions', this.multipleSelection);
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
    }
}