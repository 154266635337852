<script>
import SessionBoxComponent from '../features/SessionBoxComponent';

export default {
  name: 'SessionBoxView',
  mixins: [SessionBoxComponent],
};
</script>

<template>
  <li class="ui dropdown">
    <a href="#" class="opts_account" title="Account">
      <img :src="profileImageUrl" alt="" />
    </a>
    <div class="menu dropdown_account">
      <div class="channel_my">
        <div class="profile_link">
          <img :src="profileImageUrl" alt="" />
          <div class="pd_content">
            <div class="rhte85">
              <h6 v-if="session && session.user">{{session.user.userName}}</h6>
              <div class="mef78" title="Verify">
                <i class="uil uil-check-circle"></i>
              </div>
            </div>
            <!--<span>gambol943@gmail.com</span> -->
            <span v-if="session">{{session.user.email}}</span>
          </div>
        </div>
        <!-- <a href="my_instructor_profile_view.html" class="dp_link_12"
          >View Instructor Profile</a
        > -->
      </div>
      <!-- <a href="instructor_dashboard.html" class="item channel_item"
        >Cursus dashboard</a
      >
      <a href="membership.html" class="item channel_item">Paid Memberships</a>
      <a href="setting.html" class="item channel_item">Setting</a>
      <a href="help.html" class="item channel_item">Help</a>
      <a href="feedback.html" class="item channel_item">Send Feedback</a> -->
      <a href="#" @click="onLogout" class="item channel_item">Salir</a>
    </div>
  </li>
</template>
