import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import PostViewModel from '../../../../core/features/posts/viewmodels/PostViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'PostContent-Posts',
      item: null,
      viewModel: null,
      authors: [],
      publishedStatuses: [],
      filterOptions: {
        authorFilter: '',
        descriptionFilter: '',
        isPublishedFilter: '',
        titleFilter: '',
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR PUBLICACIONES',
        onUploadImport: 'onUploadImportPost',
        onCancelImport: 'onCancelImportPost'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedPost', this.onSearch);
    EventBus.$on('onUploadImportPost', this.onUploadImport);
    EventBus.$on('onCancelImportPost', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedPost', this.onSearch);
    EventBus.$off('onUploadImportPost', this.onUploadImport);
    EventBus.$off('onCancelImportPost', this.onCancelImport);
  },
  created() {
    this.viewModel = new PostViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onSearchPublishedStatus();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchAuthor(data) {
      if (data) {
        this.viewModel.findAuthors(`FullName=${data}&Role=profesor`)
    } else {
        this.authors = []
    }
    },
    onSearchAuthorsResponse(response) {
      console.log(response)
      this.authors = response;
    },
    onFocusAuthor() {
      this.$forceUpdate()
    },
    onSearchPublishedStatus() {
      this.viewModel.findPublishedStatuses()
    },
    onSearchPublishedStatusesResponse(response) {
      this.publishedStatuses = response;
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    getCoverImage(url) {
      return (url) ? url : '/images/Posts/add_img.jpg'
    },
    mapPostImage(post) {
      return (post && post.imageUrl) ? post.imageUrl : '/images/Posts/add_img.jpg'
    },
    onDoPublish(post) {
      this.viewModel.doPublish({id: post.id, isPublished: post.isPublished })
    },
    onDoPublishResponse(response) {
      this.onSearch()
    }
  },
  computed: {}
}