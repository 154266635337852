import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class CourseDetailViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static HOUR_IN_MINUTES = 60;
    static ACTIVITY_TYPE_LECTURE = 1;
    static ACTIVITY_TYPE_TEST = 2;

    onSearchDetail(courseId) {
        this.api.courses.findDetails(courseId)
            .then(response => {
                this.view.onSearchDetailResponse(response.data)
                return this.api.courseSubjectActivityTypes.find();
            })
            .then(response => {
                this.view.onSearchCourseSubjecActivityTypesResponse(response.data)
                if (this.view.courseDetails.courseUserSocial) {
                    this.view.isFavorite = this.view.courseDetails.courseUserSocial.isFavorite;
                    this.view.isLiked = this.view.courseDetails.courseUserSocial.liked;
                    this.view.isDisliked = this.view.courseDetails.courseUserSocial.disliked;
                    this.view.totalLiked = this.view.courseDetails.totalLiked;
                    this.view.totalDisliked = this.view.courseDetails.totalDisliked;
                    this.doCollapseActive();
                }
                return this.api.courses.doViewed(this.view.courseDetails.id)
            })
            .then((response) => {
                this.view.onDoViewedResponse(response.data)
                setTimeout(() => {
                    this.view.fullLoader = false; 
                }, 200);
            })
            .catch(this.view.onError)
    }

    onBindProfile() {
        this.view.$root.$on('update-session', (data) => {
            this.view.isSubscribed = data.user.isSubscribed;
        });
    }

    doFavorite(courseId) {
        this.api.courses.doFavorite(courseId)
            .then(response => this.view.onDoFavoriteResponse(response.data))
            .catch(this.view.onError)
    }

    doLike(courseId) {
        this.api.courses.doLike(courseId)
            .then(response => this.view.onDoLikeResponse(response.data))
            .catch(this.view.onError)
    }

    doDislike(courseId) {
        this.api.courses.doDislike(courseId)
            .then(response => this.view.onDoDislikeResponse(response.data))
            .catch(this.view.onError)
    }

    isValidActivityType(collection, activityType, keyId) {
        let item = _.find(collection, { id: activityType })
        return (!_.isNil(item) && item.id === keyId)
    }

    getTotalActivities(collection) {
        let totalItems = _.size(collection);
        if (totalItems === 1) return `${totalItems} actividad`;
        return `${totalItems} actividades`;
    }

    getTotalDurationOfActivities(collection) {
        return (_.sumBy(collection, (item) => item.duration) / CourseDetailViewModel.HOUR_IN_MINUTES).toFixed(2);
    }

    doCollapseActive() {
        setTimeout(() => {
            var headers = $('#accordion .accordion-header');
            var contentAreas = $('#accordion .ui-accordion-content ').hide()
                .first().show().end();
            var expandLink = $('.accordion-expand-all');

            // add the accordion functionality
            headers.click(function () {
                // close all panels
                contentAreas.slideUp();
                // open the appropriate panel
                $(this).next().slideDown();
                // reset Expand all button
                expandLink.text('Expandir Todo')
                    .data('isAllOpen', false);
                // stop page scroll
                return false;
            });

            // hook up the expand/collapse all
            expandLink.click(function () {
                var isAllOpen = !$(this).data('isAllOpen');
                console.log({ isAllOpen: isAllOpen, contentAreas: contentAreas })
                contentAreas[isAllOpen ? 'slideDown' : 'slideUp']();

                expandLink.text(isAllOpen ? 'Ocultar Todo' : 'Expandir Todo')
                    .data('isAllOpen', isAllOpen);
            });
        }, 200);
    }

}