import axios from 'axios'
import SessionHelper from '../shared/helpers/SessionHelper';
var port = 5000;
export default class ApiWrapper {

    constructor() { }

    get(url, config) {
        this.setAuthorizationHeaders()
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.get(url, config)
    }

    post(url, data, config) {
        this.setAuthorizationHeaders()
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.post(url, data, config)
    }

    put(url, data, config) {
        this.setAuthorizationHeaders()
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.put(url, data, config)
    }

    delete(url, config) {
        this.setAuthorizationHeaders()
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.delete(url, config)
    }

    all(requestsCollection) {
        this.setAuthorizationHeaders()
        if(process.env.NODE_ENV == 'development') {
            axios.defaults.baseURL = location.protocol + '//' + location.hostname + ':' + port;
        }
        return axios.all(requestsCollection)
    }

    setAuthorizationHeaders() {
        axios.interceptors.request.use(function(config) {
            const token = SessionHelper.getAccessToken();
            if(token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, function(err) {
            return Promise.reject(err);
        });
    }
}