export default class Student {
    constructor(options) {
      Object.assign(this, options)
    }
  
    toSend() {
      return {
        studentId: this.id,
        userProfile: this.mapUserProfile(this.userProfile),
        type: this.type,
        //universityId: this.universityToApply.id,
        careerId: (this.careerToApply) ? this.careerToApply.id : null,
        highSchoolId: (this.highSchool) ? this.highSchool.id : null,
        homePhone: this.homePhone,
        cellPhone: this.cellPhone,
        about: this.about,
        homeAddress: this.homeAddress,
        hasDiseaseOrSpecialCondition: this.hasDiseaseOrSpecialCondition,
        pageUrlFacebook: this.pageUrlFacebook,
        pageUrlInstagram: this.pageUrlInstagram,
        selectedCareerObservation: this.selectedCareerObservation,
        advisoryObservation: this.advisoryObservation,
        subjectComplicationObservation: this.subjectComplicationObservation,
        universitiesToApply: this.universitiesToApply,
      }
    }

    mapUserProfile(data) {
      return {
        userId: this.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: data.status,
        userName: data.userName,
        password: data.password,
        roleId: (data.role) ? data.role.id : '',
      }
    }

    toSendStudentInfo() {
      return {
        //universityId: this.universityToApply.id,
        // careerId: this.careerToApply.id,
        highSchoolId: this.highSchool.id,
        // universitiesToApply: this.universitiesToApply,
        careers: this.careersToApply.map((item) => item),
        hasDiseaseOrSpecialCondition: this.hasDiseaseOrSpecialCondition,
        selectedCareerObservation: this.selectedCareerObservation,
        advisoryObservation: this.advisoryObservation,
        subjectComplicationObservation: this.subjectComplicationObservation,
        about: this.about,
        homeAddress: this.homeAddress,
      }
    }

  }