<script>
import ListSimulatorsComponent from '../../features/lists/ListSimulatorsComponent';
import ModalSimulatorOfficialView from '../modals/ModalSimulatorOfficialView';
import ModalImportView from '../../../shared/views/ModalImportView';


export default {
  extends: ListSimulatorsComponent,
  name: 'ListSimulatorsView',
  components: {
    'modal-simulator-view': ModalSimulatorOfficialView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl course-categories">
      <el-col :xs="24" :sm="9" :md="11" :lg="8" :xl="6" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Simulador</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Simulador</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button size="small" class="btn-primary-light btn-primary-full" @click="onExportToExcel()" :disabled="!onCanRead(modulePermissions)">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar Excel</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> Filtrar Por <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.difficultyLevelFilter" clearable placeholder="Dificultad..."
                @change="onAllFilterChange(filterOptions.difficultyLevelFilter.id, 'DifficultyLevel')" @focus="onFocus"
                value-key="id">
                  <el-option v-for="item in difficultyLevels" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="6" :lg="6" :xl="6">
        <el-tooltip class="item" effect="dark" content="Buscar por nombre." placement="top">
          <el-input size="small" placeholder="Buscar nombre..." icon="search" v-model='searchName'
          @keyup.enter.native="onAllFilterChange(searchName, 'Name')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="Nombre" prop="name" />
      <el-table-column label="¿Publicado?" width="150">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isPublic" active-color="#13ce66" active-text="SI" inactive-text="NO" disabled ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="¿Es Temporizado?" width="150">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isTimer" active-color="#13ce66" active-text="SI" inactive-text="NO" disabled ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="DIFICULTAD" width="120" align="center">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.difficultyLevel === 1" type="info" effect="plain">FACIL</el-tag>
            <el-tag v-if="scope.row.difficultyLevel === 2" type="success" effect="plain">INTERMEDIO</el-tag>
            <el-tag v-if="scope.row.difficultyLevel === 3" type="success" effect="plain">DIFICIL</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="OPCIONES" :width="160" align="center" text-align="center">
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{ command: 'edit', item: scope.row }" :disabled="!onCanRead(modulePermissions)">
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item :command="{ command: 'copy', item: scope.row }" :disabled="!onCanRead(modulePermissions)">
                <i class="el-icon-copy-document" aria-hidden="true"></i> Copiar
              </el-dropdown-item>
              <el-dropdown-item :command="{ command: 'delete', item: scope.row }" :disabled="!onCanDelete(modulePermissions)">
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination small layout="prev, pager, next" :page-count="pagination.totalPages" :current-page.sync="currentPage" @current-change="onPageChange"></el-pagination>
    <modal-simulator-view :formData="item" :conf="modalItem" v-if="modalItem.isVisible"></modal-simulator-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>