<script>
import ModalLiveClassComponent from '../../features/modals/ModalLiveClassComponent';
export default {
  extends: ModalLiveClassComponent,
  name: 'ModalLiveClassView',
};
</script>

<template>
  <el-dialog
    title="Clase en vivo"
    :visible.sync="conf.isVisible"
    width="35%"
    custom-class="live-class-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Clases en Vivo</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Curso">
              <el-select
                v-model="formData.course"
                clearable
                filterable
                remote
                :remote-method="onSearchCourse"
                placeholder="Seleccionar"
                value-key="id"
                size="small"
              >
                <el-option
                  v-for="item in courses"
                  :key="item.id"
                  :label="item.title"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Titulo" prop="title">
              <el-input v-model="formData.title" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Descripción" prop="description">
              <el-input v-model="formData.description" type="textarea" :row="3" maxlength="255" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
            <el-form-item label="Url de Stream" prop="streamUrl" required>
              <el-input v-model="formData.streamUrl" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
            <el-form-item label="Stream Finalizado" prop="isFinished">
              <el-switch
                      v-model="formData.isFinished"
                      active-color="#13ce66"
                      active-text="SI"
                      inactive-text="NO"
                      size="small"
                    >
                    </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
