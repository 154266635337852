import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import CourseSubjectViewModel from '../../../../core/features/course/viewmodels/CourseSubjectViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            parentFilter: '',
            parents: [],
            viewModel: null,
            permissions: null,
            modules: [],
            itemCourseSubjectActivity: null,
            courseSubjectActivitySelectedIndex: null,
            courseSubjectActivityTypes: [],
            modalItemCourseSubjectActivity: {
                isVisible: false,
                isLoading: false
            },
            modalItemCourseSubjectTest: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                title: [
                    { required: true, message: 'Título requerido.', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: 'Descripción requerido.', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: 'Orden requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CourseSubjectViewModel(this);
        this.onActive();
    },
    mounted() {
        EventBus.$on('onSubmitedCourseSubjectActivity', this.onAddCourseSubjectActivity);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedCourseSubjectActivity', this.onAddCourseSubjectActivity);
    },
    methods: {
        onActive() {
            this.viewModel.onBindForm();
        },
        onBindFormViewResponse() {
            this.formData.courseSubjectActivityAnswers = this.viewModel.bindSubjectActivitiesUpdateState(this.formData.courseSubjectActivities);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    EventBus.$emit('onSubmitedCourseSubject', this.formData);
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedCourseSubject', null);
        },
        onCanCreateCourseSubjectActivity(permissions) {
            return this.canCreate(permissions) || this.canUpdate(permissions)
        },
        onCreateCourseSubjectActivity() {
            this.itemCourseSubjectActivity = this.viewModel.onCreateCourseSubjectActivity({ id: CourseSubjectViewModel.ACTIVITY_TYPE_LECTURE });
            this.viewModel.showModal(this.modalItemCourseSubjectActivity, true);
        },
        onUpdateCourseSubjectActivity(item) {
            this.itemCourseSubjectActivity = this.viewModel.onEditCourseSubjectActivity(item);
            if (this.itemCourseSubjectActivity.courseSubjectActivityType === 2) {
                this.viewModel.showModal(this.modalItemCourseSubjectTest, true);
            } else {
                this.viewModel.showModal(this.modalItemCourseSubjectActivity, true);
            }
        },
        onDeleteCourseSubjectActivity(index) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning' })
                .then(() => {
                    this.viewModel.removeSubjectActivity(this.formData.courseSubjectActivities, index)
                    this.courseSubjectActivitySelectedIndex = null;
                    this.viewModel.changeDrag(this.formData.courseSubjectActivities);
                })
                .catch(() => {

                });
        },
        onAddCourseSubjectActivity(item) {
            if (this.courseSubjectActivitySelectedIndex != null) {
                if (item.id) {
                    var index = _.findIndex(this.formData.courseSubjectActivities, { id: item.id })
                    if (index != -1) {
                        this.$set(this.formData.courseSubjectActivities, index, item);
                    }
                } else {
                    this.$set(this.formData.courseSubjectActivities, this.courseSubjectActivitySelectedIndex, item);
                }
            } else {
                let nextPosition = _.size(this.formData.courseSubjectActivities);
                this.$set(this.formData.courseSubjectActivities, nextPosition, item);

            }
            this.courseSubjectActivitySelectedIndex = null;
            this.viewModel.showModal(this.modalItemCourseSubjectActivity, false);
            this.viewModel.showModal(this.modalItemCourseSubjectTest, false);
        },
        onCreateCourseSubjectTest() {
            this.itemCourseSubjectActivity = this.viewModel.onCreateCourseSubjectActivity({ id: CourseSubjectViewModel.ACTIVITY_TYPE_TEST });
            this.viewModel.showModal(this.modalItemCourseSubjectTest, true);
        },
        onCanReadCourseSubjectActivity(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteCourseSubjectActivity(permissions) {
            return this.canDelete(permissions);
        },
        onChangeItemOptions(option) {
            this.courseSubjectActivitySelectedIndex = option.index;
            if (option.command === 'edit') this.onUpdateCourseSubjectActivity(option.item);
            if (option.command === 'delete') this.onDeleteCourseSubjectActivity(option.index);
        },
        onSearchCourseSubjectActivityTypesResponse(response) {
            this.courseSubjectActivityTypes = response.data;
        },
        getActivityTypeName(courseSubjectActivityType) {
            return this.viewModel.getActivityTypeName(this.courseSubjectActivityTypes, courseSubjectActivityType);
        },
        sortCourseSubjectActivities(collection) {
            return this.viewModel.filterAndSortSubjectActivities(collection);
        },
        getDurationTotal(totalHours, duration) {
            return (totalHours) ? totalHours : this.viewModel.getTimeFromMins(duration);
        },
        onChangeDrag(collection) {
            this.viewModel.changeDrag(collection);
        }
    }
}