import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            permissions: null,
            modules: [],
            courseFilter: '',
            courses: [],
            currentPdfViewer: null,
            rules: {
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.onActive();
    },
    mounted() { },
    methods: {
        onActive() {
            if (this.formData.hasFile) {
                this.currentPdfViewer = `/ViewerJS/#..${this.formData.fileUrl}`            
            }
        },
        onBindFileContextMenu(evt) {
            setTimeout(() => {
                if (!this.formData.isDownload) {
                    $('#file-wrapper-viewer').contents().find('#download').css("display", "none");
                }
                $('#file-wrapper-viewer').contents().find('#documentName').hide();
                $('#file-wrapper-viewer').contents().find('#about').hide();
            }, 400);
        }
    }
}