<script>
import ModalCourseComponent from '../../features/modals/ModalCourseComponent';
import ModalCourseSubjectView from './ModalCourseSubjectView.vue';
import draggable from 'vuedraggable';

export default {
  extends: ModalCourseComponent,
  name: 'ModalCourseView',
  components: {
    'modal-course-subject-view': ModalCourseSubjectView,
    draggable,
  },
};
</script>

<template>
  <div>
	  <el-dialog
    title="Curso"
    :visible.sync="conf.isVisible"
    width="70%"
    custom-class="course-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
    >
      <el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'top'" v-loading="conf.isLoading">
        <div class="course_tabs_1">
          <div id="add-course-tab" class="step-app">
            <ul class="step-steps">
              <li class="active">
                <a href="#tab_step1">
                  <span class="number"></span>
                  <span class="step-name">INFORMACIÓN GENERAL</span>
                </a>
              </li>
              <li>
                <a href="#tab_step2">
                  <span class="number"></span>
                  <span class="step-name">VISTA</span>
                </a>
              </li>
              <li>
                <a href="#tab_step3">
                  <span class="number"></span>
                  <span class="step-name">CONTENIDO DEL CURSO</span>
                </a>
              </li>
              <li>
                <a href="#tab_step4">
                  <span class="number"></span>
                  <span class="step-name">PROFESORES</span>
                </a>
              </li>
            </ul>
            <div class="step-content">
              <div class="step-tab-panel step-tab-info active" id="tab_step1">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class="uil uil-info-circle"></i>Informaci&oacute;n
                      General
                    </h3>
                  </div>
                  <fieldset>
                    <el-row class="row-bg" :gutter="20">
                      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                        <el-form-item label="Categoria" prop="courseCategory">
                          <el-select
                            v-model="formData.courseCategory"
                            clearable
                            filterable
                            remote
                            :remote-method="onSearchCourseCategory"
                            placeholder="Seleccionar"
                            value-key="id"
                            size="small"
                          >
                            <el-option
                              v-for="item in courseCategories"
                              :key="item.id"
                              :label="item.name"
                              :value="item"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
                        <el-form-item label="Universidad" prop="university">
                          <el-select v-model="formData.university"
                            allow-create
                            clearable
                            filterable
                            remote
                            :remote-method="onSearchUniversities"
                            @change="onChangeSelectUniversities"
                            placeholder="Seleccionar"
                            value-key="id"
                            size="small"
                          >
                            <el-option v-for="item in universities" :key="item.id" :label="item.name" :value="item"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row-bg" :gutter="20">
                      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                        <el-form-item label="Código" prop="code">
                          <el-input
                            v-model="formData.code"
                            size="small"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="19" :md="19" :lg="19" :xl="19">
                        <el-form-item label="Titulo" prop="title">
                          <el-input
                            v-model="formData.title"
                            maxlength="175"
                            size="small"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row-bg" :gutter="20">
                      <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                      <el-form-item label="Descripción" prop="description">
                          <el-input
                            v-model="formData.description"
                            maxlength="255"
                            size="small"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                        <el-form-item label="Publicar" prop="isPublished">
                          <el-switch
                            style="display: block"
                            v-model="formData.isPublished"
                            active-color="#13ce66"
                            inactive-text="No"
                            active-text="Si"
                            :inactive-value="false"
                            :active-value="true"
                          ></el-switch>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    
                    <el-row type="flex" class="row-bg" :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="Acerca de" prop="tags">
                          <el-input type="textarea" class="about-editor"
                              :row="3"
                              maxlength="255"
                              size="medium"
                              v-model="formData.about"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row type="flex" class="row-bg" :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="Etiquetas" prop="tags">
                          <el-tag
                            type="default"
                            effect="plain"
                            :key="tag"
                            v-for="tag in dynamicTags"
                            closable
                            :disable-transitions="false"
                            @close="handleTagClose(tag)">
                            {{tag}}
                          </el-tag>
                          <div style="margin-top: 30px;">
                            <el-input placeholder="Ingresar etiqueta"
                                class="input-new-tag"
                                v-if="inputTagsVisible"
                                v-model="inputTagsValue"
                                ref="saveTagInput"
                                size="mini"
                                @blur="handleTagConfirm">
                              <el-button slot="append" type="primary" size="small" icon="el-icon-plus" @click="handleTagConfirm"></el-button>
                            </el-input>
                            <el-button v-else type="primary" class="btn-primary button-new-tag" size="small" @click="showTagInput">+ Agregar</el-button>
                          </div>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </fieldset>
                </div>
              </div>

              <div class="step-tab-panel step-tab-gallery" id="tab_step2">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class="uil uil-image-upload"></i>Vista
                    </h3>
                  </div>
                  <div class="course__form">
                    <div class="view_info10">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="view_all_dt">
                            <el-row class="row-bg" :gutter="20">
                              <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                                <div class="view__img">
                                  <img :src="coverImageUrl" alt="" />
                                </div>
                              </el-col>
                              <el-col :xs="24" :sm="12" :md="14" :lg="14" :xl="14">
                                <div class="view_info_right">
                                  <h4>Imagen de Portada</h4>
                                    <p>
                                      Subir imagen del curso aquí. Debes conocer nuestro estandar de calidad 
                                      para las imagenes de portada de cursos aceptadas.
                                      Formato Importante: 750x422 pixeles; .jpg,
                                      .jpeg,. gif, or .png. imagen sin texto.
                                    </p>
                                    <div class="upload__input">
                                      <div class="input-group">
                                        <div class="custom-file">
                                          <el-form-item prop="coverImage">
                                              <el-upload
                                                class="file-uploader"
                                                :name="'coverImageFileUploader'"
                                                v-if="!isCoverImageTemp && !formData.coverImageUrl"
                                                action
                                                :on-success="onSuccessUploadCoverImage"
                                                :before-upload="onBeforeUploadCoverImage"
                                                :show-file-list="false"
                                                :data="coverImageFile"
                                              >
                                                <el-button size="small" type="primary" class="btn-primary">Clic para subir archivo</el-button>
                                              </el-upload>
                                              <p v-if="isCoverImageTemp || formData.coverImageUrl">
                                                <br/>
                                                <el-button size="small" type="primary" class="btn-primary" @click="onRemoveCoverImage"><i class="el-icon-circle-close"></i> Eliminar</el-button>
                                              </p>
                                            </el-form-item>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </el-col>
                            </el-row>
                            <!--
                            <div class="view_img_left">
                              <div class="view__img">
                                <img :src="coverImageUrl" alt="" />
                              </div>
                            </div>
                            <div class="view_img_right">
                              <h4>Imagen de Portada</h4>
                              <p>
                                Subir imagen del curso aquí. Debes conocer nuestro estandar de calidad 
                                para las imagenes de portada de cursos aceptadas.
                                Formato Importante: 750x422 pixeles; .jpg,
                                .jpeg,. gif, or .png. imagen sin texto.
                              </p>
                              <div class="upload__input">
                                <div class="input-group">
                                  <div class="custom-file">
                                    <el-form-item prop="coverImage">
                                        <el-upload
                                          class="file-uploader"
                                          :name="'coverImageFileUploader'"
                                          v-if="!isCoverImageTemp && !formData.coverImageUrl"
                                          action
                                          :on-success="onSuccessUploadCoverImage"
                                          :before-upload="onBeforeUploadCoverImage"
                                          :show-file-list="false"
                                          :data="coverImageFile"
                                        >
                                          <el-button size="small" type="primary" class="btn-primary">Clic para subir archivo</el-button>
                                        </el-upload>
                                        <p v-if="isCoverImageTemp || formData.coverImageUrl">
                                          <br/>
                                          <el-button size="small" type="primary" class="btn-primary" @click="onRemoveCoverImage"><i class="el-icon-circle-close"></i> Eliminar</el-button>
                                        </p>
                                      </el-form-item>
                                  </div>
                                </div>
                              </div>
                            </div>
                            -->
                          </div>
                          <div class="view_all_dt">
                            <el-row class="row-bg" :gutter="20">
                              <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                                <div class="view__img">
                                  <img src="/images/courses/add_video.jpg" alt="" v-if="!formData.promotionalVideoUrl"/>
                                  <iframe 
                                    v-if="formData.promotionalVideoUrl" :src="formData.promotionalVideoUrl"
                                    width="500" height="250" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="width:100%"></iframe>
                                </div>
                              </el-col>
                              <el-col :xs="24" :sm="12" :md="14" :lg="14" :xl="14">
                                <div class="view_info_right">
                                  <h4>Video Promocional</h4>
                                  <p>
                                    Estudiantes que ven videos promocionales bien hechos, tienen más probabilidades de inscribirse al curso. 
                                    Hemos visto distintas estadísticas y el crecimiento es impresionante!
                                  </p>
                                  <div class="upload__input">
                                    <div class="input-group">
                                      <div class="custom-file">
                                        <div class="ui left icon mt-50" style="width:100%;">
                                          <el-form-item prop="promotionalVideoUrl" style="width:100%;">
                                              <el-input v-model="formData.promotionalVideoUrl" :value="formData.promotionalVideoUrl" type="textarea" :row="2"></el-input>
                                          </el-form-item>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </el-col>
                            </el-row>
                            <!--
                            <div class="view_img_left">
                              <div class="view__img">
                                <img src="/images/courses/add_video.jpg" alt="" v-if="!formData.promotionalVideoUrl"/>
                                <iframe v-if="formData.promotionalVideoUrl"
                                :src="formData.promotionalVideoUrl" width="500" height="250" frameborder="0" allow="autoplay; fullscreen" allowfullscreen
                                style="width:100%"></iframe>
                              </div>
                            </div>
                            <div class="view_img_right">
                              <h4>Video Promocional</h4>
                              <p>
                                Estudiantes que ven videos promocionales bien hechos, tienen más probabilidades de inscribirse al curso. 
                                Hemos visto distintas estadísticas y el crecimiento es impresionante!
                              </p>
                              <div class="upload__input">
                                <div class="input-group">
                                  <div class="custom-file">
                                    <div class="ui left icon mt-50" style="width:100%;">
                                      <el-form-item prop="promotionalVideoUrl" style="width:100%;">
                                          <el-input v-model="formData.promotionalVideoUrl" :value="formData.promotionalVideoUrl" type="textarea" :row="2"></el-input>
                                      </el-form-item>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="step-tab-panel step-tab-location" id="tab_step3">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class="uil uil-film"></i>Contenido de Curso
                    </h3>
                  </div>
                  <el-row>
                    <el-col :span="24">
                      <el-button type="primary" size="small" class="btn-primary" icon="el-icon-circle-plus" @click="onCreateCourseSubject" 
                      :disabled="!onCanCreateCourseSubject(modulePermissions)">
                        Agregar Tema
                      </el-button>
                    </el-col>
                  </el-row>
                  <div class="table-responsive mt-30" style="overflow-x:auto;">
                    <div class="divTable redTable">
                      <div class="divTableHeading">
                        <div class="divTableRow">
                          <div class="divTableHead center" style="min-width:90px;">ORDEN</div>
                          <div class="divTableHead" style="min-width:200px;">TÍTULO</div>
                          <div class="divTableHead center" style="min-width:110px;">ACTIVIDADES</div>
                          <div class="divTableHead" style="min-width:110px;">DURACIÓN</div>
                          <div class="divTableHead center" style="min-width:90px;">OPCIONES</div>
                        </div>
                      </div>
                    </div>
                    <draggable v-model="formData.courseSubjects" @change="onChangeDrag">
                      <transition-group>
                        <div v-for="(element, indexCourseSubject) in orderCourseSubjects()" :key="element.sort" class="item">
                          <div class="divTable redTable">
                            <div class="divTableBody">
                              <div class="divTableRow">
                                <div class="divTableCell center" style="min-width:90px;">{{ element.sort }}</div>
                                <div class="divTableCell" style="min-width:200px;">{{ element.title }}</div>
                                <div class="divTableCell center" style="min-width:110px;">{{ getSubjectActivitiesTotal(element.courseSubjectActivities) }}</div>
                                <div class="divTableCell" style="min-width:110px;">{{ getSubjectActivitiesDurationTotal(element.totalHours, element.courseSubjectActivities) }} Hrs.</div>
                                <div class="divTableCell center" style="min-width:90px;">
                                  <el-dropdown @command="onChangeItemOptions">
                                    <el-button size="small">
                                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                      <el-dropdown-item :command="{ command: 'edit', item: element, index: indexCourseSubject }" :disabled="!onCanReadCourseSubject(modulePermissions)">
                                        <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
                                      </el-dropdown-item>
                                      <el-dropdown-item :command="{ command: 'delete', item: element, index: indexCourseSubject }" :disabled="!onCanDeleteCourseSubject(modulePermissions)">
                                        <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
                                      </el-dropdown-item>
                                    </el-dropdown-menu>
                                  </el-dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                    <!--
                    <el-table :data="orderCourseSubjects(this.formData.courseSubjects)" empty-text="No se encontraron resultados.">
                      <el-table-column label="ORDEN" width="90" align="center">
                        <template slot-scope="scope">
                        <div>
                            {{ scope.row.sort }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="TÍTULO">
                        <template slot-scope="scope">
                        <div>
                            {{ scope.row.title }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="ACTIVIDADES" width="110" align="center">
                        <template slot-scope="scope">
                        <div>
                            {{ getSubjectActivitiesTotal(scope.row.courseSubjectActivities) }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="DURACIÓN" width="110" align="center">
                        <template slot-scope="scope">
                          <div>
                            {{ getSubjectActivitiesDurationTotal(scope.row.totalHours, scope.row.courseSubjectActivities) }} Hrs.
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="OPCIONES"
                        :width="160"
                        align="center"
                        text-align="center"
                      >
                        <template slot-scope="scope">
                          <el-dropdown @command="onChangeItemOptions">
                            <el-button size="small">
                              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                :command="{ command: 'edit', item: scope.row, index: scope.$index }"
                                :disabled="!onCanReadCourseSubject(modulePermissions)"
                              >
                                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
                              </el-dropdown-item>
                              <el-dropdown-item
                                :command="{ command: 'delete', item: scope.row, index: scope.$index }"
                                :disabled="!onCanDeleteCourseSubject(modulePermissions)"
                              >
                                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </el-table-column>
                    </el-table>
                    -->
                  </div>
                </div>
              </div>

              <div class="step-tab-panel step-tab-amenities" id="tab_step4">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class="uil uil-file-copy-alt"></i>Profesores
                    </h3>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-lg-12">
                        <el-select
                          v-model="instructorSelected"
                          filterable
                          remote
                          placeholder="Buscar por nombre y seleccionar resultado para agregar"
                          :remote-method="onSearchInstructors"
                          @change="onSelectInstructor"
                          size="medium">
                          <el-option
                            v-for="item in instructors"
                            :key="item.id"
                            :label="item.getFullName()"
                            :value="item">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-lg-12">
                        <el-table
                          :data="formData.instructors"
                          empty-text="No se encontraron resultados."
                        >
                          <el-table-column label="" width="80">
                            <template slot-scope="scope">
                              <div>
                                <el-avatar :src="mapInstructorImage(scope.row.userProfile)"></el-avatar>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column label="NOMBRE DEL PROFESOR" min-width="300">
                            <template slot-scope="scope">
                              <div v-if="scope.row.getFullName">
                                {{ scope.row.getFullName() }}
                              </div>
                            </template>
                          </el-table-column>
                          
                          <el-table-column
                            label="OPCIONES"
                            :width="120"
                            align="center"
                            text-align="center"
                          >
                            <template slot-scope="scope">
                              <el-button-group>
                                <el-button @click="onRemoveInstructor(scope.$index)" size="small" class="btn-cancel">
                                  <i class="el-icon-delete" aria-hidden="true"></i>
                                </el-button>
                              </el-button-group>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step-footer step-tab-pager">
              <button data-direction="prev" class="btn btn-default steps_btn btn-primary">
                Anterior
              </button>
              <button data-direction="next" class="btn btn-default steps_btn btn-primary">
                Siguiente
              </button>
              <button data-direction="finish" class="btn btn-default steps_btn btn-primary" @click="onSubmitForm('formData')" :disabled="!onCanSaveOrUpdate()">
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Guardar</el-button
        >
      </span>-->
    </el-dialog>
    <modal-course-subject-view 
      :formData="itemCourseSubject"
      :conf="modalItemCourseSubject"
      v-if="modalItemCourseSubject.isVisible">
    </modal-course-subject-view>
  </div>
</template>
