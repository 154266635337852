<script>
import ModalSimulatorQuestionComponent from '../../features/modals/ModalSimulatorQuestionComponent';
export default {
  extends: ModalSimulatorQuestionComponent,
  name: 'ModalSimulatorQuestionView',
};
</script>

<template>
  <el-dialog
    title="Pregunta de Simulador"
    :visible.sync="conf.isVisible"
    width="65%"
    custom-class="simulator-question-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Pregunta</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <el-form-item label="Área de Conocimiento" prop="courseCategory">
              <el-select v-model="formData.courseCategory" clearable filterable remote :remote-method="onFindCourseCategory" @focus="onFocus" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in courseCategories" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <el-form-item label="Tipo de Simulador" prop="type">
              <el-select v-model="formData.type" clearable filterable remote :remote-method="onFindSimulatorExamType" @focus="onFocus" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in simulatorTypes" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" align="center">
            <el-form-item label="Nivel de Dificultad" prop="difficultyLevel">
              <el-select v-model="formData.difficultyLevel" clearable placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in difficultyLevels" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="Universidad" prop="university">
              <el-select v-model="formData.university" clearable filterable remote :remote-method="onFindUniversity" @focus="onFocus" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in universities" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="Carrera" prop="career">
              <el-select v-model="formData.career" clearable filterable remote :remote-method="onFindCareer" @focus="onFocus" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in careers" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Pregunta" prop="name">
              <el-input v-model="formData.name" maxlength="70" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Descripción" prop="tags">
              <el-input type="textarea" class="description-editor"
                  :row="4"
                  maxlength="255"
                  size="medium"
                  v-model="formData.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-button
                type="primary"
                size="small"
                class="btn-primary"
                icon="el-icon-circle-plus"
                @click="onAddAnswer"
                >Agregar Respuesta</el-button
              >
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <br />
              <el-table :data="formData.getAnswers()" @current-change="onUpdateAnswer" row-key="id" empty-text="No se encontraron resultados."
              v-loading="isAnswerLoading">
                <el-table-column label="RESPUESTA" min-width="300">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.description" size="small" :class="'answer_editor_' + scope.$index"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="CORRECTO" min-width="120" align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-switch
                        v-model="scope.row.success"
                        active-color="#13ce66"
                        active-text="SI"
                        inactive-text="NO"
                        size="small"
                      >
                      </el-switch>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="OPCIONES" :width="90" align="center" text-align="center">
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-button
                        type="default"
                        size="small"
                        icon="el-icon-delete"
                        @click="
                          onDeleteAnswer(scope.$index)
                        "
                      ></el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>