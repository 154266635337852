import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiSimulators extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findRandomQuestions(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/random-questions${parameters}`)
    }

    findDetails(id) {
        return this.client.get(`${this.endPoint}/${id}/details`)
    }

    generateTemporalSimulator(data) {
        return this.client.post(`${this.endPoint}/generate-temporal-simulator`, data)
    }

    findTemporalDetails(id) {
        return this.client.get(`${this.endPoint}/${id}/temporal-details`)
    }

    findQuestionProgress(data) {
        return this.client.post(`${this.endPoint}/question-answer-progress`, data)
    }

    findTemporalQuestionProgress(data) {
        return this.client.post(`${this.endPoint}/temporal-question-answer-progress`, data)
    }

    updateProgress(data) {
        return this.client.post(`${this.endPoint}/update-progress`, data)
    }

    updateTemporalProgress(data) {
        return this.client.post(`${this.endPoint}/update-temporal-progress`, data)
    }

    findSimulatorResult(simulatorId) {
        return this.client.get(`${this.endPoint}/${simulatorId}/result`)
    }

    findTemporalSimulatorResult(simulatorId) {
        return this.client.get(`${this.endPoint}/${simulatorId}/temporal-result`)
    }
    
    findPublished(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/explorer${parameters}`)
    }
}