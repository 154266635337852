import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import StudentViewModel from '../../../../core/features/students/viewmodels/StudentViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'StudentsContent-Students',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      careers: [],
      userStatuses: [],
      filterOptions: {
        careerFilter: '',
        emailFilter: '',
        fullNameFilter: '',
        status: ''
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR CATEGORIAS DE CURSOS',
        onUploadImport: 'onUploadImportStudent',
        onCancelImport: 'onCancelImportStudent'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedStudent', this.onSearch);
    EventBus.$on('onUploadImportStudent', this.onUploadImport);
    EventBus.$on('onCancelImportStudent', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedStudent', this.onSearch);
    EventBus.$off('onUploadImportStudent', this.onUploadImport);
    EventBus.$off('onCancelImportStudent', this.onCancelImport);
  },
  created() {
    this.viewModel = new StudentViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onSearchUserStatus()
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchCareers(data) {
      if (data) {
          this.viewModel.findCareers(`Name=${data}`)
      } else {
          this.careers = []
      }
    },
    onSearchCareersResponse(response) {
      this.careers = response;
    },
    onFocusCareer() {
      this.$forceUpdate()
    },
    onSearchUserStatus() {
      this.viewModel.findUserStatuses()
    },
    onSearchUserStatusesResponse(response) {
      this.userStatuses = response;
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    mapProfileImageUrl(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-2.jpg';
    },
    hasFacebookPage(student) {
      return !_.isNil(student.pageUrlFacebook) && !_.isEmpty(student.pageUrlFacebook);
    },
    hasInstagramPage(student) {
      return !_.isNil(student.pageUrlInstagram) && !_.isEmpty(student.pageUrlInstagram);
    },
    mapProfileImage(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
    },
    onChangeStatus(student) {
      this.viewModel.doChangeStatus({
        studentId: student.id,
        status: student.userProfile.status
      })
    },
    onChangeStatusResponse(response){
      this.onSearch();
    }
  },
  computed: {}
}