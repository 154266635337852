import _ from 'lodash';
import FileHelper from '../../../shared/helpers/FileHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import Instructor from '../models/Instructor';

export default class InstructorViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onCreate() {
    let item = new Instructor({
      state: "create",
      userProfile: {
        status: 1
      }
    });
    return item;
  }

  onDelete(data) {
    let item = new Instructor(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new Instructor(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.instructors.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Instructor(data));
  }

  onSearchItem(id) {
    return this.api.instructors.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.instructors.create(this.mappingFormDataToSend(data.toSend(), this.view.profileImageFile))
    else
      return this.api.instructors.update(data.id, this.mappingFormDataToSend(data.toSend(), this.view.profileImageFile));
  }

  onDestroy(context, id) {
    context.api.instructors.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.instructors.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.instructors.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {
    let profileUrlBase = '/images/left-imgs/img-1.jpg';
    if (this.view.formData.state === 'update') {
      this.view.profileImageUrl = (_.isNil(this.view.formData.userProfile.profileImageUrl)) ? profileUrlBase : this.view.formData.userProfile.profileImageUrl;
    }
  }

  doChangeStatus(data) {
    this.api.instructors.doChangeStatus(data)
      .then((response) => this.view.onChangeStatusResponse(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region QUERIES
  /**
   * Metodo para buscar estados de usuario
   * @param {String} data Query string para parametros de búsqueda
   */
  findUserStatuses() {
    this.api.userStatuses.find()
      .then((response) => this.view.onSearchUserStatusesResponse(response.data.data))
      .catch(this.view.onError)
  }

  // #endregion

  //#region MAPPING DATA

  mappingFormDataToSend(data, profileImageFile) {
    const fd = new FormData()
    fd.append('profileImageFile', profileImageFile)
    fd.append('instructor', JSON.stringify(data))
    return fd;
  }

  convertFileToBase64(file) {
    return FileHelper.toBase64(file);
  }

  // #endregion

  // #region VALIDATIONS
  /**
   * Metodo para verificar si password con password de confirmación son iguales
   * @param {String} value Password a confirmar 
   * @param {*} callback Metodo de respuesta a la verificación
   */
  verifyConfirmationPassword(value, callback) {
    if (value === '') {
      callback(new Error('Favor de ingresar información.'))
    } else if (value !== this.view.formData.userProfile.password) {
      callback(new Error('Las contraseñas no coinciden.'))
    } else {
      callback()
    }
  }

  // #endregion
}