<script>
import ModalCourseSubjectComponent from '../../features/modals/ModalCourseSubjectComponent';
import ModalCourseSubjectActivityView from './ModalCourseSubjectActivityView';
import ModalCourseSubjectTestView from './ModalCourseSubjectTestView';
import draggable from 'vuedraggable';

export default {
  extends: ModalCourseSubjectComponent,
  name: 'ModalCourseSubjectView',
  components: {
    'modal-course-subject-activity-view': ModalCourseSubjectActivityView,
    'modal-course-subject-test-view': ModalCourseSubjectTestView,
    draggable,
  },
};
</script>

<template>
  <div>
    <el-dialog
      title="Tema"
      :visible.sync="conf.isVisible"
      width="60%"
      custom-class="course-subject-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Información de Tema</legend>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="18 " :md="18" :lg="20" :xl="20">
              <el-form-item label="Título" prop="title">
                <el-input v-model="formData.title" maxlength="120" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6" :lg="4" :xl="4">
              <el-form-item label="Orden" prop="sort">
                <el-input-number v-model="formData.sort" size="small" :disabled="true"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Descripción" prop="description">
                <el-input v-model="formData.description" type="textarea" :row="3" maxlength="255" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button-group>
                <el-button
                type="primary"
                size="small"
                class="btn-primary"
                icon="el-icon-circle-plus"
                @click="onCreateCourseSubjectActivity"
                :disabled="!onCanCreateCourseSubjectActivity(modulePermissions)"
              >
                Agregar Actividad
              </el-button>
              <el-button
                type="primary"
                size="small"
                class="btn-primary"
                icon="el-icon-circle-plus"
                @click="onCreateCourseSubjectTest"
                :disabled="!onCanCreateCourseSubjectActivity(modulePermissions)">
                Agregar Examen
              </el-button>
              </el-button-group>
            </el-col>
          </el-row>
          <br />
          <el-row>
            <el-col :span="24">
              <div style="overflow-x:auto;">
                <div class="divTable redTable">
                  <div class="divTableHeading">
                    <div class="divTableRow">
                      <div class="divTableHead center" style="min-width:90px;">ORDEN</div>
                      <div class="divTableHead" style="min-width:200px;">TÍTULO</div>
                      <div class="divTableHead center" style="min-width:110px;">TIPO</div>
                      <div class="divTableHead" style="min-width:80px; text-align:center;">ESTADO</div>
                      <div class="divTableHead" style="min-width:80px; text-align:center;">DURACIÓN</div>
                      <div class="divTableHead center" style="min-width:90px;">OPCIONES</div>
                    </div>
                  </div>
                </div>
                <draggable v-model="formData.courseSubjectActivities" @change="onChangeDrag(formData.courseSubjectActivities)" draggable=".item">
                  <div v-for="(element, indexActivity) in sortCourseSubjectActivities(formData.courseSubjectActivities)" :key="element.id" class="item">
                    <div class="divTable redTable">
                      <div class="divTableBody">
                        <div class="divTableRow">
                          <div class="divTableCell center" style="min-width:90px;">{{ element.sort }}</div>
                          <div class="divTableCell" style="min-width:200px;">{{ element.title }}</div>
                          <div class="divTableCell center" style="min-width:110px;">
                            <el-tag type="default" effect="plain">
                              {{ getActivityTypeName(element.courseSubjectActivityType) }}
                            </el-tag>
                          </div>
                          <div class="divTableCell" style="min-width:80px; text-align:center;">
                            <el-tag type="default" effect="plain" v-if="element.isPublic">
                              PUBLICO
                            </el-tag>
                            <el-tag type="default" effect="plain" v-if="!element.isPublic">
                              PRIVADO
                            </el-tag>
                          </div>
                          <div class="divTableCell" style="min-width:80px; text-align:center;">
                            <el-tag type="default" effect="plain">
                              {{ getDurationTotal(element.totalHours, element.duration) }}
                            </el-tag>
                          </div>
                          <div class="divTableCell center" style="min-width:90px;">
                            <el-dropdown @command="onChangeItemOptions">
                              <el-button size="small">
                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </el-button>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                  :command="{ command: 'edit', item: element, index: indexActivity }"
                                  :disabled="
                                    !onCanReadCourseSubjectActivity(modulePermissions)
                                  "
                                >
                                  <i class="el-icon-edit" aria-hidden="true"></i>
                                  Mostrar
                                </el-dropdown-item>
                                <el-dropdown-item
                                  :command="{ command: 'delete', item: element, index: indexActivity }"
                                  :disabled="
                                    !onCanDeleteCourseSubjectActivity(modulePermissions)
                                  "
                                >
                                  <i class="el-icon-delete" aria-hidden="true"></i>
                                  Eliminar
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
              <!--
              <el-table
                :data="sortCourseSubjectActivities(formData.courseSubjectActivities)"
                empty-text="No se encontraron resultados."
              >
                <el-table-column label="ORDEN" width="90" align="center">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.sort }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="TÍTULO" min-width="120">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.title }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="TIPO" width="200" align="center">
                  <template slot-scope="scope">
                    <el-tag type="default" effect="plain">
                      {{ getActivityTypeName(scope.row.courseSubjectActivityType) }}
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column label="ESTADO" width="100" align="center">
                  <template slot-scope="scope">
                    <el-tag type="default" effect="plain" v-if="scope.row.isPublic">
                      PUBLICO
                    </el-tag>
                    <el-tag type="default" effect="plain" v-if="!scope.row.isPublic">
                      PRIVADO
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column label="DURACIÓN" width="100" align="center">
                  <template slot-scope="scope">
                    <el-tag type="default" effect="plain">
                      {{ getDurationTotal(scope.row.totalHours, scope.row.duration) }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="OPCIONES"
                  :width="160"
                  align="center"
                  text-align="center"
                >
                  <template slot-scope="scope">
                    <el-dropdown @command="onChangeItemOptions">
                      <el-button size="small">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          :command="{ command: 'edit', item: scope.row, index: scope.$index }"
                          :disabled="
                            !onCanReadCourseSubjectActivity(modulePermissions)
                          "
                        >
                          <i class="el-icon-edit" aria-hidden="true"></i>
                          Mostrar
                        </el-dropdown-item>
                        <el-dropdown-item
                          :command="{ command: 'delete', item: scope.row, index: scope.$index }"
                          :disabled="
                            !onCanDeleteCourseSubjectActivity(modulePermissions)
                          "
                        >
                          <i class="el-icon-delete" aria-hidden="true"></i>
                          Eliminar
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
              -->
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Aceptar</el-button
        >
      </span>
    </el-dialog>
    <modal-course-subject-activity-view
      :formData="itemCourseSubjectActivity"
      :conf="modalItemCourseSubjectActivity"
      v-if="modalItemCourseSubjectActivity.isVisible"
    ></modal-course-subject-activity-view>
    <modal-course-subject-test-view
      :formData="itemCourseSubjectActivity"
      :conf="modalItemCourseSubjectTest"
      v-if="modalItemCourseSubjectTest.isVisible">
    </modal-course-subject-test-view>
  </div>
</template>
