<script>
import ModalInstructorComponent from '../../features/modals/ModalInstructorComponent';
export default {
  extends: ModalInstructorComponent,
  name: 'ModalInstructorView',
};
</script>

<template>
  <el-dialog
    title="Profesor"
    :visible.sync="conf.isVisible"
    width="35%"
    custom-class="instructor-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="CUENTA" name="account">
          <fieldset>
            <legend>Información de Cuenta</legend>
            <br />
            <el-row class="row-bg" :gutter="10">
              <el-col :span="24">
                <div class="img148">
                  <el-upload
                    action
                    :show-file-list="false"
                    :on-success="onSuccessHandlerProfilePicture"
                    :before-upload="onBeforeUploadHandlerProfilePicture">
                    <img v-if="profileImageUrl" :src="profileImageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <!-- <img src="/images/left-imgs/img-1.jpg" alt="" /> -->
                </div>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="10" v-if="showStatus">
              <el-col :span="24" align="center">
                <el-form-item label="Estado" prop="status">
                  <el-switch
                    style="display: block"
                    v-model="formData.userProfile.status"
                    active-color="#13ce66"
                    inactive-text="Inactivo"
                    active-text="Activo"
                    :inactive-value="1"
                    :active-value="2"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="10">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Usuario" prop="userProfile.userName">
                  <el-input
                    v-model="formData.userProfile.userName"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Correo Electronico" prop="userProfile.email">
                  <el-input
                    v-model="formData.userProfile.email"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24" v-if="formData.id">
                <el-checkbox v-model="updatePassword"
                  >Actualizar password</el-checkbox
                >
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20" v-if="!formData.id || updatePassword">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Contraseña:" prop="userProfile.password">
                  <el-input
                    size="small"
                    type="password"
                    v-model="formData.userProfile.password"
                    placeholder="Contraseña"
                    :disabled="!isPasswordRequired()"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Verificar Contraseña:" prop="userProfile.checkPass">
                  <el-input
                    size="small"
                    type="password"
                    v-model="formData.userProfile.checkPass"
                    placeholder="Verificar Contraseña"
                    :disabled="!isPasswordRequired()"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </fieldset>
        </el-tab-pane>
        <el-tab-pane label="INFORMACIÓN PERSONAL" name="personal">
          <fieldset>
            <legend>Información de Estudiante</legend>
            <el-row class="row-bg" :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Nombre(s)" prop="userProfile.firstName">
                  <el-input
                    v-model="formData.userProfile.firstName"
                    maxlength="70"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Apellido(s)" prop="userProfile.lastName">
                  <el-input
                    v-model="formData.userProfile.lastName"
                    maxlength="70"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item
                  label="Acerca de mi"
                  prop="about"
                >
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="formData.about"
                    maxlength="255"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item
                  label="Especialidades o Áreas Educativas"
                  prop="educationSpecialty"
                >
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="formData.educationSpecialty"
                    maxlength="255"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            
          </fieldset>
        </el-tab-pane>
        <el-tab-pane label="CONTACTO" name="academic">
          <fieldset>
            <legend>Información de Académica</legend>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Página de Facebook" prop="pageUrlFacebook">
                  <el-input
                    v-model="formData.pageUrlFacebook"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item
                  label="Página de Instagram"
                  prop="pageUrlInstagram"
                >
                  <el-input
                    v-model="formData.pageUrlInstagram"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Página de Linkedin" prop="pageUrlLinkedIn">
                  <el-input
                    v-model="formData.pageUrlLinkedIn"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Teléfono de Hogar" prop="homePhone">
                  <el-input
                    v-model.number="formData.homePhone"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Teléfono Celular" prop="cellPhone">
                  <el-input
                    v-model.number="formData.cellPhone"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </fieldset>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
