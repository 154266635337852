export default {
  STUDENT_TYPE: [
    {
      id: 1,
      name: 'EN EL SITIO'
    },
    {
      id: 2,
      name: 'EN LÍNEA'
    }
  ],
  STATUS: [
    {
      id: 2,
      name: 'ACTIVO'
    },
    {
      id: 1,
      name: 'INACTIVO'
    }
  ]
}