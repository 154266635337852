import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiCourseCategories extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findPublished(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/published${parameters}`)
    }

    findByAuthor(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/by-author${parameters}`)
    }

    findDetails(courseId) {
        return this.client.get(`${this.endPoint}/${courseId}/details`)
    }

    doPublish(data) {
        return this.client.put(`${this.endPoint}/do-publish`, data)
    }

    doFavorite(courseId) {
        return this.client.post(`${this.endPoint}/${courseId}/favorite`)
    }

    doLike(courseId) {
        return this.client.post(`${this.endPoint}/${courseId}/like`)
    }

    doDislike(courseId) {
        return this.client.post(`${this.endPoint}/${courseId}/dislike`)
    }

    doViewed(courseId) {
        return this.client.post(`${this.endPoint}/${courseId}/viewed`)
    }

    doProgress(courseSubjectActivityId) {
        return this.client.post(`${this.endPoint}/${courseSubjectActivityId}/progress`)
    }

    doOpenAnswer(courseSubjectActivityId, data) {
        return this.client.post(`${this.endPoint}/${courseSubjectActivityId}/do-open-answer`, data)
    }

    doMultipleAnswer(courseSubjectActivityId, data) {
        return this.client.post(`${this.endPoint}/${courseSubjectActivityId}/do-multiple-answer`, data)
    }

    doFinish(courseId) {
        return this.client.put(`${this.endPoint}/${courseId}/do-finish`)
    }

    findTestTryNumber(courseSubjectActivityId) {
        return this.client.get(`${this.endPoint}/${courseSubjectActivityId}/try-test`)
    }

    doTestAnswer(courseSubjectActivityId, data) {
        return this.client.post(`${this.endPoint}/${courseSubjectActivityId}/do-test-answer`, data)
    }

    findTestAnswersDetails(courseSubjectActivityId, data){
        return this.client.post(`${this.endPoint}/${courseSubjectActivityId}/test-answer-details`, data)
    }

    findProgressByCourse(courseId) {
        return this.client.get(`${this.endPoint}/${courseId}/progress-course`)
    }
}