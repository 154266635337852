<script>
import SimulatorStudentDetailQuestionComponent from '../features/SimulatorStudentDetailQuestionComponent';

export default {
  extends: SimulatorStudentDetailQuestionComponent,
  components: {},
  name: 'SimulatorStudentDetailQuestionView',
};
</script>
<template>
  <div class="simulator-question" v-loading="isLoading">
    <el-row type="flex" class="row-bg simulator-question-header" :gutter="20" >
      <el-col :span="24">
        <h4>Pregunta</h4>
        <div v-html="currentQuestion.value.description"></div>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg simulator-question-answers" :gutter="20">
      <el-col :span="24">
        <h4>Respuestas</h4>
          <el-checkbox-group v-model="answersSelected" @change="onChangeAnswer">
            <el-col
              :span="24"
              v-for="answer in currentQuestion.value.simulatorAnswers"
              :key="answer.id"
              class="choice"
            >
              <el-checkbox :label="answer.id" size="medium" align="center">
                <div v-html="answer.description"></div>
              </el-checkbox>
            </el-col>
          </el-checkbox-group>
      </el-col>
    </el-row>
  </div>
</template>