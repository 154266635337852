<script>
import ListSubscriptionsComponent from '../../features/lists/ListSubscriptionsComponent';
import ModalSubscriptionView from '../modals/ModalSubscriptionView';
import ModalImportView from '../../../shared/views/ModalImportView';

export default {
  extends: ListSubscriptionsComponent,
  name: 'ListSubscriptionView',
  components: {
    'modal-subscription-view': ModalSubscriptionView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl subscriptions">
      <el-col :xs="24" :sm="13" :md="13" :lg="8" :xl="7" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Inscripción</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Inscripciones</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
              <!--<el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.subscriptionPlanFilter" filterable remote clearable placeholder="Plan de Inscripción..."
                :remote-method="onSearchSubscriptionPlans" @change="onAllFilterChange(filterOptions.subscriptionPlanFilter.id, 'SubscriptionPlanId')" value-key="id">
                  <el-option v-for="item in subscriptionPlans" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.discountFilter" filterable remote clearable placeholder="Descuento..."
                :remote-method="onSearchDiscounts" @change="onAllFilterChange(filterOptions.discountFilter.id, 'DiscountId')" value-key="id">
                  <el-option v-for="item in discounts" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="block">
                  <span class="block-title">F. Inscripción:</span>
                  <el-date-picker
                    size="small"
                    v-model="filterOptions.subscriptionDate"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="F. Inicio"
                    end-placeholder="F. Fin"
                    value-format="yyyy-MM-dd"
                    format="dd MMM yyyy"
                    @change="onRangeDate(filterOptions.subscriptionDate, 'SubscriptionDate', 'SubscriptionEndDate')"
                  ></el-date-picker>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="block">
                  <span class="block-title">F. Conclusión:</span>
                  <el-date-picker
                    size="small"
                    v-model="filterOptions.closuseDate"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="F. Inicio"
                    end-placeholder="F. Fin"
                    value-format="yyyy-MM-dd"
                    format="dd MMM yyyy"
                    @change="onRangeDate(filterOptions.closuseDate, 'ClosuseDate', 'ClosuseEndDate')"
                  ></el-date-picker>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="3" :xl="3">
        <el-tooltip class="item" effect="dark" content="Buscar por folio." placement="top">
          <el-input size="small" placeholder="Buscar folio" icon="search" v-model='filterOptions.folioFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.folioFilter, 'Folio')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="7" :xl="6">
        <el-tooltip class="item" effect="dark" content="Buscar por estudiante." placement="top">
          <el-input size="small" placeholder="Buscar estudiante" icon="search" v-model='filterOptions.fullNameFilter'
          @keyup.enter.native="onAllFilterChange(filterOptions.fullNameFilter, 'FullName')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="4" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por Costo Total." placement="top">
          <el-input size="small" placeholder="Costo Total ($)" icon="search" v-model='filterOptions.searchAmount'
          @keyup.enter.native="onAllFilterChange(filterOptions.searchAmount, 'Amount')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table :data="collection" empty-text="No se encontraron resultados." v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
      <el-table-column label="" width="70">
        <template slot-scope="scope">
         <div>
            <el-avatar :src="mapProfileImage(scope.row.userProfile)"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ESTUDIANTE" min-width="220">
        <template slot-scope="scope">
         <div>
            {{ scope.row.userProfile.firstName }} {{ scope.row.userProfile.lastName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="FOLIO" :width="100" align="center">
        <template slot-scope="scope">
          <el-tag type="default" effect="plain"> {{scope.row.folio}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="PLAN" :width="230" align="center">
        <template slot-scope="scope">
          <el-tag type="default" effect="plain"> {{scope.row.subscriptionPlan.name}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="DESCUENTO" :width="230" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.discount">
            <el-tag type="default" effect="plain"> {{ scope.row.discount.name }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="COSTO TOTAL" :width="120" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.subscriptionPlan">
            <el-tag type="default" effect="plain"> {{formatMoney(scope.row.subscriptionPlan.amount)}}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="PAGO" :width="120" align="center">
        <template slot-scope="scope">
          <div> <el-tag type="default" effect="plain"><i class="el-icon-money"></i> {{formatMoney(scope.row.amount)}}</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column label="DESCUENTO %" :width="120" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.discount"> <el-tag type="default" effect="plain"> {{scope.row.discount.amount}} <i class="uil uil-tag-alt"></i></el-tag></div>
        </template>
      </el-table-column>
      <el-table-column prop="subscriptionDate" label="F. INICIO" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.subscriptionDate | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="subscriptionEndDate" label="F. CONCLUSIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.subscriptionEndDate)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.subscriptionEndDate | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <!--
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column
        label="OPCIONES"
        :width="110"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-subscription-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-subscription-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>