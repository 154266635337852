import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuStudentView from '../../../shared/views/MainMenuStudentView';
import MainFooterView from '../../../shared/views/MainFooterView';
import PermissionHelper from '../../../../core/shared/helpers/PermissionHelper';
import CourseDetailViewModel from '../../../../core/features/course/viewmodels/CourseDetailViewModel';
import ModalCoursePromotionalVideoView from '../../views/modals/ModalCoursePromotionalVideoView';
import ModalCoursePublicActivityView from '../../views/modals/ModalCoursePublicActivityView';

export default {
  extends: ItemComponent,
  components: {
    'header-view': HeaderView,
    'main-menu-student-view': MainMenuStudentView,
    'main-footer-view': MainFooterView,
    'modal-course-promotional-video-view': ModalCoursePromotionalVideoView,
    'modal-course-public-activity-view': ModalCoursePublicActivityView
  },
  data() {
    return {
      viewModel: null,
      courseDetails: null,
      courseSubjectActivityTypes: [],
      isFavorite: false,
      isLiked: false,
      totalLiked: 0,
      totalDisliked: 0,
      isDisliked: false,
      isViewed: false,
      totalViewed: 0,
      isSubscribed: false,
      modalItemCoursePromotionalVideo: {
        isVisible: false,
        isLoading: false
      },
      activityPreview: null,
      modalItemCoursePublicActivity: {
        isVisible: false,
        isLoading: false
      },
    };
  },
  created() {
    this.fullLoader = true;
    this.viewModel = new CourseDetailViewModel(this)
    this.onActive()
  },
  methods: {
    onCanShow(moduleName) {
      return PermissionHelper.canShow(moduleName);
    },
    onActive() {
      this.viewModel.onBindProfile()
      this.viewModel.onSearchDetail(this.$route.params.id)
    },
    onSearchDetailResponse(response) {
      this.courseDetails = response.data
    },
    onSearchCourseSubjecActivityTypesResponse(response) {
      this.courseSubjectActivityTypes = response.data;
    },
    getTotalActivities(collection) {
      return this.viewModel.getTotalActivities(collection);
    },
    getTotalDurationOfActivities(collection) {
      return this.viewModel.getTotalDurationOfActivities(collection);
    },
    showIconActivityType(activity) {
      if (this.isActivityLecture(activity)) return 'uil uil-file icon_142';
      if (this.isActivityVideo(activity)) return 'uil uil-play-circle icon_142';
      if (this.isActivityFile(activity)) return 'uil uil-file-download-alt icon_142';
      if (this.isActivityMultipleChoicesQuestion(activity)) return 'uil uil-file-check-alt icon_142';
      if (this.isActivityOpenQuestion(activity)) return 'uil uil-file-alt icon_142';
      if (this.isActivityMathQuestion(activity)) return 'uil uil-file-alt icon_142';
    },
    isActivityLecture(activity) {
      return this.viewModel.isValidActivityType(this.courseSubjectActivityTypes, activity.courseSubjectActivityType, CourseDetailViewModel.ACTIVITY_TYPE_LECTURE);
    },
    isActivityVideo(activity) {
      return this.viewModel.isValidActivityType(this.courseSubjectActivityTypes, activity.courseSubjectActivityType, CourseDetailViewModel.ACTIVITY_TYPE_VIDEO);
    },
    isActivityFile(activity) {
      return this.viewModel.isValidActivityType(this.courseSubjectActivityTypes, activity.courseSubjectActivityType, CourseDetailViewModel.ACTIVITY_TYPE_FILE);
    },
    isActivityMultipleChoicesQuestion(activity) {
      return this.viewModel.isValidActivityType(this.courseSubjectActivityTypes, activity.courseSubjectActivityType, CourseDetailViewModel.ACTIVITY_TYPE_MULTIPLE_CHOICES_QUESTION);
    },
    isActivityOpenQuestion(activity) {
      return this.viewModel.isValidActivityType(this.courseSubjectActivityTypes, activity.courseSubjectActivityType, CourseDetailViewModel.ACTIVITY_TYPE_OPEN_QUESTION);
    },
    isActivityMathQuestion(activity) {
      return this.viewModel.isValidActivityType(this.courseSubjectActivityTypes, activity.courseSubjectActivityType, CourseDetailViewModel.ACTIVITY_TYPE_MATH_QUESTION);
    },
    orderCourseSubjects(collection) {
      return _.sortBy(collection, 'sort');
    },
    orderCourseSubjectActivities(collection) {
      return _.sortBy(collection, 'sort');
    },
    getCoverImage(url) {
      return (url) ? url : '/images/courses/add_img.jpg'
    },
    getProfileImage(author) {
      return (author && author.profileImageUrl) ? author.profileImageUrl : '/images/left-imgs/img-1.jpg'
    },
    onShowPromotionalVideo() {
      this.viewModel.showModal(this.modalItemCoursePromotionalVideo, true);
    },
    hasPromotionalVideo() {
      return (this.courseDetails.promotionalVideoUrl)
    },
    onDoLike() {
      this.viewModel.doLike(this.courseDetails.id)
    },
    onDoDislike() {
      this.viewModel.doDislike(this.courseDetails.id)
    },
    onDoFavorite() {
      this.viewModel.doFavorite(this.courseDetails.id)
    },
    onDoFavoriteResponse(response) {
      this.isFavorite = response.isFavorite;
    },
    onDoLikeResponse(response) {
      this.isLiked = response.isLiked;
      this.totalLiked = response.totalLiked;
    },
    onDoDislikeResponse(response) {
      this.isDisliked = response.isDisliked;
      this.totalDisliked = response.totalDisliked;
    },
    onDoViewedResponse(response) {
      this.isViewed = response.isViewed;
      this.totalViewed = response.totalViewed;
    },
    getSocialStatusClass(flag) {
      return (flag) ? 'lkcm152 active' : 'lkcm152'
    },
    onShowPublicActivity(activity) {
      this.activityPreview = activity;
      this.viewModel.showModal(this.modalItemCoursePublicActivity, true);
    },
    mapTags(tags) {
      var collection = (tags) ? tags.split(',') : []
      return collection.join(' | ')
    },
    mapProfileImageUrl(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-2.jpg';
    },
    hasFacebookPage(instructor) {
      return !_.isNil(instructor.pageUrlFacebook) && !_.isEmpty(instructor.pageUrlFacebook);
    },
    hasInstagramPage(instructor) {
      return !_.isNil(instructor.pageUrlInstagram) && !_.isEmpty(instructor.pageUrlInstagram);
    },
    hasLinkedinPage(instructor) {
      return !_.isNil(instructor.pageUrlLinkedIn) && !_.isEmpty(instructor.pageUrlLinkedIn);
    },
    mapTotalCourses(instructor) {
      return (instructor.totalCourse === 1) ? `${instructor.totalCourse} Curso` : `${instructor.totalCourse} Cursos`;
    },
    onGoToCourse(course) {
      if (this.isSubscribed) {
        this.$router.push(`/estudiante/cursos/${course.id}/aprender`)
      } else {
        this.$alert('Para ver el contenido de nuestros cursos, necesitas una subscripción activa.', 'Información', {
          confirmButtonText: 'Aceptar',
          callback: action => {
            this.$router.push(`/estudiante/mi-cuenta`)
          },
          confirmButtonClass: 'btn-cancel'
        });
      }
    }
  },
};