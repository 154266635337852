export default class CourseSubjectTestQuestion {
    constructor(options) {
        Object.assign(this, options)
    }
    
    toSend(answerEditors) {
        return {
            id: this.id,
            description: this.description,
            sort: this.sort || 0,
            duration: this.duration || 0,
            updateState: this.updateState,
            courseSubjectTestQuestionAnswers: this.mapAnswers(answerEditors, this.courseSubjectTestQuestionAnswers)
        }
    }

    mapAnswers(answerEditors, answers) {
        let toDelete = _.filter(answers, (answer) => answer.updateState == 4 && answer.id)
        let toUpdate = _.filter(answers, (answer) => answer.updateState != 4)

        let mappedToUpdate = _.map(toUpdate, (answer, index) => {
            let answerEditor = _.find(answerEditors, {selector: `answer_editor_${index}`});
            answer.description = (answerEditor) ? answerEditor.component.root.innerHTML : '';
            return answer
        })

        return toDelete.concat(mappedToUpdate)
    }

    bindAnswers() {
        _.forEach(this.courseSubjectTestQuestionAnswers, (answer, index) => (answer.id) ? answer.updateState = 3 : answer.updateState = 2)
    }


    addAnswer() {
        this.courseSubjectTestQuestionAnswers.push({
            description: '',
            success: false,
            updateState: 2
        })
    }

    initDetaultAnswers() {
        this.courseSubjectTestQuestionAnswers = [
            { title: null, updateState: 2 },
            { title: null, updateState: 2 },
            { title: null, updateState: 2 },
            { title: null, updateState: 2 }
        ]
    }

    removeAllAnswers() {
        this.courseSubjectTestQuestionAnswers = []
    }


    removeAnswer(index) {
        this.getAnswers()[index].updateState = 4;
    }

    getAnswers() {
        return this.getFilteredAnswers(this.courseSubjectTestQuestionAnswers);
    }

    getFilteredAnswers(collection) {
        return _.filter(collection, (item) => item.updateState != 4)
    }
    
}