<script>
import ModalRoleComponent from '../../features/modals/ModalRoleComponent';
export default {
  extends: ModalRoleComponent,
  name: 'ModalRoleView',
};
</script>
<template>
<el-dialog title="Role" :visible.sync="conf.isVisible" width="70%" custom-class="role-modal" :before-close="onCancel" :close-on-click-modal="false">
  <el-form
  :model="formData"
  :rules="rules"
  ref="formData"
  status-icon
  :label-position="'top'"
  v-loading="isItemLoading"
  :element-loading-text="itemLoadingMessage"
  >
    <fieldset>
      <legend>Información de Rol</legend>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item label="Nombre" prop="name">
            <el-input v-model="formData.name" size="small" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </fieldset>
    <fieldset>
      <legend>Información de Permisos</legend>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-collapse accordion>
            <el-collapse-item :name="parentModule.name" v-for="(parentModule, indexModule) in modulesWithPermissions" v-bind:key="indexModule" style="background: #EBEEF5;">
              <template slot="title" >{{parentModule.name}}<i class="header-icon el-icon-information"></i></template>
              <el-row type="flex" class="row-bg" :gutter="20" style="background: #EBEEF5;font-size: 12px;font-weight: 600;padding: 5px 0px; margin-bottom:10px;">
                <el-col :span="10">
                  Permisos
                </el-col>
                <el-col :span="14" align="center">
                  <el-row type="flex" class="row-bg">
                    <el-col :span="4">Mostrar</el-col>
                    <el-col :span="4">Leer</el-col>
                    <el-col :span="4">Crear</el-col>
                    <el-col :span="4">Actualizar</el-col>
                    <el-col :span="4">Eliminar</el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row type="flex" class="row-bg" style="border-bottom: 1px solid #EBEEF5;" :gutter="20" v-for="(subModule, indexSubmodule) in parentModule.subModules" v-bind:key="indexSubmodule">
                <el-col :span="10">
                  {{subModule.name}}
                </el-col>
                <el-col :span="14" align="center">
                  <el-checkbox-group v-model="claims" >
                    <el-col :span="4" v-for="(permission) in subModule.permissions" :key="permission.code">
                      <el-checkbox :label="permission.code" size="small" align="center">
                        {{ "" }}
                      </el-checkbox>
                    </el-col>
                  </el-checkbox-group>
                </el-col>
              </el-row>
              <!-- <el-checkbox :indeterminate="sysModule.isIndeterminate" v-model="sysModule.checkAll" @change="handleCheckAllChange(sysModule)">Marcar todos</el-checkbox>
              
              <el-checkbox-group v-model="sysModule.permissionSelected" @change="handleCheckedCitiesChange(sysModule.permissionSelected, sysModule)">
                <el-col :lg="12" :xl="8" v-for="(permission, indexPermission) in sysModule.permissions" v-bind:key="indexPermission">
                  <el-checkbox :label="permission.name">{{permission.name}}</el-checkbox>
                </el-col>
              </el-checkbox-group>-->
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </fieldset>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="onCancel" size="small" class="btn-cancel">Cancelar</el-button>
    <el-button type="primary" @click="onSubmitForm('formData')" :disabled="!onCanSaveOrUpdate()" size="small" class="btn-primary">Guardar</el-button>
  </span>
</el-dialog>
</template>

