<script>
import AccountRegisterComponent from '../features/AccountRegisterComponent';

export default {
  name: 'AccountRegisterView',
  mixins: [AccountRegisterComponent],
  components: { },
};
</script>

<template>
    <!-- Signup Start -->
	<div class="sign_in_up_bg">
		<div class="container">
			<div class="row justify-content-lg-center justify-content-md-center">
				<div class="col-lg-12">
					<div class="main_logo25" id="logo">
						<a href="/"><img class="logo-login" src="images/logo.png" alt=""></a>
						<a href="/"><img class="logo-inverse" src="images/ct_logo.svg" alt=""></a>
					</div>
				</div>
			
				<div class="col-lg-6 col-md-8">
					<div class="sign_form">
						<h2>Bienvenidos a AppMisionate</h2>
						<p>Registrate y comienza a aprender.</p>
						<el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'left'" v-loading="isFormProcessing" :element-loading-text="formProcessingMessage">
							<div class="ui search focus mt-15">
								<div class="ui left icon input swdh11 swdh19">
									<el-form-item label="Nombres:" prop="firstName" >
										<el-input type="text" v-model="formData.firstName"></el-input>
									</el-form-item>
									<i class="uil uil-user icon icon2"></i>														
								</div>
							</div>
							<div class="ui search focus mt-15">
								<div class="ui left icon input swdh11 swdh19">
									<el-form-item label="Apellidos:" prop="lastName" >
										<el-input type="text" v-model="formData.lastName"></el-input>
									</el-form-item>
									<i class="uil uil-user icon icon2"></i>
								</div>
							</div>
							<div class="ui search focus mt-15">
								<div class="ui left icon input swdh11 swdh19">
									<el-form-item label="Correo:" prop="email">
										<el-input type="email" v-model="formData.email"></el-input>
									</el-form-item>
									<i class="uil uil-envelope icon icon2"></i>
								</div>
							</div>
							<div class="ui search focus mt-15">
								<div class="ui left icon input swdh11 swdh19">
									<el-form-item label="Contraseña:" prop="password">
										<el-input type="password" v-model="formData.password"></el-input>
									</el-form-item>
									<i class="uil uil-key-skeleton-alt icon icon2"></i>
								</div>
							</div>
							<!-- <div class="ui form mt-30 checkbox_sign">
								<div class="inline field">
									<div class="ui checkbox mncheck">
										<input type="checkbox" tabindex="0" class="hidden">
										<label>I’m in for emails with exciting discounts and personalized recommendations</label>
									</div>
								</div>
							</div>-->
							<button class="login-btn btn-primary" type="button" @click="onSignup('formData')">Registrarse</button>
						</el-form>
						<p class="sgntrm145">Registrandote, estas de acuerdo con nuestros <a class="link-primary" href="/terminos-y-condiciones">T&eacute;rminos de Uso</a> y <a class="link-primary" href="/terminos-y-condiciones">Pol&iacute;ticas de Privacidad</a>.</p>
						<p class="mb-0 mt-30">Ya tienes una cuenta? <router-link to="/login" class="link-primary">Ingresar</router-link></p>
					</div>
					<div class="sign_footer"><img src="/images/logo_no_text.png" alt="" class="logo_footer">© 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.</div>
				</div>				
			</div>				
		</div>				
	</div>
	<!-- Signup End -->	
</template>