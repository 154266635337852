<script>
import ListCoursesStudentComponent from '../../features/lists/ListCoursesStudentComponent';
import ModalCourseView from '../modals/ModalCourseView';
import ModalImportView from '../../../shared/views/ModalImportView';
import ModalStudentInfoView from '../../../account/views/ModalStudentInfoView';


export default {
  extends: ListCoursesStudentComponent,
  name: 'ListCourseStudentView',
  components: {
    'modal-course-category-view': ModalCourseView,
    'modal-import': ModalImportView,
    'modal-student-info-view': ModalStudentInfoView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl course-categories">
      <div class="col-xl-12 col-lg-8">
						<div class="section3125">
							<div class="explore_search">
								<div class="ui search focus">
									<div class="ui left icon input swdh11">
                    <el-input type="text" placeholder="Buscar curso por nombre." class="prompt srch_explore" 
                    v-model='searchName'
                    @keyup.enter.native="onAllFilterChange(searchName, 'Title')" suffix-icon="search"></el-input>
									</div>
								</div>
							</div>							
						</div>							
					</div>
    </el-row>
    <div class="row">
      <div class="col-lg-3 col-md-4" v-for="course in collection" :key="course.id">
        <div class="fcrse_1 mt-30">
              <router-link :to="'/estudiante/cursos/' + course.id +'/detalles'" class="fcrse_img">
              <img :src="getCoverImage(course.coverImageUrl)" alt="" />
              <div class="course-overlay">
                <!-- <div class="badge_seller">Más Visto</div>-->
                <!-- <div class="crse_reviews">
                  <i class="uil uil-star"></i>4.5
                </div> -->
                <span class="play_btn1"><i class="uil uil-play"></i></span>
                <div class="crse_timer">
                  {{course.totalHours}} Horas
                </div>
              </div>
            </router-link>
            <div class="fcrse_content">
              <div class="eps_dots more_dropdown">
                <a href="#"><i class="uil uil-ellipsis-v"></i></a>
                <div class="dropdown-content">
                  <!-- <span><i class="uil uil-share-alt"></i>Compartir</span> -->
                  <span @click="onDoLike(course)"><i :class="getSocialStatusClass(course.isLiked)"></i>Me Gusta</span>
                </div>
              </div>
              <div class="vdtodt">
                <span class="vdt14">{{course.totalViewed}} vista(s)</span>
                <span class="vdt14">{{course.lastView}}</span>
              </div>
              <router-link :to="'/estudiante/cursos/' + course.id +'/detalles'" class="crse14s">{{course.title}}</router-link>
              <span class="crse-cate">{{mapTags(course.tags)}}</span>
              <div class="auth1lnkprce">
                <p class="cr1fot" v-if="course.author">Por <a href="#">{{course.author}}</a></p>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-if="isCollectionEmpty(collection)"><h3>No se encontraron cursos</h3></div>
    <el-pagination
      v-else
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-course-category-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-course-category-view>
    <modal-student-info-view
    :formData="studentInfoItem"
    :conf="modalSubscriptionInfo"
    v-if="modalSubscriptionInfo.isVisible">
    </modal-student-info-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
