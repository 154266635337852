<script>
import ModalCourseSubjectTestComponent from '../../features/modals/ModalCourseSubjectTestComponent';
import ModalCourseSubjectTestQuestionView from './ModalCourseSubjectTestQuestionView';
import FileSectionView from '../details/FileSectionView';
import draggable from 'vuedraggable';

export default {
  extends: ModalCourseSubjectTestComponent,
  name: 'ModalCourseSubjectTestView',
  components: {
    'modal-test-question-view': ModalCourseSubjectTestQuestionView,
    'file-section-view': FileSectionView,
    draggable,
  },
};
</script>

<template>
  <div>
    <el-dialog
      title="Examen"
      :visible.sync="conf.isVisible"
      width="55%"
      custom-class="course-subject-test-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Información de Examen</legend>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
              <el-form-item label="Título" prop="title">
                <el-input
                  v-model="formData.title"
                  maxlength="120"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="Orden" prop="sort">
                <el-input-number v-model="formData.sort" size="small" :disabled="true"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" align="center">
              <el-form-item label="¿Es Obligatorio?">
                <el-switch
                  v-model="formData.isRequired"
                  active-color="#13ce66"
                  active-text="SI"
                  inactive-text="NO"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" align="center">
              <el-form-item label="¿Es Cronometrado?">
                <el-switch
                  v-model="formData.isTimer"
                  active-color="#13ce66"
                  active-text="SI"
                  inactive-text="NO"
                  @change="isTimerChange"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" align="center">
              <el-form-item label="Tipo de Cronometrado" prop="timerType">
              <el-select
                v-model="formData.timerType"
                clearable
                filterable
                placeholder="Seleccionar"
                value-key="id"
                size="small"
                :disabled="!formData.isTimer"
                @change="isTimerTypeChange"
              >
                <el-option
                  v-for="item in timerTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" align="center">
              <el-form-item label="Duración Total(Min.)" prop="duration">
                <el-input-number v-model="formData.duration" size="small" :disabled="!isTestTimeType"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-button
                type="primary"
                size="small"
                class="btn-primary"
                icon="el-icon-circle-plus"
                @click="onAddSection"
                >Agregar Secci&oacute;n</el-button
              >
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <br />
              <el-collapse accordion>
                <el-collapse-item :name="indexSection" v-for="(section, indexSection) in collectionCourseSubjectTestActivities(formData.courseSubjectTestActivities)" v-bind:key="indexSection" class="row-collapse">
                  <template slot="title">
                    <el-col :span="21">Secci&oacute;n {{ indexSection + 1 }}:</el-col>
                    <el-col :span="3" align="center"><el-button @click="onDeleteSection(indexSection)" type="button" size="small" class="btn-cancel"><i class="el-icon-delete" aria-hidden="true"></i></el-button></el-col>
                    <i class="header-icon el-icon-information"></i>
                  </template>

                  <el-row type="flex" class="row-bg" :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="Título Sección:" prop="description">
                        <el-input
                          v-model="section.description"
                          maxlength="120"
                          size="small"
                          :class="'description_editor_' + indexSection"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row type="flex" class="row-bg" :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="Instrucciones" prop="instruction">
                        <el-input
                          v-model="section.instruction"
                          maxlength="120"
                          size="small"
                          :class="'instruction_editor_' + indexSection"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    
                  </el-row>
                  
                  <el-row class="row-bg" :gutter="20">
                    <el-col :xs="24" :sm="8" :md="6" :lg="5" :xl="4" align="center">
                      <el-form-item label="Duración" prop="duration">
                        <el-input-number
                          v-model="section.duration"
                          size="small"
                          :disabled="!isSectionTimeType"
                        ></el-input-number>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="7" :lg="5" :xl="4" align="center">
                      <el-form-item prop="sectionFile" label="Archivo de Sección">
                        <file-section-view :section="section"></file-section-view>
                      </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="7" :lg="5" :xl="4" align="center">
                      <el-button
                      type="primary"
                      size="small"
                      class="btn-primary"
                      icon="el-icon-circle-plus"
                      style="margin-top:54px;"
                      @click="onAddQuestion(indexSection)"
                      >Agregar Pregunta</el-button
                    >
                    </el-col>
                  </el-row>                  
                  <div style="overflow-x:auto;">
                    <div class="divTable redTable">
                      <div class="divTableHeading">
                        <div class="divTableRow">
                          <div class="divTableHead" style="min-width:90px;">ORDEN</div>
                          <div class="divTableHead" style="min-width:300px;">PREGUNTA</div>
                          <div class="divTableHead center" style="min-width:90px;">OPCIONES</div>
                        </div>
                      </div>
                    </div>
                    <draggable v-model="section.courseSubjectTestQuestions" @change="changeDrag(section)" draggable=".item">
                      <div v-for="(question, indexQuestion) in sortCourseSubjectTestQuestions(section.courseSubjectTestQuestions)" :key="question.id" class="item">
                        <div class="divTable redTable">
                          <div class="divTableBody">
                            <div class="divTableRow">
                              <div class="divTableCell" style="min-width:90px;">
                                <p v-html="question.sort"></p>
                              </div>
                              <div class="divTableCell" style="min-width:300px;">
                                <p v-html="question.description"></p>
                              </div>
                              <div class="divTableCell center" style="min-width:90px;">
                                <el-dropdown @command="onChangeItemOptions">
                                  <el-button size="small">
                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                  </el-button>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="{ command: 'edit', item: question, sectionIndex: indexSection, index: indexQuestion }" :disabled=" !onCanReadCourseSubjectQuestion(modulePermissions)">
                                      <i class="el-icon-edit" aria-hidden="true"></i>Mostrar
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                      :command="{ command: 'delete', item: question, sectionIndex: indexSection, index: indexQuestion }"
                                      :disabled="
                                        !onCanDeleteCourseSubjectQuestion(modulePermissions)">
                                      <i class="el-icon-delete" aria-hidden="true"></i>
                                      Eliminar
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <!--
                  <el-row type="flex" class="row-bg row-header" :gutter="20">
                    <el-col :span="21">
                        PREGUNTA
                    </el-col>
                    <el-col :span="3" align="center">
                        OPCIONES
                    </el-col>
                  </el-row>
                  <el-row type="flex" class="row-bg row-content" :gutter="20" v-for="(question,
                        indexQuestion) in section.courseSubjectTestQuestions"
                        v-bind:key="indexQuestion">
                    <br>
                    <el-col :span="21" >
                        <p v-html="question.description"></p>
                    </el-col>
                    <el-col :span="3" align="center">
                        <el-dropdown @command="onChangeItemOptions">
                      <el-button size="small">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          :command="{ command: 'edit', item: question, sectionIndex: indexSection, index: indexQuestion }"
                          :disabled="
                            !onCanReadCourseSubjectQuestion(modulePermissions)
                          "
                        >
                          <i class="el-icon-edit" aria-hidden="true"></i>
                          Mostrar
                        </el-dropdown-item>
                        <el-dropdown-item
                          :command="{ command: 'delete', item: question, sectionIndex: indexSection, index: indexQuestion }"
                          :disabled="
                            !onCanDeleteCourseSubjectQuestion(modulePermissions)
                          "
                        >
                          <i class="el-icon-delete" aria-hidden="true"></i>
                          Eliminar
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    </el-col>
                  </el-row>
                  -->
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Aceptar</el-button
        >
      </span>
    </el-dialog>
    <modal-test-question-view
      :formData="itemQuestion"
      :conf="modalItemQuestion"
      v-if="modalItemQuestion.isVisible">
    </modal-test-question-view>
  </div>
</template>
