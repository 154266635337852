import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import PostViewModel from '../../../../core/features/posts/viewmodels/PostViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'PostContent-Posts',
            viewModel: null,
            postImageUrl: '/images/courses/add_img.jpg',
            postImageFile: null,
            isPostImageTemp: false,
            editorAbout: null,
            permissions: null,
            modules: [],
            rules: {
                code: [
                    { required: true, message: 'Código requerido.', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Nombre requerido.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new PostViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            setTimeout(() => {
                this.viewModel.onBindEditor('.description-editor');
                this.viewModel.bindFormView();
            }, 300);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.formData.description = this.editorAbout.root.innerHTML;
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedPost', null);
        },
        onSuccessHandlerPostPicture(data) {

        },
        async onBeforeUploadHandlerPostPicture(file) {
            let dataUrl = await this.viewModel.convertFileToBase64(file)
            this.postImageUrl = (dataUrl) ? dataUrl : '/images/left-imgs/img-1.jpg'
            this.postImageFile = file;
            this.isPostImageTemp = true;
        }
    }
}