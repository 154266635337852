import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import CourseLearnDetailViewModel from '../../../../core/features/course/viewmodels/CourseLearnDetailViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            viewModel: null,
            courseDetail: null,
            courseSubjectActive: null,
            courseSubjectActivityTypes: [],
            activityTypeSelected: null,
            activitySelected: null,
            activityAnswers: [],
            openAnswer: null,
            tabActive: 'general-info',
            progressActivities: [],
            doublyLinkedList: null,
            testDoublyLinkedList: null,
            currentCourseNode: null,
            openAnswerEditor: null,
            currentPdfViewer: null,
            contentLoader: false,
            testSectionSelected: null,
            currentQuestions: [],
            timerLabel: null,
            currentTimer: null,
            testTryNumber: null,
            testAnswers: [],
            testQuestionSelected: null,
            isDoingTest: false,
            showTestResult: false,
            isTestFinished: false,
            currentTestAnswersDetails: null,
            evaluationItem: null,
            modalEvaluation: {
                isLoading: false,
                isVisible: false
            }
        };
    },
    mounted() {
        this.$root.$on('onSubmitedEvaluation', this.onSubmitEvaluation)
    },
    beforeDestroy() {
        this.$root.$off('onSubmitedEvaluation', this.onSubmitEvaluation);
    },
    created() {
        this.viewModel = new CourseLearnDetailViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.viewModel.setCopyRights();
            this.viewModel.onBindFormView(this.$route.params.id);
            this.viewModel.bindActivityNavigation();
        },
        orderCourseSubjects(collection) {
            return _.sortBy(collection, 'sort');
        },
        orderCourseSubjectActivities(collection) {
            return _.sortBy(collection, 'sort');
        },
        getTotalActivities(collection) {
            return this.viewModel.getTotalActivities(collection);
        },
        getTotalDurationOfActivities(collection) {
            return this.viewModel.getTotalDurationOfActivities(collection);
        },
        showIconActivityType(activity) {
            return this.viewModel.getActivityTypeIcon(activity)
        },
        onSelectActivity(activity) {
            if (this.isDoingTest) {
                if (this.activitySelected.id != activity.id) {
                    this.showAlertQuitTest(activity);
                }
            } else {
                this.moveSelectedLecture(activity);
            }
        },
        onChangeAnswer(data) {
            
        },
        onDoActivityProgress(activityId, activityIndex) {
            this.viewModel.onDoProgress(activityId)
        },
        onDoProgressResponse(response) {
            this.viewModel.updateProgressCourse(this.courseDetail.id);
        },
        onPrevLecture() {
            if (this.isDoingTest) {
                this.showAlertQuitTest(null, false);
            } else {
                this.movePrevLecture();
            }
        },
        onNextLecture() {
            if (this.isDoingTest) {
                this.showAlertQuitTest(null, true);
            } else {
                this.moveNextLecture();
            }
        },
        moveNextLecture() {
            if (this.currentCourseNode.next) {
                this.contentLoader = true;
                setTimeout(() => {
                    this.currentCourseNode = this.viewModel.nextCourseNode(this.currentCourseNode)
                    this.updateCurrentActivity(this.currentCourseNode.value, true)
                }, 400);
            } else {
                this.showEvaluationModal();
            }
        },
        moveSelectedLecture(activity) {
            this.contentLoader = true;
            setTimeout(() => {
                this.updateCurrentActivity(activity, false);
            }, 400);
        },
        movePrevLecture() {
            if (this.currentCourseNode.prev) {
                this.contentLoader = true;
                setTimeout(() => {
                    this.currentCourseNode = this.viewModel.prevCourseNode(this.currentCourseNode)
                    this.updateCurrentActivity(this.currentCourseNode.value, true)
                }, 400);
            }
        },
        updateCurrentActivity(activity, isNode) {
            if(activity) {
                if (!isNode) {
                    this.currentCourseNode = this.viewModel.getNodeByActivityId(activity.id)
                }
    
                this.activityTypeSelected = activity.courseSubjectActivityType;
                this.activitySelected = activity;
    
                this.courseSubjectActive = this.viewModel.getSubjectIdByActivity(activity)
    
                this.$forceUpdate();
    
                $(".ql-toolbar").remove();
    
                switch (this.activityTypeSelected) {
                    case CourseLearnDetailViewModel.ACTIVITY_TYPE_LECTURE:
                        if (this.activitySelected.hasFile) {
                            this.onSelectFileType();
                        }
    
                        if (this.activitySelected.hasOpenResponse) {
                            this.onSelectOpenQuestion();
                        }
    
                        break;
                    case CourseLearnDetailViewModel.ACTIVITY_TYPE_TEST:
                        this.onSelectTestActivity();
                        break;
                    default:
                        break;
                }
    
            }
            
            setTimeout(() => {
                this.contentLoader = false;
            }, 400);
        },
        onSelectOpenQuestion() {
            setTimeout(() => {
                this.viewModel.bindEditor('.open-answer-editor');
            }, 5);
        },
        onSelectFileType() {
            this.currentPdfViewer = `/ViewerJS/#..${this.activitySelected.fileUrl}`
        },
        onSelectTestActivity() {
            this.viewModel.findTestTryNumber(this.activitySelected.id)
        },
        onFindTestTryNumberResponse(response) {
            this.testTryNumber = response.tryNumber;
            this.showTestResult = false;
            this.testSectionSelected = this.viewModel.getTestSectionDefault(this.activitySelected.courseSubjectTestActivities);
            if (this.testSectionSelected) {
                _.forEach(this.testSectionSelected.value.courseSubjectTestQuestions, (question) => {
                    question.answers = [];
                })
            }
            if (this.activitySelected.isTimer) {
                this.$confirm(`Este examen esta temporizado a ${this.activitySelected.totalHours} Horas. ¿Deseas Comenzar?`, 'Alerta', {
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    type: 'warning',
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    confirmButtonClass: 'btn-primary',
                    cancelButtonClass: 'btn-cancel',
                }).then(() => {
                    this.isDoingTest = true;
                    this.currentTimer = this.viewModel.initTimer({
                        startValues: { minutes: this.activitySelected.duration },
                        onStart: this.onTimerStart,
                        onUpdate: this.onTimerUpdate,
                        onFinished: this.onTimerFinished
                    });
                }).catch((error) => { });
            } else {
                this.$confirm(`¿Deseas comenzar el examen?`, 'Alerta', {
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    type: 'warning',
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    confirmButtonClass: 'btn-primary',
                    cancelButtonClass: 'btn-cancel',
                }).then(() => {
                    this.isDoingTest = true;
                }).catch((error) => { });
            }
        },
        onSendMultipleAnswer() {
            var data = {
                activityType: CourseLearnDetailViewModel.ACTIVITY_TYPE_MULTIPLE_CHOICES_QUESTION,
                courseSubjectActivityId: this.activitySelected.id,
                multipleAnswers: this.activityAnswers,
                success: this.viewModel.verifyAnswers(this.activityAnswers, this.activitySelected.courseSubjectActivityAnswers),
            };
            this.viewModel.onDoMultipleAnswer(this.activitySelected.id, data)
        },
        onSendOpenAnswer() {
            var data = {
                activityType: CourseLearnDetailViewModel.ACTIVITY_TYPE_OPEN_QUESTION,
                courseSubjectActivityId: this.activitySelected.id,
                openAnswer: this.openAnswerEditor.root.innerHTML,
            };
            this.viewModel.onDoOpenAnswer(this.activitySelected.id, data)
        },
        onDoOpenAnswerResponse(response) {
            this.$confirm(response.message, 'Información', {
                confirmButtonText: 'Aceptar',
                type: 'info',
                center: false,
                showCancelButton: false,
                confirmButtonClass: 'btn-primary'
            })
            .then(() => {
                this.onNextLecture()
            });
        },
        onDoMultipleAnswerResponse(response) {
            if (response.answerSuccess) {
                this.$confirm(response.message, 'Información', {
                    confirmButtonText: 'Aceptar',
                    type: 'success',
                    center: false,
                    showCancelButton: false,
                    confirmButtonClass: 'btn-primary',
                    dangerouslyUseHTMLString: true
                })
                .then(() => {
                    this.activityAnswers = []
                    this.onNextLecture()
                });
            } else {
                this.$confirm(this.viewModel.mapMultipleChoiceAnswer(response.message, this.activitySelected.courseSubjectActivityAnswers), 'Información', {
                    confirmButtonText: 'Aceptar',
                    type: 'error',
                    center: false,
                    showCancelButton: false,
                    confirmButtonClass: 'btn-primary',
                    dangerouslyUseHTMLString: true
                })
                .then(() => {
                    this.activityAnswers = []
                    this.onNextLecture()
                });
            }
        },
        getActivityStatus(activity) {
            return (this.activitySelected && this.activitySelected.id === activity.id) ? 'el-row course-row-lecture selected' : 'el-row course-row-lecture';
        },
        onBindFileContextMenu(evt) {
            setTimeout(() => {
                if (!this.activitySelected.isDownload) {
                    $('#file-wrapper-viewer').contents().find('#download').css("display", "none");
                }
                $('#file-wrapper-viewer').contents().find('#documentName').hide();
                $('#file-wrapper-viewer').contents().find('#about').hide();
            }, 400);
        },
        onFinishCourse() {
            this.$confirm('¿Deseas finalizar el curso?', 'Alerta', {
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                type: 'info',
                confirmButtonClass: 'btn-primary',
                cancelButtonClass: 'btn-cancel',
            })
                .then(() => {
                    this.viewModel.onDoFinish(this.courseDetail.id)
                })
                .catch(() => {

                });
        },
        onDoFinishResponse(response) {
            this.$router.go(-1);
        },
        onPrevTestSection() {
            if (this.testSectionSelected.prev) {

                this.contentLoader = true;

                setTimeout(() => {
                    this.testSectionSelected = this.viewModel.prevCourseNode(this.testSectionSelected)
                }, 400);

                setTimeout(() => {
                    this.contentLoader = false;
                }, 400);
            }
        },
        onNextTestSection() {
            this.onSendTestAnswer();
        },
        onStopTimer() {
            this.currentTimer.stop();
            this.timerLabel = null;
        },
        updateCurrentTestSection() {
            this.contentLoader = true;
            setTimeout(() => {
                if (this.testSectionSelected.next) {
                    
                    this.testSectionSelected = this.viewModel.nextCourseNode(this.testSectionSelected)
                    
                    if (this.testSectionSelected) {
                        _.forEach(this.testSectionSelected.value.courseSubjectTestQuestions, (question) => {
                            question.answers = [];
                        })
                    }
                    setTimeout(() => {
                        this.contentLoader = false;
                    }, 400);
                } else {
                    this.viewModel.findTestAnswersDetails({ courseSubjectActivityId: this.activitySelected.id, tryNumber: this.testTryNumber })
                }
            }, 300);
        },
        getPdfViewer(item) {
            return `/ViewerJS/#..${item.fileUrl}`;
        },
        onTimerStart(data) {
            this.timerLabel = data;
        },
        onTimerUpdate(data) {
            this.timerLabel = data;
        },
        onTimerFinished() {
            this.viewModel.findTestAnswersDetails({ courseSubjectActivityId: this.activitySelected.id, tryNumber: this.testTryNumber })
        },
        onFinishTest(response) {
            if (this.currentTimer) this.onStopTimer();
            this.currentTestAnswersDetails = response.data;
            setTimeout(() => {
                this.isDoingTest = false;
                this.showTestResult = true;
                this.contentLoader = false;
            }, 400);
        },
        onChangeTestAnswer(data) {
            this.$forceUpdate();
        },
        onSendTestAnswer() {
            let requests = []
            _.forEach(this.testSectionSelected.value.courseSubjectTestQuestions, (question) => {
                if (!_.isEmpty(question.answers)) {
                    let data = {
                        tryNumber: this.testTryNumber,
                        multipleAnswers: question.answers,
                        success: this.viewModel.verifyAnswers(question.answers, question.courseSubjectTestQuestionAnswers),
                        courseSubjectTestQuestionId: question.id
                    };
                    requests.push(this.viewModel.onDoTestAnswer(this.activitySelected.id, data))
                }
            });

            if (_.size(this.testSectionSelected.value.courseSubjectTestQuestions) !== _.size(requests)) {
                this.$confirm(`Revisa tus respuestas, puede que falte alguna.`, 'Alerta', {
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'No',
                    type: 'warning',
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    confirmButtonClass: 'btn-primary',
                    cancelButtonClass: 'btn-cancel',
                    showCancelButton: false,
                }).then(() => {

                }).catch((error) => {

                });
            } else {
                this.viewModel.onDoTestAnswersRequests(requests)
            }
        },
        onDoTestAnswerResponse(response) {
            this.updateCurrentTestSection()
        },
        doScore(question) {
            return this.viewModel.scoreQuestion(question, this.currentTestAnswersDetails)
        },
        isAnswerResponse(answer) {
            return this.viewModel.verifyAnswerResponse(answer.id, this.currentTestAnswersDetails)
        },
        getStyleAnswerResponse(answer, question) {
            if (this.isAnswerResponse(answer) && answer.success && this.doScore(question)) return 'test-result success';
            if (this.isAnswerResponse(answer) && !answer.success && !this.doScore(question)) return 'test-result fail';
            if (this.isAnswerResponse(answer) && answer.success && !this.doScore(question)) return 'test-result fail';
            return 'test-result';
        },
        getTestScore() {
            return this.viewModel.scoreTest(this.currentTestAnswersDetails, this.activitySelected.courseSubjectTestActivities);
        },
        getTestTotalSuccess() {
            return this.viewModel.getTotalSuccess(this.currentTestAnswersDetails, this.activitySelected.courseSubjectTestActivities);
        },
        isScoreTestSuccess() {
            let scoreTest = this.viewModel.scoreTest(this.currentTestAnswersDetails, this.activitySelected.courseSubjectTestActivities);
            return this.viewModel.isScoreSuccess(scoreTest);
        },
        showAlertQuitTest(activity, isNext) {
            this.$confirm(`¿Deseas salir el examén actual?`, 'Alerta', {
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                type: 'warning',
                closeOnPressEscape: false,
                closeOnClickModal: false,
                confirmButtonClass: 'btn-primary',
                cancelButtonClass: 'btn-cancel',
            }).then(() => {
                this.isDoingTest = false;
                this.showTestResult = false;
                if (this.currentTimer) this.onStopTimer();
                if (activity) {
                    this.moveSelectedLecture(activity);
                } else if (isNext) {
                    this.moveNextLecture();
                } else {
                    this.movePrevLecture();
                }
            }).catch((error) => {

            });
        },
        showEvaluationModal() {
            this.evaluationItem = this.viewModel.onCreateEvaluation();
            this.viewModel.showModal(this.modalEvaluation, true);
        },
        onSubmitEvaluation(data) {
            this.viewModel.sendCourseEvaluation({
                observation: data.observation,
                qualification: data.qualification,
                courseId: this.courseDetail.id
            });
            this.evaluationItem = null;
            this.viewModel.showModal(this.modalEvaluation, false);
        },
        onSubmitEvaluationResponse(response) {
            this.onFinishCourse();
        },
        hasQuestions(questions) {
            return !_.isEmpty(questions)
        },
        filterQuestionsByAnswers(questions) {
            return _.filter(questions, (item) => {
                return !_.isEmpty(item.courseSubjectTestQuestionAnswers)
            })
        },
        isCollection(item) {
            return _.isArray(item)
        }
    },
};