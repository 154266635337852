<script>
import CourseDetailComponent from '../../features/details/CourseDetailComponent';

export default {
  extends: CourseDetailComponent,
  name: 'CourseDetailView',
};
</script>

<template>
  <div>
    <header-view></header-view>
    <main-menu-student-view></main-menu-student-view>
    <!-- Body Start -->
    <div class="wrapper _bg4586">
      <div class="_215b01">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="section3125">
                <div class="row justify-content-center">
                  <div class="col-xl-4 col-lg-5 col-md-6">
                    <div class="preview_video" v-if="courseDetails">
                      <a
                        href="#"
                        class="fcrse_img"
                        data-toggle="modal"
                        data-target="#videoModal"
                      >
                        <img
                          :src="getCoverImage(courseDetails.coverImageUrl)"
                          alt=""
                        />
                        <!-- <iframe v-if="courseDetails.promotionalVideoUrl" :src="courseDetails.promotionalVideoUrl" 
                        width="1000" height="250" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="margin-left:-280px;"></iframe> -->
                        <div
                          v-if="hasPromotionalVideo()"
                          class="course-overlay"
                          @click="onShowPromotionalVideo"
                        >
                          <!-- <div class="badge_seller">Bestseller</div> -->
                          <span class="play_btn1"
                            ><i class="uil uil-play"></i
                          ></span>
                          <span class="_215b02">Ver Video</span>
                        </div>
                        <div
                          v-else
                          class="course-overlay"
                        >
                          <!-- <div class="badge_seller">Bestseller</div> -->
                          <!-- <span class="play_btn1"
                            ><i class="uil uil-play"></i
                          ></span> -->
                        </div>
                      </a>
                    </div>
                    <div class="_215b10">
                      <span class="_215b11" @click="onDoFavorite">
                        <span v-if="!isFavorite"
                          ><i class="uil uil-heart"></i
                        ></span>
                        <span v-if="isFavorite"
                          ><i class="uil uil-heart active"></i
                        ></span>
                        Favorito
                      </span>
                      <!-- <a href="#" class="_215b12">
                        <span><i class="uil uil-windsock"></i></span>Report
                        abuse
                      </a> -->
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-7 col-md-6" v-if="courseDetails">
                    <div class="_215b03">
                      <h2>{{ courseDetails.title }}</h2>
                      <span class="_215b04">{{
                        courseDetails.description
                      }}</span>
                    </div>
                    <!-- <div class="_215b05">
                      <div class="crse_reviews mr-2">
                        <i class="uil uil-star"></i>4.5
                      </div>
                      (81,665 ratings)
                    </div> -->
                    <div class="_215b05">
                      {{ courseDetails.totalEnrolled }} Estudiante(s) en el
                      curso
                    </div>

                    <div class="_215b05">
                      {{ mapTags(courseDetails.tags) }}
                    </div>

                    <div class="_215b05" v-if="courseDetails.lastUpdate">
                      Ultima actualizaci&oacute;n {{ courseDetails.lastUpdate }}
                    </div>
                    <ul class="_215b31">
                      <li>
                        <button class="btn_adcart btn-primary" @click="onGoToCourse(courseDetails)">Ir al Curso</button>
                      </li>
                      <!-- <li><button class="btn_buy">Buy Now</button></li> -->
                    </ul>
                    <!-- <div class="_215fgt1">
                      30-Day Money-Back Guarantee
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215b15 _byt1458">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="user_dt5">
                <div
                  class="user_dt_left"
                  v-if="courseDetails && courseDetails.author"
                >
                  <div class="live_user_dt">
                    <div class="user_img5">
                      <img
                        :src="getProfileImage(courseDetails.author)"
                        alt=""
                      />
                    </div>
                    <div class="user_cntnt">
                      <a href="#" class="_df7852"
                        >{{ courseDetails.author.firstName }}
                        {{ courseDetails.author.lastName }}</a
                      >
                      <span class="subscribe-btn btn-primary btn-author"> Autor </span>
                      <!-- <el-button slot="reference" class="subscribe-btn btn-primary">Ver Perfil</el-button> -->
                    </div>
                  </div>
                </div>
                <div class="user_dt_right">
                  <ul>
                    <li>
                      <div :class="getSocialStatusClass(isViewed)">
                        <i class="uil uil-eye"></i><span>{{totalViewed}}</span>
                      </div>
                    </li>
                    <li @click="onDoLike">
                      <span :class="getSocialStatusClass(isLiked)"
                        ><i class="uil uil-thumbs-up"></i
                        ><span>{{ totalLiked }}</span></span
                      >
                    </li>
                    <li @click="onDoDislike">
                      <span :class="getSocialStatusClass(isDisliked)"
                        ><i class="uil uil-thumbs-down"></i
                        ><span>{{ totalDisliked }}</span></span
                      >
                    </li>
                    <!-- <li>
                      <a href="#" class="lkcm152"
                        ><i class="uil uil-share-alt"></i><span>9</span></a
                      >
                    </li> -->
                  </ul>
                </div>
              </div>
              
              <div class="course_tabs">
                <nav>
                  <div
                    class="nav nav-tabs tab_crse justify-content-center"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class="nav-item nav-link active"
                      id="nav-about-tab"
                      data-toggle="tab"
                      href="#nav-about"
                      role="tab"
                      aria-selected="true"
                      >Acerca del Curso</a
                    >
                    <a
                      class="nav-item nav-link"
                      id="nav-courses-tab"
                      data-toggle="tab"
                      href="#nav-courses"
                      role="tab"
                      aria-selected="false"
                      >Contenido de Curso</a
                    >
                    <a
                      class="nav-item nav-link"
                      id="nav-instructors-tab"
                      data-toggle="tab"
                      href="#nav-instructors"
                      role="tab"
                      aria-selected="false"
                      >Profesores</a
                    >
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215b17">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tab_content">
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-about" role="tabpanel">
                    <div class="container" v-if="courseDetails">
                      <div class="crse_content">
                        <h3>Acerca del Curso</h3>
                      </div>
                      <span v-html="courseDetails.about"></span>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-courses" role="tabpanel" v-if="courseDetails">
                    <div class="crse_content">
                      <h3>Contenido de Curso</h3>
                      <div class="_112456">
                        <ul class="accordion-expand-holder">
                          <li>
                            <span class="accordion-expand-all _d1452"
                              >Expandir Todo</span
                            >
                          </li>
                          <li>
                            <span class="_fgr123">
                              {{
                                courseDetails.totalActivities
                              }}
                              Actividades</span
                            >
                          </li>
                          <li>
                            <span class="_fgr123"
                              >{{ courseDetails.totalHours }}
                              <small>(Hrs)</small></span
                            >
                          </li>
                        </ul>
                      </div>
                      <div
                        id="accordion"
                        class="ui-accordion ui-widget ui-helper-reset"
                      >
                        <div
                          v-for="(subject, subjectIndex) in orderCourseSubjects(
                            courseDetails.courseSubjects
                          )"
                          v-bind:key="subjectIndex"
                        >
                          <a
                            href="javascript:void(0)"
                            class="accordion-header ui-accordion-header ui-helper-reset ui-state-default ui-accordion-icons ui-corner-all"
                          >
                            <div class="section-header-left">
                              <span class="section-title-wrapper">
                                <i
                                  class="uil uil-presentation-play crse_icon"
                                ></i>
                                <span class="section-title-text">{{
                                  subject.title
                                }}</span>
                              </span>
                            </div>
                            <div class="section-header-right">
                              <span class="num-items-in-section">{{
                                subject.totalActivities
                              }}</span>
                              <span class="section-header-length"
                                >{{ subject.totalHours }}
                                <small>(Hrs)</small></span
                              >
                            </div>
                          </a>
                          <div
                            class="ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom"
                          >
                            <div
                              class="lecture-container"
                              v-for="(activity,
                              activityIndex) in orderCourseSubjectActivities(
                                subject.courseSubjectActivities
                              )"
                              v-bind:key="activityIndex"
                            >
                              <div class="left-content">
                                <i :class="showIconActivityType(activity)"></i>
                                <div class="top">
                                  <div class="title">
                                    {{ activity.title }}
                                  </div>
                                </div>
                              </div>
                              <div class="details">
                                <el-button
                                  type="text"
                                  @click="onShowPublicActivity(activity)"
                                  class="preview-text"
                                  v-if="activity.isPublic"
                                  >Ver</el-button
                                >
                                <span class="content-summary">{{
                                  activity.totalHours
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Finalidazión de Collapse-->
                      <!-- <a class="btn1458" href="#">20 More Sections</a> -->
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-instructors" role="tabpanel" v-if="courseDetails">
                    <div class="container">
                      <div class="crse_content">
                        <h3>Profesores</h3>
                      </div>
                      <el-row>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" class="block" v-for="instructor in courseDetails.instructors" :key="instructor.id">
                          <div style="margin:5px;">
                            <div class="fcrse_1 mt-30 course-detail-instructor">
                              <div class="tutor_img">
                                <img :src="mapProfileImageUrl(instructor.userProfile)" alt="">
                              </div>
                              <div class="tutor_content_dt">
                                <!--
                                <div class="tutor150">
                                  <a href="instructor_profile_view.html" class="tutor_name">{{instructor.userProfile.firstName}} {{instructor.userProfile.lastName}}</a>
                                  <div class="mef78" title="Verify">
                                    <i class="uil uil-check-circle"></i>
                                  </div>
                                </div> -->
                                <div class="tutor150">
                                  <span class="tutor_name">{{instructor.userProfile.firstName}}</span>
                                </div>
                                <div class="tutor150">
                                  <span class="tutor_name">{{instructor.userProfile.lastName}}</span>
                                </div>
                                <div class="mef78" title="Verify">
                                  <i class="uil uil-check-circle"></i>
                                </div>
                                <el-popover
                                  width="400"
                                  placement="right"
                                  trigger="click">
                                  <div>
                                    <h4>Acerca</h4>
                                    <p>{{instructor.about}}</p>
                                  </div>
                                  <br>
                                  <div>
                                    <h4>Especialidad</h4>
                                    <p>{{instructor.educationSpecialty}}</p>
                                  </div>
                                  <br>
                                  <div>
                                    <h4>Redes Sociales</h4>
                                    <br>
                                    <ul class="tutor_social_links">
                                    <li>
                                      <a v-if="hasFacebookPage(instructor)" :href="instructor.pageUrlFacebook" class="fb" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                      <a v-if="hasInstagramPage(instructor)" :href="instructor.pageUrlInstagram" class="in" target="_blank">
                                        <i class="fab fa-instagram"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a v-if="hasLinkedinPage(instructor)" :href="instructor.pageUrlLinkedIn" class="ln" target="_blank">
                                        <i class="fab fa-linkedin-in"></i>
                                      </a>
                                    </li>
                                  </ul>
                                  </div>
                                  <el-button slot="reference" type="text">Ver m&aacute;s...</el-button>
                                </el-popover>
                              </div>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main-footer-view></main-footer-view>
      <modal-course-promotional-video-view
        :conf="modalItemCoursePromotionalVideo"
        :formData="courseDetails"
        v-if="modalItemCoursePromotionalVideo.isVisible"
      >
      </modal-course-promotional-video-view>

      <modal-course-public-activity-view
        :conf="modalItemCoursePublicActivity"
        :formData="activityPreview"
        v-if="modalItemCoursePublicActivity.isVisible"
      >
      </modal-course-public-activity-view>
    </div>
    <div v-loading.fullscreen.lock="fullLoader"></div>
    <!-- Body End -->
  </div>
</template>
