import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent'
import LiveClassViewModel from '../../../../core/features/liveclass/viewmodels/LiveClassViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'LiveClassContent-LiveClass',
            viewModel: null,
            permissions: null,
            modules: [],
            courseFilter: '',
            courses: [],
            rules: {
                streamUrl: [
                    { required: true, message: 'Url Stream requerido.', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: 'Titulo requerido.', trigger: 'blur' }
                ],
                course: [
                    { required: true, message: "Selecciona un Curso", trigger: "change" }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new LiveClassViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onSearchCourse(data) {
            if (data) {
                this.viewModel.findCourses(`Title=${data}`)
            } else {
                this.courses = []
            }
        },
        onSearchCoursesResponse(response) {
            this.courses = response;
          },
        onFocusCourseCategory() {
            this.$forceUpdate()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedLiveClass', null);
        }
    }
}