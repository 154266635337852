import EndPoints from './EndPoints';
import ApiWrapper from './ApiWrapper';

// API SYSTEM
import ApiCourseCategories from "../features/setting/apis/ApiCourseCategories";
import ApiAdvisoryCities from "../features/setting/apis/ApiAdvisoryCities";
import ApiCareers from "../features/setting/apis/ApiCareers";
import ApiUniversities from "../features/setting/apis/ApiUniversities";
import ApiHighSchools from "../features/setting/apis/ApiHighSchools";
import ApiCourseSubjectActivityTypes from "../features/setting/apis/ApiCourseSubjectActivityTypes";
import ApiUserStatuses from "../features/setting/apis/ApiUserStatuses";
import ApiFinishedStatuses from "../features/setting/apis/ApiFinishedStatuses";
import ApiPublishedStatuses from "../features/setting/apis/ApiPublishedStatuses";
import ApiTestTimerTypes from '../features/setting/apis/ApiTestTimerTypes';
import ApiSimulatorQuestions from '../features/setting/apis/ApiSimulatorQuestions';
import ApiSimulatorQuestionCategories from '../features/setting/apis/ApiSimulatorQuestionCategories';
import ApiSimulatorExamnTypes from "../features/setting/apis/ApiSimulatorExamnTypes";
import ApiDifficultyLevels from "../features/setting/apis/ApiDifficultyLevels";

// API AUTHENTICATION & AUTHORIZATION
import ApiAuthentication from "../features/authentication/apis/ApiAuthentication";
import ApiUsers from "../features/authorization/apis/ApiUsers";
import ApiRoles from "../features/authorization/apis/ApiRoles";
import ApiModules from "../features/system/apis/ApiModules";
import ApiPermissions from "../features/system/apis/ApiPermissions";

// API COURSE
import ApiCourses from "../features/course/apis/ApiCourses";

// API DISCOUNT
import ApiDiscounts from "../features/discount/apis/ApiDiscounts";

//API STUDENTS
import ApiStudents from '../features/students/apis/ApiStudents';

//API INSTRUCTORS
import ApiInstructors from '../features/instructors/apis/ApiInstructors';

//API SUBSCRIPTIONPLAN
import ApiSubscriptionPlans from '../features/subscriptionplan/apis/ApiSubscriptionPlans';

//API SUBSCRIPTION
import ApiSubscriptions from '../features/subscription/apis/ApiSubscriptions';

//API STATEMENTS
import ApiStatements from '../features/statements/apis/ApiStatements';

//API LIVE CLASS
import ApiLiveClasses from '../features/liveclass/apis/ApiLiveClasses';
import ApiLiveChat from '../features/liveclass/apis/ApiLiveChat';

// API NOTIFICATIONS
import ApiNotifications from '../features/notifications/apis/ApiNotifications';

// API RESPONSE ACTIVITY ANSWERS
import ApiResponseActivityAnswers from '../features/responseactivityanswer/apis/ApiResponseActivityAnswers';

// API EVALUATION
import ApiEvaluations from "../features/evaluations/apis/ApiEvaluations";

// API POSTS 
import ApiPosts from '../features/posts/apis/ApiPosts';

// API SIMULATORS 
import ApiSimulators from '../features/simulator/apis/ApiSimulators';

// API WEBSITE POSTS
import ApiBlogPages from '../features/website/apis/ApiPosts';

// API DASHBOARDS
import ApiDashboardStudent from '../features/home/apis/ApiDashboardStudent';
import ApiDashboardInstructor from '../features/home/apis/ApiDashboardInstructor';
import ApiDashboard from '../features/home/apis/ApiDashboard';

const apiWrapper = new ApiWrapper();

export default {
    //SYSTEM
    courseCategories: new ApiCourseCategories(apiWrapper, `${EndPoints.API}${EndPoints.COURSE_CATEGORIES}`),
    advisoryCities: new ApiAdvisoryCities(apiWrapper, `${EndPoints.API}${EndPoints.ADVISORY_CITIES}`),
    careers: new ApiCareers(apiWrapper, `${EndPoints.API}${EndPoints.CAREERS}`),
    universities: new ApiUniversities(apiWrapper, `${EndPoints.API}${EndPoints.UNIVERSITIES}`),
    highSchools: new ApiHighSchools(apiWrapper, `${EndPoints.API}${EndPoints.HIGH_SCHOOLS}`),
    modules: new ApiModules(apiWrapper, `${EndPoints.API}${EndPoints.MODULES}`),
    permissions: new ApiPermissions(apiWrapper, `${EndPoints.API}${EndPoints.PERMISSIONS}`),
    courseSubjectActivityTypes: new ApiCourseSubjectActivityTypes(apiWrapper, `${EndPoints.API}${EndPoints.COURSE_SUBJECT_ACTIVITY_TYPES}`),
    userStatuses: new ApiUserStatuses(apiWrapper, `${EndPoints.API}${EndPoints.USER_STATUSES}`),
    finishedStatuses: new ApiFinishedStatuses(apiWrapper, `${EndPoints.API}${EndPoints.FINISHED_STATUSES}`),
    publishedStatuses: new ApiPublishedStatuses(apiWrapper, `${EndPoints.API}${EndPoints.PUBLISHED_STATUSES}`),
    testTimerTypes: new ApiTestTimerTypes(apiWrapper, `${EndPoints.API}${EndPoints.TEST_TIMER_TYPES}`),
    simulatorQuestions: new ApiSimulatorQuestions(apiWrapper, `${EndPoints.API}${EndPoints.SIMULATOR_QUESTIONS}`),
    simulatorQuestionCategories: new ApiSimulatorQuestionCategories(apiWrapper, `${EndPoints.API}${EndPoints.SIMULATOR_QUESTION_CATEGORIES}`),
    simulatorExamnTypes: new ApiSimulatorExamnTypes(apiWrapper, `${EndPoints.API}${EndPoints.SIMULATOR_EXAM_TYPES}`),
    difficultyLevels: new ApiDifficultyLevels(apiWrapper, `${EndPoints.API}${EndPoints.DIFFICULTY_LEVELS}`),
    
    // AUTHENTICATION
    authentication: new ApiAuthentication(apiWrapper, `${EndPoints.API}${EndPoints.AUTH}`),
    users: new ApiUsers(apiWrapper, `${EndPoints.API}${EndPoints.USERS}`),
    roles: new ApiRoles(apiWrapper, `${EndPoints.API}${EndPoints.ROLES}`),

    //COURSE
    courses: new ApiCourses(apiWrapper, `${EndPoints.API}${EndPoints.COURSES}`),

    //RESPONSE ACTIVITY ANSWERS
    responseActivityAnswers: new ApiResponseActivityAnswers(apiWrapper, `${EndPoints.API}${EndPoints.RESPONSE_ACTIVITY_ANSWERS}`),

    //DISCOUNT
    discounts: new ApiDiscounts(apiWrapper, `${EndPoints.API}${EndPoints.DISCOUNTS}`),

    //STUDENTS
    students: new ApiStudents(apiWrapper, `${EndPoints.API}${EndPoints.STUDENTS}`),

    //INSTRUCTORS
    instructors: new ApiInstructors(apiWrapper, `${EndPoints.API}${EndPoints.INSTRUCTORS}`),

    //SUBSCRIPTIONPLAN
    subscriptionPlans: new ApiSubscriptionPlans(apiWrapper, `${EndPoints.API}${EndPoints.SUBSCRIPTION_PLANS}`),

    //SUBSCRIPTION
    subscriptions: new ApiSubscriptions(apiWrapper, `${EndPoints.API}${EndPoints.SUBSCRIPTIONS}`),

    //STATEMENTS
    statements: new ApiStatements(apiWrapper, `${EndPoints.API}${EndPoints.STATEMENTS}`),

    //LIVECLASS
    liveclass: new ApiLiveClasses(apiWrapper, `${EndPoints.API}${EndPoints.LIVE_CLASSES}`),
    liveChat: new ApiLiveChat(apiWrapper, `${EndPoints.API}${EndPoints.LIVE_CHAT}`),

    //NOTIFICATIONS
    notifications: new ApiNotifications(apiWrapper, `${EndPoints.API}${EndPoints.NOTIFICATIONS}`),

    //EVALUATIONS
    evaluations: new ApiEvaluations(apiWrapper, `${EndPoints.API}${EndPoints.EVALUATIONS}`),

    //POSTS
    posts: new ApiPosts(apiWrapper, `${EndPoints.API}${EndPoints.POSTS}`),

    //SIMULATORS
    simulators: new ApiSimulators(apiWrapper, `${EndPoints.API}${EndPoints.SIMULATORS}`),

    //WEBSITE POSTS
    blogPages: new ApiBlogPages(apiWrapper, `${EndPoints.API}${EndPoints.POSTS}`),

    dashboardStudents: new ApiDashboardStudent(apiWrapper, `${EndPoints.API}${EndPoints.DASHBOARD_STUDENT}`),
    dashboardInstructors: new ApiDashboardInstructor(apiWrapper, `${EndPoints.API}${EndPoints.DASHBOARD_INSTRUCTOR}`),
    dashboardMain: new ApiDashboard(apiWrapper, `${EndPoints.API}${EndPoints.DASHBOARD_MAIN}`),
    
};
