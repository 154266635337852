<script>
import HomeStudentDashboardComponent from '../../features/dashboards/HomeStudentDashboardComponent';
import ModalStudentInfoView from '../../../account/views/ModalStudentInfoView';
import ModalStudentSimulatorView from '../../../account/views/ModalStudentSimulatorView';

export default {
  extends: HomeStudentDashboardComponent,
  name: 'HomeStudentDashboardView',
  components: {
    'modal-student-info-view': ModalStudentInfoView,
    'modal-student-simulator-view': ModalStudentSimulatorView,
  },
};
</script>

<template>
  <div class="container-fluid">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="13" :md="13" :lg="9" :xl="7" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreateSimulator"
            >Crear Simulador</el-button
          >
        </el-button-group>
      </el-col>
    </el-row>
    <br />
    <div class="row">
      <div class="col-lg-12">
        <h5 class="st_title">Estad&iacute;sticas De Tus Cursos</h5>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Favoritos</h5>
            <h2>{{ currentStats.totalFavorite }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Te Gustan</h5>
            <h2>{{ currentStats.totalLiked }}</h2>
            <!-- span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos No Te Gustan</h5>
            <h2>{{ currentStats.totalDisliked }}</h2>
            <!-- <span class="crdbg_4">New 3</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Suscripci&oacute;n Finaliza:</h5>
            <h2 v-if="currentStats.endDateSubscription">
              {{ currentStats.endDateSubscription | formatDate }}
            </h2>
            <h2 v-else>Sin Inscripci&oacute;n</h2>
            <!-- <span class="crdbg_4">New 3</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/membership/icon-1.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="section3125 mt-50">
          <h4 class="item_title">Cursos Activos</h4>
          <div class="la5lo1">
            <div class="owl-carousel featured_courses owl-theme">
              <div
                class="item"
                v-for="course in currentCourses"
                :key="course.id"
              >
                <div class="fcrse_1 mb-20">
                  <router-link
                    :to="'/estudiante/cursos/' + course.id + '/detalles'"
                    class="fcrse_img"
                  >
                    <img :src="getCoverImage(course.coverImageUrl)" alt="" />
                    <div class="course-overlay">
                      <!-- <div class="badge_seller">Más Visto</div>-->
                      <!-- <div class="crse_reviews">
							<i class="uil uil-star"></i>4.5
							</div> -->
                      <!-- span class="play_btn1"><i class="uil uil-play"></i></span>-->
                      <div class="crse_timer">
                        {{ course.totalHours }} Horas
                      </div>
                    </div>
                  </router-link>
                  <div class="fcrse_content">
                    <!-- <div class="eps_dots more_dropdown">
							<a href="#"><i class="uil uil-ellipsis-v"></i></a>
							<div class="dropdown-content">
							<span><i class="uil uil-share-alt"></i>Compartir</span>
							<span @click="onDoLike(course)"><i :class="getSocialStatusClass(course.isLiked)"></i>Me Gusta</span>
							</div>
						</div>-->
                    <div class="vdtodt">
                      <span class="vdt14"
                        >{{ course.totalViewed }} vista(s)</span
                      >
                      <span class="vdt14">{{ course.lastView }}</span>
                    </div>
                    <router-link
                      :to="'/estudiante/cursos/' + course.id + '/detalles'"
                      class="crse14s"
                      >{{ course.title }}</router-link
                    >
                    <span class="crse-cate">{{ mapTags(course.tags) }}</span>
                    <div class="auth1lnkprce">
                      <p class="cr1fot" v-if="course.author">
                        Por <a href="#">{{ course.author }}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="section3125 mt-50">
          <h4 class="item_title">Cursos Finalizados</h4>
          <div class="la5lo1">
            <div class="owl-carousel finished_courses owl-theme">
              <div
                class="item"
                v-for="course in finishedCourses"
                :key="course.id"
              >
                <div class="fcrse_1 mb-20">
                  <router-link
                    :to="'/estudiante/cursos/' + course.id + '/detalles'"
                    class="fcrse_img"
                  >
                    <img :src="getCoverImage(course.coverImageUrl)" alt="" />
                    <div class="course-overlay">
                      <!-- <div class="badge_seller">Más Visto</div>-->
                      <!-- <div class="crse_reviews">
							<i class="uil uil-star"></i>4.5
							</div> -->
                      <!-- <span class="play_btn1"><i class="uil uil-play"></i></span> -->
                      <div class="crse_timer">
                        {{ course.totalHours }} Horas
                      </div>
                    </div>
                  </router-link>
                  <div class="fcrse_content">
                    <!-- <div class="eps_dots more_dropdown">
							<a href="#"><i class="uil uil-ellipsis-v"></i></a>
							<div class="dropdown-content">
							<span><i class="uil uil-share-alt"></i>Compartir</span>
							<span @click="onDoLike(course)"><i :class="getSocialStatusClass(course.isLiked)"></i>Me Gusta</span>
							</div>
						</div> -->
                    <div class="vdtodt">
                      <span class="vdt14"
                        >{{ course.totalViewed }} vista(s)</span
                      >
                      <span class="vdt14">{{ course.lastView }}</span>
                    </div>
                    <router-link
                      :to="'/estudiante/cursos/' + course.id + '/detalles'"
                      class="crse14s"
                      >{{ course.title }}</router-link
                    >
                    <span class="crse-cate">{{ mapTags(course.tags) }}</span>
                    <div class="auth1lnkprce">
                      <p class="cr1fot" v-if="course.author">
                        Por <a href="#">{{ course.author }}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-student-info-view
      :formData="studentInfoItem"
      :conf="modalSubscriptionInfo"
      v-if="modalSubscriptionInfo.isVisible"
    ></modal-student-info-view>
    <modal-student-simulator-view
      :formData="studentSimulatorItem"
      :conf="modalSimulatorInfo"
      v-if="modalSimulatorInfo.isVisible"
    ></modal-student-simulator-view>
  </div>
</template>
