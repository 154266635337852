import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';

import SimulatorOfficialDetailViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialDetailViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            viewModel: null,
            currentTimer: null,
            timerLabel: null,
            sectionSelected: null,
            simulator: null,
            isFinished: false,
            isLoading: true,
            isTimerLoading: false,
        };
    },
    mounted() {
        // this.$root.$on('onSubmitedSimulator', this.onSubmitSimulator)
        EventBus.$on('onFinishSection', this.onFinishSection);
    },
    beforeDestroy() {
        // this.$root.$off('onSubmitedSimulator', this.onSubmitSimulator);
        EventBus.$off('onFinishSection', this.onFinishSection);
    },
    created() {
        this.viewModel = new SimulatorOfficialDetailViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.viewModel.onBindFormView(this.$route.params.id)
        },
        onBindFormViewResponse(response) {
            setTimeout(() => {
                this.simulator = response.data;
            }, 400);

            setTimeout(() => {
                this.isLoading = false
            }, 400);
        },
        onSelectSection(section) {
            if (this.viewModel.isSectionSelectable(this.simulator.isTimer, this.currentTimer)) {
                this.isLoading = true
                setTimeout(() => {
                    this.sectionSelected = section;
                }, 400);

                setTimeout(() => {
                    this.isLoading = false
                }, 450);
            } else {
                this.$notify({
                    title: 'Advertencia',
                    message: 'Debe iniciar el simulador para poder seleccionar una sección',
                    type: 'warning'
                });
            }
        },
        onSubmitSimulator(data) {

        },
        onStartTimer() {
            this.isTimerLoading = true

            setTimeout(() => {
                this.currentTimer = this.viewModel.initTimer({
                    startValues: { minutes: this.viewModel.getTime(this.simulator) },
                    onStart: this.onTimerStart,
                    onUpdate: this.onTimerUpdate,
                    onFinished: this.onTimerFinished
                });
                this.isTimerLoading = false
            }, 600);
        },
        onTimerStart(data) {
            this.timerLabel = data
        },
        onTimerUpdate(data) {
            this.timerLabel = data
        },
        onTimerFinished() {
            this.timerLabel = null
            this.isFinished = true
            this.sectionSelected = null
        },
        onFinishSection(data) {
            this.isLoading = true
            setTimeout(() => {
                this.sectionSelected = data
            }, 400);

            setTimeout(() => {
                this.isLoading = false
            }, 450);
        },
        onFinishSimulator() {
            this.$confirm(`Revisa tus respuestas, puede que falte alguna. Una vez que veas los resultados, no podrás comenzar nuevamente. ¿Deseas continuar?`, 'Alerta', {
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'No',
                type: 'warning',
                closeOnPressEscape: false,
                closeOnClickModal: false,
                confirmButtonClass: 'btn-primary',
                cancelButtonClass: 'btn-cancel',
                showCancelButton: true,
            }).then(() => {
                if (this.currentTimer) {
                    this.currentTimer.stop();
                }
                this.timerLabel = null
                this.isFinished = true
                this.sectionSelected = null
            }).catch((error) => {

            });

        }
    },
};