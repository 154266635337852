export default class Course {
  constructor(options) {
    Object.assign(this, options)
  }

  toSend() {
    return {
      id: this.id,
      code: this.code,
      title: this.title,
      description: this.description,
      about: this.about,
      promotionalVideoUrl: this.promotionalVideoUrl,
      coverImageUrl: this.coverImageUrl,
      isPublished: this.isPublished,
      tags: this.tags,
      courseCategory: (this.courseCategory) ? this.courseCategory.id : null,
      universityId: this.university.id,
      courseSubjects: this.mapCourseSubjects(this.courseSubjects),
      instructors: this.instructors,
    }
  }

  mapCourseSubjects(collection) {
    return _.map(collection, (item) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        sort: item.sort,
        updateState: item.updateState,
        courseSubjectActivities: this.mapCourseSubjectActivities(item.courseSubjectActivities)
      }
    })
  }

  mapCourseSubjectActivities(collection) {
    return _.map(collection, (item) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        sort: item.sort,
        duration: item.duration || 0,
        hasFile: item.hasFile,
        hasVideo: item.hasVideo,
        isPublic: item.isPublic,
        hasOpenResponse: item.hasOpenResponse,
        hasMultipleResponse: item.hasMultipleResponse,
        isDownload: item.isDownload,
        videoUrl: item.videoUrl,
        file: item.file,
        fileUrl: item.fileUrl,
        timerType: item.timerType,
        isRequired: item.isRequired,
        isTimer: item.isTimer,
        courseSubjectActivityType: item.courseSubjectActivityType,
        updateState: item.updateState,
        courseSubjectActivityAnswers: this.mapCourseSubjectActivityAnswers(item.courseSubjectActivityAnswers),
        courseSubjectTestActivities: this.mapCourseSubjectTestActivities(item.courseSubjectTestActivities)
      }
    })
  }

  mapCourseSubjectActivityAnswers(collection) {
    return _.map(collection, (item) => {
      var current = {
        id: item.id,
        title: item.title,
        success: item.success,
        updateState: item.updateState,
      }
      if (item.id) current.id = item.id;
      return current;
    })
  }

  mapCourseSubjectTestActivities(collection) {
    return _.map(collection, (item) => {
      return {
        id: item.id,
        instruction: item.instruction,
        description: item.description,
        duration: item.duration || 0,
        fileUrl: item.fileUrl,
        updateState: item.updateState,
        courseSubjectTestQuestions: this.mapCourseSubjectTestQuestions(item.courseSubjectTestQuestions)
      }
    });
  }

  mapCourseSubjectTestQuestions(collection) {
    return _.map(collection, (item) => {
      var current = {
        description: item.description,
        sort: item.sort || 0,
        duration: item.duration || 0,
        updateState: item.updateState,
        courseSubjectTestQuestionAnswers: this.mapCourseSubjectTestQuestionAnswers(item.courseSubjectTestQuestionAnswers)
      };
      if (item.id) current.id = item.id;
      return current;
    })
  }

  mapCourseSubjectTestQuestionAnswers(collection) {
    return _.map(collection, (item) => {
      var current = { description: item.description, success: item.success, updateState: item.updateState };
      if (item.id) current.id = item.id;
      return current;
    })
  }

  
  /**
   * Metodo para eliminar un tema del curso actual
   * @param {*} index 
   */
  removeCourseSubject(index) {
    this.getNoRemovedCourseSubjects()[index].updateState = 4;
  }

  /**
   * Metodo para actualizar el posicionamiento de la lista de temas del curso actual
   */
  updateSortPositionCourseSubjects() {
    _.each(this.getNoRemovedCourseSubjects(), (item, index) => {
      item.sort = index + 1;
      item.updateState = (item.updateState == 2) ? item.updateState : 3;
    })
  }


  /**
   * Metodo para obtener el siguiente indice de la lista de temas del curso actual
   * 
   */
  getNextCourseSubjectIndex() {
    return _.size(this.getNoRemovedCourseSubjects()) + 1;
  }


  /**
   * Metodo para obtener los temas no eliminados del curso actual
   */
  getNoRemovedCourseSubjects() {
    return this.courseSubjects.filter((item) => item.updateState !== 4);
  }

  /**
   * Metodo para obtener los temas no eliminados del curso actual de forma ordenada por el campo sort
   */
  getNoRemovedCourseSubjectsOrdered() {
    return _.sortBy(this.getNoRemovedCourseSubjects(), 'sort');
  }

}
