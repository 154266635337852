export default class CourseSubject {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
        }
    }
}
