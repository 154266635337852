import _ from 'lodash'
import QuillEditorHelper from '../../../shared/helpers/QuillEditorHelper'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import SimulatorQuestion from '../models/SimulatorQuestion'

export default class SimulatorQuestionViewModel extends BaseViewModel {
	constructor(view) {
		super(view)
	}
	// INICIALIZADORES

	static UPDATE_STATES = {
		ADD: 2,
		EDIT: 3,
		REMOVE: 4,
	}

	//#region CRUD

	onCreate() {
		let item = new SimulatorQuestion({
			state: "create",
			code: "PS-" + (this.view.collection.length + 1),
			simulatorAnswers: this.initDefaultAnswers(),
			status: 1
		});
		return item;
	}

	onDelete(data) {
		let item = new SimulatorQuestion(data);
		item.state = "delete";
		return item;
	}

	onEdit(data) {
		let item = new SimulatorQuestion(data);
		item.state = "update";
		return item;
	}

	createQuestion(sections) {
		var item = new SimulatorQuestion({
			state: 'create',
			sort: _.size(sections) + 1,
			simulatorAnswers: this.initDefaultAnswers(),
		});

		return item;
	}

	onSearchCollection() {
		return this.api.simulatorQuestions.find(this.getSearchQuery())
	}

	mapCollection(collection) {
		return _.map(collection, (data) => new SimulatorQuestion(data));
	}

	onSearchItem(id) {
		return this.api.simulatorQuestions.findOne(id)
	}

	onSave(data) {
		if (data.state === 'create')
			return this.api.simulatorQuestions.create(data.toSend(this.view.answerEditors))
		else
			return this.api.simulatorQuestions.update(data.id, data.toSend(this.view.answerEditors));
	}

	onDestroy(context, id) {
		context.api.simulatorQuestions.delete(id)
			.then(response => context.view.onSuccess(response.data))
			.catch(context.view.onError)
	}

	onCancel(context) {
		context.view.item = null;
	}

	import(file) {
		this.api.simulatorQuestions.import(file)
			.then(response => this.view.onImportResponse(response.data))
			.catch(this.view.onError)
	}

	exportToExcel() {
		this.api.simulatorQuestions.exportToExcel()
			.then(response => this.view.onExportResponse(response.data))
			.catch(this.view.onError)
	}

	// #endregion

	// #region BINDING DATA
	bindQuestionFormView() {
		this.api.simulatorExamnTypes.find()
			.then((response) => {
				this.view.onFindSimulatorExamTypesResponse(response.data.data)
				this.bindDescriptionEditor('.description-editor');

				if (this.view.formData.state === 'update') {
					this.view.formData.type = this.getSimulatorType(response.data.data, this.view.formData.type)
					this.view.formData.difficultyLevel = this.getDifficultyLevel(this.view.difficultyLevels, this.view.formData.difficultyLevel)

					if (!_.isEmpty(this.view.formData.courseCategory)) {
						this.view.courseCategories = [this.view.formData.courseCategory]
					}

					if (!_.isEmpty(this.view.formData.university)) {
						this.view.universities = [this.view.formData.university]
					}

					if (!_.isEmpty(this.view.formData.career)) {
						this.view.careers = [this.view.formData.career]
					}
				}
				this.view.onBindFormViewResponse();
			})
			.catch(this.view.onError)
	}

	bindDescriptionEditor(selector) {
		this.view.editorDescription = QuillEditorHelper.buildEditor(selector);
		this.view.editorDescription.root.innerHTML = this.view.formData.description || "";
	}

	bindAnswerEditors(answers) {
		if (!_.isEmpty(answers)) {
			setTimeout(() => {
				_.forEach(answers, (answer, index) => {
					var currentAnswer = {
						selector: `answer_editor_${index}`,
						component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
						updateState: answer.updateState
					};
					currentAnswer.component.root.innerHTML = answer.description;
					this.view.answerEditors.push(currentAnswer);
				});
			}, 300);
		}
		this.view.conf.isLoading = false;
	}

	filterRemovedAnswers(collection) {
		return _.filter(collection, (item) => {
			return item.updateState != SimulatorQuestionViewModel.UPDATE_STATES.REMOVE
		})
	}

	initDefaultAnswers() {
		return [
			{ description: null, success: false, updateState: SimulatorQuestionViewModel.UPDATE_STATES.ADD },
			{ description: null, success: false, updateState: SimulatorQuestionViewModel.UPDATE_STATES.ADD },
			{ description: null, success: false, updateState: SimulatorQuestionViewModel.UPDATE_STATES.ADD },
			{ description: null, success: false, updateState: SimulatorQuestionViewModel.UPDATE_STATES.ADD }
		]
	}

	// #endregion

	// #region QUERIES
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findCourseCategories(data) {
		this.api.courseCategories.find(data)
			.then((response) => {
				this.view.onFindCourseCategoriesResponse(response.data.data)
			})
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar categorias de cursos
	 * @param {String} data Query string para parametros de búsqueda
	 */
	findSimulatorExamTypes(data) {
		this.api.simulatorExamnTypes.find(data)
			.then((response) => this.view.onFindSimulatorExamTypesResponse(response.data.data))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar dificultades
	 * @param {String} data Query string para parametros de búsqueda
	 */
	 findDifficultyLevel(data) {
		this.api.difficultyLevels.find(data)
			.then((response) => this.view.onFindDifficultyLevelsResponse(response.data))
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar Universidades
	 * @param {String} data Query string para parametros de búsqueda
	 */
	 findUniversities(data) {
		this.api.universities.find(data)
			.then((response) => {
				this.view.onFindUniversitiesResponse(response.data.data)
			})
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar Carreras
	 * @param {String} data Query string para parametros de búsqueda
	 */
	 findCareers(data) {
		this.api.careers.find(data)
			.then((response) => {
				this.view.onFindCareersResponse(response.data.data)
			})
			.catch(this.view.onError)
	}
	/**
	 * Metodo para buscar tipo de simulador
	 */
	getSimulatorType(data, type) {
		return _.find(data, function (o) {
			return o.id == type;
		});
	}

	/**
	 * Metodo para buscar tipo de simulador
	 */
	 getDifficultyLevel(data, type) {
		return _.find(data, function (o) {
			return o.id == type;
		});
	}


	// #endregion

	//#region MAPPING DATA
	addNewAnswer(answers) {

		answers.push({
			success: false,
			description: '',
			updateState: SimulatorQuestionViewModel.UPDATE_STATES.ADD,
		});

		setTimeout(() => {
			var nextIndex = _.size(answers) - 1;
			this.view.answerEditors.push({
				selector: `answer_editor_${nextIndex}`,
				component: QuillEditorHelper.buildEditor(`.answer_editor_${nextIndex}`)
			});
		}, 300);

	}

	removeAnswer(simulatorAnswers, answerEditors, index) {
		
		this.filterRemovedAnswers(simulatorAnswers)[index].updateState = SimulatorQuestionViewModel.UPDATE_STATES.REMOVE;
		this.filterRemovedAnswers(answerEditors)[index].updateState = SimulatorQuestionViewModel.UPDATE_STATES.REMOVE;
		this.view.$forceUpdate();
	}

	updateAnswer(answers) {
		answers.updateState = (answers.id) ?  SimulatorQuestionViewModel.UPDATE_STATES.EDIT : SimulatorQuestionViewModel.UPDATE_STATES.ADD;
	}

	updateAnswerEditor(answer, editors, index) {
		answers.updateState = (answers.id) ?  SimulatorQuestionViewModel.UPDATE_STATES.EDIT : SimulatorQuestionViewModel.UPDATE_STATES.ADD;
		let answerEditor = _.find(editors, {selector: `answer_editor_${index}`});
        answer.description = (answerEditor) ? answerEditor.component.root.innerHTML : '';
	}

	removeAnswerEditor(answerEditors, index) {
		// answerEditors[index].updateState = SimulatorQuestionViewModel.UPDATE_STATES.REMOVE;
		answerEditors.splice(index, 1);
		_.forEach(answerEditors, (answerEditor, index) => {
			answerEditor.selector = `answer_editor_${index}`;
		})
	}

	renderAnswerEditors(answers, editors) {
		
		if(!_.isEmpty(editors)) { 
			this.clearAnswerEditorsView(answers, editors)
		}

		let answerEditors = [];

		if(!_.isEmpty(answers)){
			_.forEach(answers, (answer, index) => {
				let currentAnswer = {
					selector: `answer_editor_${index}`,
					component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
					updateState: answer.updateState
				};
				currentAnswer.component.root.innerHTML = answer.description;
				answerEditors.push(currentAnswer);
			});
		}

		return answerEditors;
	}

	clearAnswerEditorsView(answers, editors) {
		_.forEach(answers, (answer, index) => {
			let answerEditor = _.find(editors, {selector: `answer_editor_${index}`});
			answer.description = (answerEditor) ? answerEditor.component.root.innerHTML : '';
		})
		
		let answerEditors = document.querySelectorAll('[class^="answer_editor"]');
		let qlToolbars = document.querySelectorAll('.el-table__row .ql-toolbar');
		let qlContainers = document.querySelectorAll('[class^="answer_editor"] .ql-container');
		_.forEach(answerEditors, (answerEditor, index) => answerEditor.remove());
		_.forEach(qlToolbars, (toolBar, index) => toolBar.remove());
		_.forEach(qlContainers, (container, index) => container.remove());
	}

	// #endregion

	// #region VALIDATIONS

	// #endregion
}
