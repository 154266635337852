import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import SimulatorViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialViewModel';


export default {
    extends: ListComponent,
    data() {
        return {
            modulePermissions: 'SimulatorContent-Simulators',
            item: null,
            searchName: null,
            searchCode: null,
            viewModel: null,
            courseCategoryFilter: '',
            courseCategories: [],
            limit: 12,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR CURSOS',
                onUploadImport: 'onUploadImportSimulator',
                onCancelImport: 'onCancelImportSimulator'
            },
            studentInfoItem: null,
            modalSubscriptionInfo: {
                isVisible: false,
                isLoading: false,
            },
        }
    },
    mounted() {
        EventBus.$on('onSubmitedSimulator', this.onSearch);
        EventBus.$on('onUploadImportSimulator', this.onUploadImport);
        EventBus.$on('onCancelImportSimulator', this.onCancelImport);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSimulator', this.onSearch);
        EventBus.$off('onUploadImportSimulator', this.onUploadImport);
        EventBus.$off('onCancelImportSimulator', this.onCancelImport);
    },
    created() {
        this.viewModel = new SimulatorViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.onSearch();
            this.$root.$on('update-session', this.onUpdateSession);
        },
        onSearch() {
            this.viewModel.findPublished();
        },
        onAllFilterChange(data, field) {
            this.onFilterChange(data, field);
        },
        onCanCreate() {
            return this.canCreate(this.modulePermissions)
        },
        onCanRead() {
            return this.canRead(this.modulePermissions)
        },
        onCanUpdate() {
            return this.canUpdate(this.modulePermissions)
        },
        onCanDelete() {
            return this.canDelete(this.modulePermissions)
        },
        getCoverImage(url) {
            return (url) ? url : '/images/courses/add_img.jpg'
        },
        onUpdateSession(data) {
            if (data.user.hasSubscriptions && !data.user.hasSubscriptionInfo) {
                this.studentInfoItem = this.viewModel.onCreateStudentInfo()
                this.viewModel.showModal(this.modalSubscriptionInfo, true)
            }
        },
        isCollectionEmpty(collection) {
            return _.isEmpty(collection)
        }
    },
    computed: {}
}