import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import CourseViewModel from '../../../../core/features/course/viewmodels/CourseViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'CourseContent-Courses',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      authors: [],
      courseCategories: [],
      publishedStatuses: [],
      filterOptions: {
        authorFilter: '',
        codeFilter: '',
        courseCategoryFilter: '',
        isPublishedFilter: '',
        titleFilter: '',
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR CURSOS',
        onUploadImport: 'onUploadImportCourse',
        onCancelImport: 'onCancelImportCourse'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedCourse', this.onSearch);
    EventBus.$on('onUploadImportCourse', this.onUploadImport);
    EventBus.$on('onCancelImportCourse', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedCourse', this.onSearch);
    EventBus.$off('onUploadImportCourse', this.onUploadImport);
    EventBus.$off('onCancelImportCourse', this.onCancelImport);
  },
  created() {
    this.viewModel = new CourseViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onSearchPublishedStatus();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchAuthor(data) {
      if (data) {
        this.viewModel.findAuthors(`FullName=${data}&Role=profesor`)
    } else {
        this.authors = []
    }
    },
    onSearchAuthorsResponse(response) {
      console.log(response)
      this.authors = response;
    },
    onFocusAuthor() {
      this.$forceUpdate()
    },
    onSearchCourseCategory(data) {
      if (data) {
        this.viewModel.findCourseCategories(`Name=${data}`)
    } else {
        this.courseCategories = []
    }
    },
    onSearchCourseCategoriesResponse(response) {
      console.log(response)
      this.courseCategories = response;
    },
    onFocusCourseCategory() {
      this.$forceUpdate()
    },
    onSearchPublishedStatus() {
      this.viewModel.findPublishedStatuses()
    },
    onSearchPublishedStatusesResponse(response) {
      this.publishedStatuses = response;
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    getCoverImage(url) {
      return (url) ? url : '/images/courses/add_img.jpg'
    },
    onDoPublish(course) {
      this.viewModel.doPublish({id: course.id, isPublished: course.isPublished })
    },
    onDoPublishResponse(response) {
      this.onSearch()
    }
  },
  computed: {}
}