export default class SimulatorOfficialSection {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            name: this.name,
            sort: this.sort,
            description: this.description,
            questionQuantity: this.questionQuantity,
            courseCategory: this.courseCategory,
            updateState: this.updateState,
            simulatorQuestions: this.mapSimulatorQuestions(this.simulatorQuestions)
        }
    }

    mapSimulatorQuestions(questions){
        return _.map(questions, (question) => {
            return {
                id: question.id,
                code: question.code,
                name: question.name,
                courseCategory: question.courseCategory,
                type: question.type,
                difficultyLevel: question.difficultyLevel,
                description: question.description,
                sort: question.sort || 0,
            };
        });
    }
}