import BaseComponent from '../../../core/infraestructure/BaseComponent'
import AuthViewModel from '../../../core/features/authentication/viewmodels/AuthViewModel.js'

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            formLogin: {
                Username: '',
                Password: ''
            },
            rules: {
                username: [{ required: true, message: 'Usuario o Correo Requerido.' }],
                password: [{ required: true, message: 'Contraseña Requerida..' }]
            }
        };
    },
    created() {
        this.viewModel = new AuthViewModel(this);
    },
    mounted() { },
    methods: {
        onLogin(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.viewModel.showFormLoading(true, 'Autenticando usuario...')
                    this.viewModel.login(this.formLogin)
                    return true;
                }
                return false;
            });
        },
        onLoginResponse(response) {
            this.viewModel.showFormLoading(false, '')
            if (response.isAuthenticated) {
                this.viewModel.showNotification('success', 'Autenticación', response.message)
                this.viewModel.setSession(response.accessToken)
                this.viewModel.profile();
            } else {
                this.viewModel.showNotification('warning', 'Advertencia', response.message)
            }
        },
        onFindProfileResponse(response) {
            this.viewModel.setProfilePermissions(response.profile)
            switch(response.profile.role.name.toLowerCase()) {
                case 'root':
                case 'administrador':
                    this.$router.push('/admin/resumen');
                  break;
                case 'profesor':
                    this.$router.push('/profesor/resumen');
                  break;
                case 'estudiante':
                    this.$router.push('/estudiante/resumen');
                    break;
              }
        },
        onLoginError(response){
            this.viewModel.showFormLoading(false, '')
            this.onError(response);
        }
    }
};