<script>
import ModalSimulatorOfficialComponent from '../../features/modals/ModalSimulatorOfficialComponent';
import ModalSimulatorOfficialSectionView from './ModalSimulatorOfficialSectionView';
import draggable from 'vuedraggable';

export default {
  extends: ModalSimulatorOfficialComponent,
  name: 'ModalSimulatorOfficialOfficialView',
  components: {
    'modal-simulator-official-section-view': ModalSimulatorOfficialSectionView,
    draggable,
  },
};
</script>

<template>
  <div>
    <el-dialog
      title="Simulador"
      :visible.sync="conf.isVisible"
      width="50%"
      custom-class="simulator-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Información de Simulador</legend>

          <el-row class="row-bg" :gutter="20">
            <div class="view_all_dt">
              <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                <div class="view__img">
                  <img :src="coverImageUrl" alt="" />
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="14" :lg="14" :xl="14">
                <div class="view_info_right">
                  <h4>Imagen de Portada</h4>
                  <p>
                    Subir imagen del simulador aquí. Debes conocer nuestro
                    estandar de calidad para las imagenes de portada de
                    simuladores aceptadas. Formato Importante: 750x422 pixeles;
                    .jpg, .jpeg,. gif, or .png. imagen sin texto.
                  </p>
                  <div class="upload__input">
                    <div class="input-group">
                      <div class="custom-file">
                        <el-form-item prop="coverImage">
                          <el-upload
                            class="file-uploader"
                            :name="'coverImageFileUploader'"
                            v-if="!isCoverImageTemp && !formData.coverImageUrl"
                            action
                            :on-success="onSuccessUploadCoverImage"
                            :before-upload="onBeforeUploadCoverImage"
                            :show-file-list="false"
                            :data="coverImageFile"
                          >
                            <el-button
                              size="small"
                              type="primary"
                              class="btn-primary"
                              style="margin-top: 40px;"
                              >Clic para subir archivo</el-button
                            >
                          </el-upload>
                          <p v-if="isCoverImageTemp || formData.coverImageUrl">
                            <br />
                            <el-button
                              size="small"
                              type="primary"
                              class="btn-primary"
                              @click="onRemoveCoverImage"
                              ><i class="el-icon-circle-close"></i>
                              Eliminar</el-button
                            >
                          </p>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </div>
          </el-row>

          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="Nombre" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="Nombre"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Descripción" prop="description">
                <el-input
                  v-model="formData.description"
                  type="textarea"
                  :row="3"
                  maxlength="255"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="12">
              <el-form-item label="Universidad" prop="university">
                <el-select v-model="formData.university" placeholder="Seleccionar"
                @change="onChangeUniversity"
                value-key="id"
                clearable
                filterable
                size="medium">
                  <el-option
                    v-for="item in universities"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Carrera" prop="career">
                <el-select v-model="formData.career" placeholder="Seleccionar"
                value-key="id"
                clearable
                filterable
                size="medium">
                  <el-option
                    v-for="item in careers"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="center">
              <el-form-item label="¿Publicado?">
                <el-switch
                  v-model="formData.isPublic"
                  active-color="#13ce66"
                  active-text="SI"
                  inactive-text="NO"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="center">
              <el-form-item label="¿Es Temporizado?">
                <el-switch
                  v-model="formData.isTimer"
                  active-color="#13ce66"
                  active-text="SI"
                  inactive-text="NO"
                  @change="isTimerChange"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="center">
              <el-form-item label="Tipo de Temporizado" prop="timerType">
                <el-select
                  v-model="formData.timerType"
                  clearable
                  filterable
                  placeholder="Seleccionar"
                  value-key="id"
                  size="small"
                  :disabled="!formData.isTimer"
                  @change="isTimerTypeChange"
                >
                  <el-option
                    v-for="item in timerTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="center">
              <el-form-item label="Duración Total(Min.)" prop="duration">
                <el-input-number
                  v-model="formData.duration"
                  size="small"
                  :disabled="!onAllowDuration()"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="center">
              <el-form-item label="Numero de Preguntas" prop="questionQuantity">
                <el-input-number
                  v-model="formData.questionQuantity"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="center">
              <el-form-item label="Puntaje Maximo" prop="topScore">
                <el-input-number
                  v-model="formData.topScore"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" align="center">
              <el-form-item label="Puntaje Minimo" prop="minimumScore">
                <el-input-number
                  v-model="formData.minimumScore"
                  size="small"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="8" :md="7" :lg="5" :xl="4">
              <el-button
                type="primary"
                size="small"
                class="btn-primary"
                icon="el-icon-circle-plus"
                style="margin-bottom:10px;"
                @click="onAddSection()"
                >Agregar Sección</el-button
              >
            </el-col>
          </el-row>
          <el-row class="row-bg" :gutter="20">
            <div style="overflow-x:auto; overflow-y: auto; height:260px;">
              <div class="divTable redTable">
                <div class="divTableHeading">
                  <div class="divTableRow">
                    <div class="divTableHead center" style="min-width:60px;">
                      ORDEN
                    </div>
                    <div class="divTableHead" style="min-width:300px;">
                      SECCIÓN
                    </div>
                    <div class="divTableHead center" style="min-width:60px;">
                      NO. PREGUNTAS
                    </div>
                    <div class="divTableHead center" style="min-width:60px;">
                      OPCIONES
                    </div>
                  </div>
                </div>
              </div>
              <draggable
                v-model="formData.simulatorSections"
                @change="onChangeDrag(formData)"
                draggable=".item"
              >
                <div
                  v-for="(question,
                  indexQuestion) in sortSimulatorOfficialSections(
                    formData.simulatorSections
                  )"
                  :key="question.id"
                  class="item"
                >
                  <div class="divTable redTable">
                    <div class="divTableBody">
                      <div class="divTableRow">
                        <div
                          class="divTableCell center"
                          style="min-width:60px;"
                        >
                          <p v-html="question.sort"></p>
                        </div>
                        <div class="divTableCell" style="min-width:300px;">
                          <p v-html="question.name"></p>
                        </div>
                        <div class="divTableCell" style="min-width:60px;">
                          <p v-html="question.questionQuantity"></p>
                        </div>
                        <div
                          class="divTableCell center"
                          style="min-width:60px;"
                        >
                          <el-dropdown @command="onChangeItemOptions">
                            <el-button size="small">
                              <i
                                class="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                :command="{
                                  command: 'edit',
                                  item: question,
                                  index: indexQuestion,
                                }"
                                :disabled="
                                  !onCanReadSimulatorOfficial(modulePermissions)
                                "
                              >
                                <i class="el-icon-edit" aria-hidden="true"></i
                                >Mostrar
                              </el-dropdown-item>
                              <el-dropdown-item
                                :command="{
                                  command: 'delete',
                                  item: question,
                                  index: indexQuestion,
                                }"
                                :disabled="
                                  !onCanDeleteSimulatorOfficial(
                                    modulePermissions
                                  )
                                "
                              >
                                <i
                                  class="el-icon-delete"
                                  aria-hidden="true"
                                ></i>
                                Eliminar
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Guardar</el-button
        >
      </span>
    </el-dialog>
    <modal-simulator-official-section-view
      :formData="itemSection"
      :conf="modalItemSection"
      v-if="modalItemSection.isVisible"
    >
    </modal-simulator-official-section-view>
  </div>
</template>
