import QuillEditorHelper from '../../../shared/helpers/QuillEditorHelper';
import FileHelper from '../../../shared/helpers/FileHelper';
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import CourseSubjectTestQuestion from '../models/CourseSubjectTestQuestion'
import CourseSubjectTestActivity from '../models/CourseSubjectTestActivity'

export default class CourseSubjectTestViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    static UPDATE_STATES = {
        ADD: 2,
        EDIT: 3,
        REMOVE: 4,
    }

    bindTestFormView(formData) {
        setTimeout(() => {
            this.api.testTimerTypes.find()
                .then((response) => {
                    this.view.timerTypes = response.data.data;
                    formData.courseSubjectTestActivities = this.bindSections(formData.courseSubjectTestActivities)
                    if (formData.timerType) {
                        formData.timerType = _.find(response.data.data, { id: formData.timerType })
                    } else {
                        formData.timerType = null;
                    }
                    this.bindInstructionEditors(formData.courseSubjectTestActivities);
                    this.bindDescriptionEditors(formData.courseSubjectTestActivities);
                })
                .catch(this.view.onError);
        }, 300);
    }

    removeSection(sections, sectionIndex) {
        sections.splice(sectionIndex, 1);
        this.view.instructionEditors.splice(sectionIndex);
        this.view.descriptionEditors.splice(sectionIndex);
    }

    addNewSection(sections) {

        var section = new CourseSubjectTestActivity({
            courseSubjectTestQuestions: [],
            isSectionFileTemp: false,
            updateState: 2,
            state: 'create'
        });

        sections.push(section)

        setTimeout(() => {
            var nextIndex = _.size(sections) - 1;
            this.view.instructionEditors.push({
                selector: `instruction_editor_${nextIndex}`,
                component: QuillEditorHelper.buildEditor(`.instruction_editor_${nextIndex}`)
            });

            this.view.descriptionEditors.push({
                selector: `description_editor_${nextIndex}`,
                component: QuillEditorHelper.buildEditor(`.description_editor_${nextIndex}`)
            });

        }, 300);
    }

    bindSections(collection) {
        return _.map(collection, (data) => {
            data.updateState = 3;
            return new CourseSubjectTestActivity(data)
        });
    }

    bindInstructionEditors(sections) {
        _.forEach(sections, (section, index) => {
            var currentInstruction = {
                selector: `instruction_editor_${index}`,
                component: QuillEditorHelper.buildEditor(`.instruction_editor_${index}`)
            };
            currentInstruction.component.root.innerHTML = section.instruction || "";
            this.view.instructionEditors.push(currentInstruction);
        });
    }

    bindDescriptionEditors(sections) {
        _.forEach(sections, (section, index) => {
            var currentDescription = {
                selector: `description_editor_${index}`,
                component: QuillEditorHelper.buildEditor(`.description_editor_${index}`)
            };
            currentDescription.component.root.innerHTML = section.description || "";
            this.view.descriptionEditors.push(currentDescription);
        });
    }

    saveQuestion(formData) {
        return formData.toSend(this.view.answerEditors);
    }

    createQuestion(section) {
        var item = new CourseSubjectTestQuestion({
            state: 'create',
            sort: _.size(section.courseSubjectTestQuestions) + 1,
            updateState: CourseSubjectTestViewModel.UPDATE_STATES.ADD,
            courseSubjectTestQuestionAnswers: [{ success: false }, { success: false }, { success: false }, { success: false }],
        });

        return item;
    }

    editQuestion(data) {
        var item = new CourseSubjectTestQuestion(data);
        item.updateState = CourseSubjectTestViewModel.UPDATE_STATES.EDIT;
        item.state = 'update';
        return item;
    }

    updateQuestion(section, questionIndex, item) {
        section.courseSubjectTestQuestions[questionIndex] = item;
    }

    addNewQuestion(section, item) {
        section.courseSubjectTestQuestions.push(item)
    }

    removeQuestion(section, questionIndex) {
        section.courseSubjectTestQuestions.splice(questionIndex, 1);
    }

    bindQuestionFormView(formData) {
        setTimeout(() => {

            this.view.questionEditor = QuillEditorHelper.buildEditor(`.question_editor`);

            this.view.questionEditor.on('text-change', (delta, oldDelta, source) => {
                var text = this.view.questionEditor.getText();
                this.view.$root.$emit('update-question-editor', (_.isEmpty(text)) ? null : text);
            });

            this.view.questionEditor.root.innerHTML = formData.description || "";

            this.view.bindQuestionFormViewResponse()
            /* formData.courseSubjectTestQuestionAnswers.forEach(element => {
                if (element.updateState != 4) element.updateState = 3
            });

            this.bindAnswerEditors(formData.courseSubjectTestQuestionAnswers);*/

        }, 300);
    }

    isDescriptionValid(rule, value, callback) {
        if (!_.isNil(value) || !_.isEmpty(value)) {
            callback();
        } else {
            callback(new Error("Descripción requerido."));
        }
    }

    bindAnswerEditors(answers) {
        if (!_.isEmpty(answers)) {
            setTimeout(() => {
                _.forEach(answers, (answer, index) => {

                    var currentAnswer = {
                        selector: `answer_editor_${index}`,
                        component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
                        updateState: answer.updateState
                    };

                    currentAnswer.component.root.innerHTML = answer.description || "";
                    this.view.answerEditors.push(currentAnswer);
                });
            }, 100);
        }
    }



    convertFileToBase64(file) {
        return FileHelper.toBase64(file);
    }

    removeAnswerEditor(answerEditors, index) {
		answerEditors.splice(index, 1);
		_.forEach(answerEditors, (answerEditor, index) => {
			answerEditor.selector = `answer_editor_${index}`;
		})
	}

    renderAnswerEditors(answers, editors) {
		
		if(!_.isEmpty(editors)) { 
			this.clearAnswerEditorsView(answers, editors)
		}

		let answerEditors = [];

		if(!_.isEmpty(answers)){
			_.forEach(answers, (answer, index) => {
				let currentAnswer = {
					selector: `answer_editor_${index}`,
					component: QuillEditorHelper.buildEditor(`.answer_editor_${index}`),
					updateState: answer.updateState
				};
				currentAnswer.component.root.innerHTML = answer.description || '';
				answerEditors.push(currentAnswer);
			});
		}

		return answerEditors;
	}

	clearAnswerEditorsView(answers, editors) {
		_.forEach(answers, (answer, index) => {
			let answerEditor = _.find(editors, {selector: `answer_editor_${index}`});
			answer.description = (answerEditor) ? answerEditor.component.root.innerHTML : '';
		})
		
		let answerEditors = document.querySelectorAll('[class^="answer_editor"]');
		let qlToolbars = document.querySelectorAll('.el-table__row .ql-toolbar');
		let qlContainers = document.querySelectorAll('[class^="answer_editor"] .ql-container');
		_.forEach(answerEditors, (answerEditor, index) => answerEditor.remove());
		_.forEach(qlToolbars, (toolBar, index) => toolBar.remove());
		_.forEach(qlContainers, (container, index) => container.remove());
	}
}