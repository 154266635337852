import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import SimulatorOfficialViewModel from '../../../../core/features/simulator/viewmodels/SimulatorOfficialViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'Settings-Careers',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      parentFilter: '',
      parents: [],
      difficultyLevels: [],
      filterOptions: {
        difficultyLevelFilter: null,
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR CARRERAS',
        onUploadImport: 'onUploadImportSimulator',
        onCancelImport: 'onCancelImportSimulator',
      },
    };
  },
  mounted() {
    EventBus.$on('onSubmitedSimulator', this.onSearch);
    EventBus.$on('onUploadImportSimulator', this.onUploadImport);
    EventBus.$on('onCancelImportSimulator', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedSimulator', this.onSearch);
    EventBus.$off('onUploadImportSimulator', this.onUploadImport);
    EventBus.$off('onCancelImportSimulator', this.onCancelImport);
  },
  created() {
    this.viewModel = new SimulatorOfficialViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onFindDifficultyLevel();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchCourseCategory(data) {
      if (data) {
        this.viewModel.findCourseCategories(`Name=${data}`)
    } else {
        this.parents = []
    }
    },
    onSearchCourseCategoriesResponse(response) {
      this.parents = response;
    },
    onFindDifficultyLevel() {
      this.viewModel.findDifficultyLevel()
    },
    onFindDifficultyLevelsResponse(response) {
      this.difficultyLevels = response.data;
    },
    onFocus() {
      this.$forceUpdate()
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}