export default {
  TYPES: [
    {
      id: 1,
      name: 'EN EL SITIO'
    },
    {
      id: 2,
      name: 'EN LINEA UN MES'
    },
    {
      id: 3,
      name: 'EN LINEA TRES MES'
    },
    {
      id: 4,
      name: 'EN LINEA SEIS MES'
    }
  ],
  SUBSCRIPTION_PLANS: {
    ON_SITE: 1,
    ON_LINE_ONE_MONTH: 2,
    ON_LINE_THREE_MONTH: 3,
    ON_LINE_SIX_MONTH: 4,
  },
  SUBSCRIPTION_PLANS_LABEL: {
    ON_SITE: 'EN EL SITIO',
    ON_LINE_ONE_MONTH: 'EN LINEA UN MES',
    ON_LINE_THREE_MONTH: 'EN LINEA TRES MES',
    ON_LINE_SIX_MONTH: 'EN LINEA SEIS MES',
  },
}