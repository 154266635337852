export default class CourseSubjectActivity {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend(answerEditors, instructionEditors, descriptionEditors) {
        return {
            id: this.id,
            sort: this.sort,
            hasFile: this.hasFile,
            hasVideo: this.hasVideo,
            isPublic: this.isPublic,
            isDownload: this.isDownload,
            courseSubjectActivityType: (this.courseSubjectActivityType.id) ? this.courseSubjectActivityType.id : this.courseSubjectActivityType,
            title: this.title,
            description: this.description,
            videoUrl: this.videoUrl,
            fileUrl: this.fileUrl,
            hasOpenResponse: this.hasOpenResponse,
            hasMultipleResponse: this.hasMultipleResponse,
            duration: this.duration || 0,
            file: this.file,
            timerType: (this.timerType) ? this.timerType.id : 0,
            isRequired: this.isRequired,
            isTimer: this.isTimer,
            updateState: this.updateState,
            courseSubjectActivityAnswers: this.mapAnswers(answerEditors, this.courseSubjectActivityAnswers),
            courseSubjectTestActivities: this.mapTestActivities(this.courseSubjectTestActivities, instructionEditors, descriptionEditors),
        }
    }

    mapTestActivities(testActivities, instructionEditors, descriptionEditors) {
        return _.map(testActivities, (testActivity, index) => {
            var currentTestActivity = testActivity.toSend();
            var instructionEditor = _.find(instructionEditors, { selector: `instruction_editor_${index}` });
            currentTestActivity.instruction = instructionEditor.component.root.innerHTML;
            var descriptionEditor = _.find(descriptionEditors, { selector: `description_editor_${index}` });
            currentTestActivity.description = descriptionEditor.component.root.innerHTML;
            return currentTestActivity;
        })
    }

    mapAnswers(answerEditors, answers) {
        let toDelete = _.filter(answers, (answer) => answer.updateState == 4 && answer.id);
        let toUpdate = _.filter(answers, (answer) => answer.updateState != 4)

        let mappedToUpdate = _.map(toUpdate, (answer, index) => {
            let answerEditor = _.find(answerEditors, { selector: `answer_editor_${index}` });
            answer.title = (answerEditor) ? answerEditor.component.root.innerHTML : '';
            return answer
        })

        return toDelete.concat(mappedToUpdate)
    }


    bindAnswers() {
        _.forEach(this.courseSubjectActivityAnswers, (answer, index) => (answer.id) ? answer.updateState = 3 : answer.updateState = 2)
    }


    addAnswer() {
        this.courseSubjectActivityAnswers.push({
            description: '',
            success: false,
            updateState: 2
        })
    }

    initDetaultAnswers() {
        this.courseSubjectActivityAnswers = [
            { title: null, updateState: 2 },
            { title: null, updateState: 2 },
            { title: null, updateState: 2 },
            { title: null, updateState: 2 }
        ]
    }

    removeAllAnswers() {
        this.courseSubjectActivityAnswers = []
    }


    removeAnswer(index) {
        this.getAnswers()[index].updateState = 4;
    }

    getAnswers() {
        return this.getFilteredAnswers(this.courseSubjectActivityAnswers);
    }

    getFilteredAnswers(collection) {
        return _.filter(collection, (item) => item.updateState != 4)
    }

}