import BaseComponent from '../../../core/infraestructure/BaseComponent';
import SessionBoxViewModel from '../../../core/features/shared/viewmodels/SessionBoxViewModel';

export default {
    extends: BaseComponent,
    data() {
        return {
            session: null,
            profileImageUrl: '/images/hd_dp.jpg',
        };
    },
    created() {
        this.viewModel = new SessionBoxViewModel(this);
        this.viewModel.profile()
        this.viewModel.sessionHideOnMobile()
        this.viewModel.onBindTemplateUI()
    },
    mounted() { },
    methods: {
        onFindProfileResponse(response) {
            if (response.isAuthenticated) {
                this.session = response.profile;
                this.profileImageUrl = (this.session.user.profileImageUrl) ? this.session.user.profileImageUrl : this.profileImageUrl;
                this.$root.$emit('update-session', response.profile);
            } else {
                this.$nextTick(() => this.$router.push("/"));
            }
        },
        onChangeMenuOptions(command) {
            if (command === "logout") {
                this.viewModel.logout();
            } else {
                this.$router.push(`/${command}`);
            }
        },
        onLogout() {
            this.viewModel.removeSessionToken();
            this.viewModel.logout();
        },
        onLogoutResponse() {
            this.viewModel.removePermissions();
            this.$nextTick(() => this.$router.push("/"));
        }
    },
    computed: {}
}