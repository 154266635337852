<script>
import ModalStudentComponent from '../../features/modals/ModalStudentComponent';
export default {
  extends: ModalStudentComponent,
  name: 'ModalStudentView',
};
</script>

<template>
  <el-dialog
    title="Estudiante"
    :visible.sync="conf.isVisible"
    width="35%"
    custom-class="student-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <el-tabs v-model="activeName" >
        <el-tab-pane label="CUENTA" name="account">
          <fieldset>
            <legend>Información de Cuenta</legend>
            <el-row class="row-bg" :gutter="10">
              <el-col :span="24">
                <div class="img148">
                  <el-upload
                    action
                    :show-file-list="false"
                    :on-success="onSuccessHandlerProfilePicture"
                    :before-upload="onBeforeUploadHandlerProfilePicture">
                    <img v-if="profileImageUrl" :src="profileImageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <!-- <img src="/images/left-imgs/img-1.jpg" alt="" /> -->
                </div>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              <!-- <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Tipo de Estudiante" prop="type">
                  <el-select v-model="formData.type"
                    clearable
                    filterable
                    placeholder="Seleccionar"
                    value-key="id"
                    size="small"
                  >
                    <el-option
                      v-for="item in studentTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Nombre(s)" prop="userProfile.firstName">
                  <el-input v-model="formData.userProfile.firstName" maxlength="70" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Apellido(s)" prop="userProfile.lastName">
                  <el-input v-model="formData.userProfile.lastName" maxlength="70" size="small"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="10" v-if="showStatus">
              <el-col :span="24" align="center">
                <el-form-item label="Estado" prop="status">
                  <el-switch
                    style="display: block"
                    v-model="formData.userProfile.status"
                    active-color="#13ce66"
                    inactive-text="Inactivo"
                    active-text="Activo"
                    :inactive-value="1"
                    :active-value="2"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="10">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Correo Electronico" prop="userProfile.email">
                  <el-input v-model="formData.userProfile.email" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Usuario" prop="userProfile.userName">
                  <el-input v-model="formData.userProfile.userName" size="small"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20" v-if="formData.id">
              <el-col :span="24">
                <el-checkbox v-model="updatePassword">Actualizar Contraseña</el-checkbox>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Contraseña:" prop="userProfile.password">
                  <el-input
                    size="small"
                    type="password"
                    v-model="formData.userProfile.password"
                    placeholder="Contraseña"
                    :disabled="!isPasswordRequired()"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Verificar Contraseña:" prop="userProfile.checkPass">
                  <el-input
                    size="small"
                    type="password"
                    v-model="formData.userProfile.checkPass"
                    placeholder="Verificar Contraseña"
                    :disabled="!isPasswordRequired()"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </fieldset>
        </el-tab-pane>
        <el-tab-pane label="INFORMACIÓN PERSONAL" name="personal">
          <fieldset>
            <legend>Información de Estudiante</legend>
            
            
            <el-row class="row-bg" :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Teléfono de Hogar" prop="homePhone">
                  <el-input v-model.number="formData.homePhone" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Teléfono Celular" prop="cellPhone">
                  <el-input v-model.number="formData.cellPhone" size="small"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Página de Facebook" prop="pageUrlFacebook">
                  <el-input v-model="formData.pageUrlFacebook" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item label="Página de Instagram" prop="pageUrlInstagram">
                  <el-input v-model="formData.pageUrlInstagram" size="small"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Dirección" prop="homeAddress">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.homeAddress"
                    maxlength="255"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Acerca de ti" prop="about">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.about"
                    maxlength="255"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </fieldset>
        </el-tab-pane>
        <el-tab-pane label="INFORMACIÓN DE INSCRIPCIÓN" name="academic" v-if="!formData.isStudent">
          <fieldset>
            <legend>Información de Inscripci&oacute;n</legend>
            <el-row class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Universidades a la que desea aplicar" prop="universitiesToApply" class="universities">
                  <el-checkbox-group v-model="universitiesToApply">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-for="(university) in universities" :key="university.id">
                      <el-checkbox :label="university.name" size="small" align="center" @change="onChekedUniversity(university)"></el-checkbox>
                      <el-select :disabled="mapChekedUniversity(university.name)" v-model="careersToApply" clearable @change="onChangeSelectCarrers" value-key="id" size="small">
                        <el-option v-for="item in careers[university.name]" :key="item.id" :label="item.name" :value="item"></el-option>
                      </el-select>
                    </el-col>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            
            <!--
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Universidad a la que desea aplicar" prop="universityToApply">
                  <el-select v-model="formData.universityToApply"
                    allow-create
                    clearable
                    filterable
                    remote
                    :remote-method="onSearchUniversities"
                    @change="onChangeSelectUniversities"
                    placeholder="Seleccionar"
                    value-key="id"
                    size="small"
                  >
                    <el-option
                      v-for="item in universities"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            -->
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Carrera a la que desea ingresar" prop="careerToApply">
                  <el-select v-model="formData.careerToApply"
                    allow-create
                    clearable
                    filterable
                    remote
                    :remote-method="onSearchCareers"
                    @change="onChangeSelectCareers"
                    placeholder="Seleccionar"
                    value-key="id"
                    size="small"
                  >
                    <el-option
                      v-for="item in careers"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="Preparatoria que cursa o cursó" prop="highSchool">
                  <el-select v-model="formData.highSchool"
                    allow-create
                    clearable
                    filterable
                    remote
                    :remote-method="onSearchHighSchools"
                    @change="onChangeSelectHighSchools"
                    placeholder="Seleccionar"
                    value-key="id"
                    size="small"
                  >
                    <el-option
                      v-for="item in highSchools"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="¿Por qué quiere estudiar la carrera seleccionada?" prop="selectedCareerObservation">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.selectedCareerObservation"
                    maxlength="155"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="¿Qué espera de esta asesoría?" prop="advisoryObservation">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.advisoryObservation"
                    maxlength="155"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <el-form-item label="¿Qué materia se le dificulta más entre matemáticas y español? y ¿Por qué?" prop="subjectComplicationObservation">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="formData.subjectComplicationObservation"
                    maxlength="155"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="¿Padece de Alguna Enfermedad o Condición Especial?">
                  <el-switch
                    style="display: block"
                    v-model="formData.hasDiseaseOrSpecialCondition"
                    active-color="#13ce66"
                    inactive-text="No"
                    active-text="Si"
                    :inactive-value="false"
                    :active-value="true"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </fieldset>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
