<script>
import CourseLearnDetailComponent from '../../features/details/CourseLearnDetailComponent';
import HeaderView from '../../../shared/views/HeaderView';
import MainFooterView from '../../../shared/views/MainFooterView';
import EvaluationView from './EvaluationView';

export default {
  extends: CourseLearnDetailComponent,
  components: {
    'header-view': HeaderView,
    'main-footer-view': MainFooterView,
    'evaluation-view': EvaluationView,
  },
  name: 'CourseLearnDetailView',
};

</script>
<template>
  <div>
    <header-view></header-view>
    
    <nav class="vertical_nav course-navigation">
      <div class="left_section menu_left" id="js-menu">
        <div class="left_section">
          <div class="course-content-menu">
            <div class="crse_content" v-if="courseDetail">
              <h2>CONTENIDO DEL CURSO</h2>
              <el-collapse v-model="courseSubjectActive">
                <el-checkbox-group v-model="progressActivities">
                  <el-collapse-item
                    :name="subject.id"
                    v-for="(subject, subjectIndex) in orderCourseSubjects(
                      courseDetail.courseSubjects
                    )"
                    v-bind:key="subjectIndex"
                    class="course-collapse"
                  >
                    <template slot="title">
                      <i class="uil uil-presentation-play crse_icon"></i>
                      <span class="title">
                        {{ subject.title }}
                        <p>{{subject.totalHours}}</p>
                      </span>
                      
                    </template>
                    <div
                      v-for="(activity,
                      activityIndex) in orderCourseSubjectActivities(
                        subject.courseSubjectActivities
                      )"
                      v-bind:key="activityIndex"
                      :class="getActivityStatus(activity)">
                      <el-col :span="2">
                        <el-checkbox :label="activity.id" @change="onDoActivityProgress(activity.id, activityIndex)">
                          <span></span>
                        </el-checkbox>
                          
                      </el-col>
                      <el-col :span="22">
                        <span @click="onSelectActivity(activity)" style="width: 100%; display: inline-block;">
                          <span class="label">{{ activity.title }}</span> <br/>
                          <small><i :class="showIconActivityType(activity)"></i> {{activity.totalHours}}</small>
                        </span>
                      </el-col>
                    </div>
                  </el-collapse-item>
                </el-checkbox-group>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="wrapper course-content">
      <div class="course-content-lecture" v-loading="contentLoader">
        <div v-if="activitySelected && activityTypeSelected === 1">
          <div class="lecture-wapper">
            <!-- Video -->
            <div v-if="activitySelected.hasVideo">
              <div class="course-video-wrapper">
                <iframe
                  v-if="activitySelected.videoUrl"
                  :src="activitySelected.videoUrl"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen>
                </iframe>
              </div>
            </div>

            <!-- FILE -->
            <div v-if="activitySelected.hasFile">
              <div class="container file-lecture-wrapper" v-if="activitySelected.fileUrl && currentPdfViewer">
                <iframe id="file-wrapper-viewer" :src="currentPdfViewer" @load="onBindFileContextMenu"/>
              </div>
            </div>

            <!-- DESCRIPTION -->
            <div class="container answers" v-html="activitySelected.description"></div>
            
            <!-- MUTIPLE CHOICE -->
            <div v-if="activitySelected.hasMultipleResponse">
              <div class="container answers multiple">
                <el-row type="flex" class="row-bg" :gutter="20">
                  <el-col :span="24">
                    <el-checkbox-group v-model="activityAnswers" @change="onChangeAnswer">
                      <el-col :span="24" v-for="answer in activitySelected.courseSubjectActivityAnswers" :key="answer.id" class="choice">
                        <el-checkbox :label="answer.id" size="medium" align="center">
                          <div v-html="answer.title"></div>
                        </el-checkbox>
                      </el-col>
                    </el-checkbox-group>
                  </el-col>
                </el-row>
                <el-row type="flex" class="row-bg wrapper-action" :gutter="20">
                  <el-col :span="24">
                    <el-button
                      type="primary"
                      class="btn-primary"
                      @click="onSendMultipleAnswer"
                      >Enviar Respuesta</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!-- OPEN ANSWER -->
            <div v-if="activitySelected.hasOpenResponse">
              <div class="container answers open">
                <el-row type="flex" class="row-bg" :gutter="20" style="margin-bottom:60px;">
                  <el-col :span="24">
                    <el-input
                        type="textarea"
                        :row="3"
                        v-model="openAnswer"
                        size="small"
                        placeholder="Escriba su respuesta aquí"
                        class="open-answer-editor"
                      ></el-input>
                  </el-col>
                </el-row>
                <el-row type="flex" class="row-bg wrapper-action" :gutter="20">
                  <el-col :span="24">
                    <el-button
                      type="primary"
                      class="btn-primary"
                      @click="onSendOpenAnswer"
                      >Enviar Respuesta</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>

        <!-- TEST -->
         <div class="lecture-wrapper" v-if="activitySelected && activityTypeSelected === 2">
           <div class="container" v-if="testSectionSelected">
             <div class="test-info" v-if="isDoingTest && !showTestResult">
               <el-row>
                <el-col :xs="14" :sm="20" :md="20" :lg="21" :xl="20">
                  <h3 class="test-title" v-html="activitySelected.title"></h3>
                </el-col>
                <el-col :xs="10" :sm="4" :md="4" :lg="3" :xl="4" align="center">
                  <h3 class="test-timer" v-if="timerLabel"><i class="el-icon-timer"></i> {{timerLabel}}</h3>
                </el-col>
              </el-row>
              <h4 class="test-section-title" v-html="testSectionSelected.value.description"></h4>
              <h4 class="test-section-instruction">Instrucciones</h4>
              <div class="test-description" v-html="testSectionSelected.value.instruction"></div>
              
              <div class="container file-lecture-wrapper" v-if="testSectionSelected.value.fileUrl">
                <iframe id="file-wrapper-viewer" :src="getPdfViewer(testSectionSelected.value)" @load="onBindFileContextMenu"/>
              </div>
             </div>

             <div class="test-content" v-if="isDoingTest && !showTestResult && hasQuestions(testSectionSelected.value.courseSubjectTestQuestions)">
               <el-collapse v-model="currentQuestions" class="test-collapse">
                <el-collapse-item accordion :name="question.id" v-for="question in filterQuestionsByAnswers(testSectionSelected.value.courseSubjectTestQuestions)" v-bind:key="question.id">
                  <template slot="title">
                    <div v-html="question.description"></div><i class="header-icon el-icon-information"></i>
                  </template>
                  <el-checkbox-group v-model="question.answers" @change="onChangeTestAnswer" v-if="isCollection(question.answers)">
                    <el-col :span="24" v-for="answer in question.courseSubjectTestQuestionAnswers" :key="answer.id" class="choice">
                      <el-checkbox :label="answer.id" size="medium" align="center">
                        <div v-html="answer.description"></div>
                      </el-checkbox>
                    </el-col>
                  </el-checkbox-group>
                </el-collapse-item>
              </el-collapse>
             </div>

             <div class="test-results" v-if="showTestResult">
               <h2>{{activitySelected.title}} Finalizado</h2>
               <el-row type="flex" class="row-bg test-percent" :gutter="20">
                  <el-col :xs="4" :sm="2" :md="2" :lg="1" :xl="1" align="center">
                    <div v-if="isScoreTestSuccess()"><i class="uil uil-check-circle success"></i></div>
                    <div v-else><i class="uil uil-times-circle fail"></i></div>
                  </el-col>
                  <el-col :xs="20" :sm="22" :md="22" :lg="23" :xl="23">
                    <h3>Calificaci&oacute;n {{getTestScore()}}</h3>
                    <span class="total-success">{{getTestTotalSuccess()}} aciertos</span>
                  </el-col>
                </el-row>
               <el-collapse class="test-collapse">
                <el-collapse-item accordion :name="testActivity.id" v-for="testActivity in activitySelected.courseSubjectTestActivities" v-bind:key="testActivity.id">
                  <template slot="title">
                    <div v-html="testActivity.description"></div><i class="header-icon el-icon-information"></i>
                  </template>
                  <el-row type="flex" class="row-bg" :gutter="20" style="margin-bottom:20px; border: 1px solid #f1f1f1; padding:7px; 10px;" v-for="question in testActivity.courseSubjectTestQuestions" v-bind:key="question.id">
                    <el-col :span="24">
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24"><h4 class="test-result-question"><i class="uil uil-book-open"></i> {{question.description}}</h4></el-col>
                      </el-row>
                      <el-row type="flex" class="row-bg" :gutter="20">
                        <el-col :span="24">
                          <ul style="padding: 5px; 0px;">
                            <li v-for="answer in question.courseSubjectTestQuestionAnswers" v-bind:key="answer.id" :class="getStyleAnswerResponse(answer, question)">
                              <el-row type="flex" class="row-bg" :gutter="20">
                                <el-col :span="1" align="center">
                                  <div v-if="isAnswerResponse(answer) && answer.success && doScore(question)"><i class="uil uil-check-circle"></i></div>
                                  <div v-else-if="isAnswerResponse(answer) && !answer.success && !doScore(question)"><i class="uil uil-times-circle"></i></div>
                                  <div v-else-if="isAnswerResponse(answer) && answer.success && !doScore(question)"><i class="uil uil-times-circle"></i></div>
                                </el-col>
                                <el-col :span="20">
                                  <span v-html="answer.description"></span>
                                  <span v-if="answer.success" class="small">Respuesta Correcta</span>
                                </el-col>
                              </el-row>
                            </li>
                          </ul>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-collapse-item>
              </el-collapse>
             </div>
             <div class="course-content-navigation test" v-if="isDoingTest && !showTestResult && hasQuestions(testSectionSelected.value.courseSubjectTestQuestions)">
                <div class="lecture-nav">
                  <div class="ctrl prev" @click="onPrevTestSection" v-if="testSectionSelected.prev">
                    <i class="uil uil-angle-double-left"></i>
                  </div>
                  <div class="ctrl next" @click="onNextTestSection">
                    <i class="uil uil-angle-double-right"></i>
                  </div>
                </div>
              </div>
           </div>
         </div>
      </div>
      <div class="course-content-navigation">
        <div class="lecture-nav">
          <div class="ctrl prev" @click="onPrevLecture" v-if="currentCourseNode && currentCourseNode.prev">
            <i class="uil uil-angle-left"></i>
          </div>
          <div class="ctrl next" @click="onNextLecture" v-if="currentCourseNode">
            <i class="uil uil-angle-right"></i>
          </div>
        </div>
      </div>
      <div class="course-content-info" v-if="courseDetail">
        <div class="container">
          <el-tabs v-model="tabActive">
            <el-tab-pane label="Información General" name="general-info">
              <h3>{{courseDetail.title}}</h3>
              <span v-html="courseDetail.about"></span>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <main-footer-view></main-footer-view>
      <evaluation-view :formData="evaluationItem" :conf="modalEvaluation" v-if="modalEvaluation.isVisible"></evaluation-view>
    </div>
    <div v-loading.fullscreen.lock="fullLoader"></div>
  </div>
</template>
