export default class SimulatorOfficial {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            isPublic: this.isPublic,
            isTimer: this.isTimer,
            coverImageUrl: this.coverImageUrl,
            timerType: (this.timerType) ? this.timerType.id : null,
            duration: this.duration,
            questionQuantity: this.questionQuantity,
            topScore: this.topScore,
            minimumScore: this.minimumScore,
            careerId: (this.career) ? this.career.id : null,
            difficultyLevel: (this.difficultyLevel) ? this.difficultyLevel.id : null,
            simulatorSections: this.mapSimulatorSections(this.simulatorSections)
        }
    }

    mapSimulatorSections(collection) {
        return _.map(collection, (item) => {
            var current = {
                description: item.description,
                sort: item.sort || 0,
                name: item.name,
                questionQuantity: item.questionQuantity,
                courseCategoryId: item.courseCategory.id,
                updateState: item.updateState,
                simulatorQuestions: this.mapSimulatorQuestions(item.simulatorQuestions)
            };
            if (item.id) current.id = item.id;
            return current;
        })
    }

    mapSimulatorQuestions(questions){
        return _.map(questions, (question) => {
            return {
                id: question.id,
                description: question.description,
                sort: question.sort || 0,
                updateState: question.updateState,
                simulatorQuestionId : question.id
            };
        });
    }
}