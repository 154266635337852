import { EventBus } from '../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../core/infraestructure/ItemComponent';
import SimulatorPublicViewModel from '../../../core/features/simulator/viewmodels/SimulatorPublicViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'SimulatorsContent-Simulators',
            types: [],
            reportDate: '',
            viewModel: null,
            permissions: null,
            modules: [],
            timerTypes: [
                {
                    id: 1,
                    name: 'General'
                },
                {
                    id: 2,
                    name: 'Sección'
                },
                {
                    id: 3,
                    name: 'Por Pregunta'
                }
            ],
            isTestTimeType: false,
            difficultyLevels: [],
            numberSections: [],
            courseCategories: [],
            itemSection: null,
            sectionSelectedIndex: null,
            questionSelectedIndex: null,
            modalItemSection: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                difficultyLevel: [
                    { required: true, message: 'Nivel requerido.', trigger: 'change' }
                ],
                duration: [
                    {
                        validator: this.onDurationValidator,
                    }
                ]
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SimulatorPublicViewModel(this),
        this.onActive();
    },
    mounted() { 
        EventBus.$on('onSubmitedSimulatorSection', this.onSaveSimulatorSection);
    },
    beforeDestroy() {
        EventBus.$off('onSubmitedSimulatorSection', this.onSaveSimulatorSection);
    },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onAddSection() {
            let item = this.viewModel.createSection();
            this.formData.simulatorSections.push(item);
            this.$forceUpdate();
        },
        onFindSimulatorOfficialExamType(data) {
            if (data) {
                this.viewModel.findSimulatorOfficialExamTypes(`Name=${data}`)
            } else {
                this.types = []
            }
        },
        onFindSimulatorOfficialExamTypesResponse(response) {
            this.types = response;
        },
        onSearchCourseCategory(data) {
            if (data) {
                this.viewModel.findCourseCategories(`Name=${data}`)
            } else {
                this.courseCategories = []
            }
        },
        onSearchCourseCategoriesResponse(response) {
            this.courseCategories = response;
          },
        onFocus() {
            this.$forceUpdate()
        },
        isTimerChange(isTimer) {
            if(!isTimer) {
                this.formData.timerType = null;
                this.formData.duration = null;
                this.isTestTimeType = false;
            }
        },
        isTimerTypeChange(type) {
            this.isTestTimeType = false;
            if(type) {
                this.isTestTimeType = (type.id === 3);
            }
            this.formData.duration = null;
        },
        onChangeItemOptions(option) {
            this.sectionSelectedIndex = option.index;
            if(option.command === 'delete') this.onDeleteSection(option.index);
        },
        onDeleteSection(sectionIndex) {
            this.$confirm('¿Desea eliminar el registro seleccionado?', 'Alerta', { confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar', type: 'warning' })
                .then(() => {
                    setTimeout(() => {
                        this.viewModel.deletionSection(sectionIndex, this.formData.simulatorSections);
                        this.$forceUpdate()
                    }, 200);
                })
                .catch(() => {

                });
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (this.viewModel.validateForm(valid)) {
                    this.viewModel.generateTemporalSimulator(this.formData);
                }
            });
        },
        onGenerateTemporalSimulatorResponse(response) {
            setTimeout(() => {
                this.$router.push({ path: `/estudiante/simuladores/${response.simulatorId}/prueba` })
            }, 500);
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onCanReadSimulatorOfficial(permissions) {
            return this.canRead(permissions);
        },
        onCanDeleteSimulatorOfficial(permissions) {
            return this.canDelete(permissions);
        },
        onDurationValidator(rule, value, callback) {
            if(this.formData.isTimer) {
                if (value) {
                    if (value >= 1) {
                        callback();
                    } else {
                        callback(new Error('Valor no válido.'));
                    }
                } else {
                    callback(new Error('Duración requerida.'));
                }
            } else {
                callback();
            }
        },
        onQuestionNumberValidator(rule, value, callback) {
            if (value) {
                if (value >= 1) {
                    callback();
                } else {
                    callback(new Error('Valor no válido.'));
                }
            } else {
                callback(new Error('No. de Preguntas requerido.'));
            }
        }
    }
}