import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import UserViewModel from '../../../../core/features/authorization/viewmodels/UserViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'Authorization-Users',
            updatePassword: false,
            addPermissions: false,
            viewModel: null,
            permissions: null,
            regions: [],
            centersInRegions: [],
            distributionCenters: [],
            roles: [],
            tabInfoActive: 'account',
            rules: {
                userName: [
                    { required: true, message: "Usuario requerido.", trigger: "blur" },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    },
                ],
                employeeCode: [
                    { required: true, message: "No. de empleado requerido.", trigger: "blur" },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-Z]+$/g,
                        message: "Valores permitidos: alfaNumerico",
                        trigger: "change"
                    },
                ],
                email: [
                    { required: true, message: "Correo requerido." },
                    {
                        type: "email",
                        message: "Ingresar email valido",
                        trigger: "blur,change"
                    },
                ],
                role: [
                    { required: true, message: "Selecciona un Role", trigger: "change" }
                ],
                distributionCenter: [
                    { required: true, message: "Selecciona un Centro de Distribución", trigger: "change" }
                ],
                password: [
                    {
                        required: this.isPasswordRequired(),
                        message: "Contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    }
                ],
                checkPass: [
                    {
                        required: this.isPasswordRequired(),
                        message: "Verifiación de contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Solo se aceptan valores alfabetico.",
                        trigger: "change"
                    },
                    { validator: this.validatePass }
                ]
            }
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new UserViewModel(this, 'Users');
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView()
        },
        verifyUsername(rule, value, callback) {
            this.viewModel.verifyUserName(this.formData.id, value, callback);
        },
        verifyUserEmail(rule, value, callback) {
            this.viewModel.verifyUserEmail(this.formData.id, value, callback);
        },
        isPasswordRequired() {
            return this.updatePassword || !this.formData.id;
        },
        onSearchRoles(data) {
            if (data) {
                this.viewModel.findRoles(`Name=${data}`)
            } else {
                this.roles = []
            }
        },
        onSearchRolesResponse(response) {
            this.roles = this.viewModel.mapRoles(response);
        },
        onSearchDistributionCenters(data) {
            if (data != '') {
                this.viewModel.findDistributionCenters(`Name=${data}`)
            } else {
                this.distributionCenters = []
            }
        },
        onSearchDistributionCentersResponse(response) {
            this.distributionCenters = response;
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.onSave(this.formData);
                    return true
                }
                return false
            });
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedUser', null);
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onCanCreate() {
            return this.canCreate(this.modulePermissions)
        },
        onCanUpdate() {
            return this.canUpdate(this.modulePermissions)
        },
        validatePass(rule, value, callback) {
            this.viewModel.verifyConfirmationPassword(value, callback)
        },
        onRegionsChange(regions) {
            this.viewModel.removeRegionsPermissions(regions)
            this.viewModel.setRegionsPermissions(regions)
        },
        onCentersInRegionsChange(data) {
        },
        
    }
};