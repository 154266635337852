import * as signalR from "@microsoft/signalr"
import BaseComponent from '../../../core/infraestructure/BaseComponent';
import NotificationAlertsViewModel from '../../../core/features/shared/viewmodels/NotificationAlertsViewModel';

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            notificationAlerts: [],
            totalAlertsNotifications: 0,
            notificationSelected: null,
        };
    },
    created() {
        this.connectoToSignalRHub();
        this.viewModel = new NotificationAlertsViewModel(this);
        this.onActive();
    },
    mounted() {},
    methods: {
        onActive() {
            this.viewModel.bindNotificationAlerts();
        },
        onFindNotificationAlertsResponse(response){
            this.notificationAlerts = response.data;
            this.totalAlertsNotifications = response.totalAlertsNotifications;
            $('.ui.dropdown').dropdown();
        },
        onSelectNotification(notification) {
            this.notificationSelected = notification;
            this.viewModel.doMarkAsRead(notification);
        },
        onMarkAsReadResponse() {
            if(this.notificationSelected.url){
                this.$router.push(this.notificationSelected.url, () => this.$router.go(0))
            }
        },
        mapImage(sender) {
            return (sender && sender.profileImageUrl) ?  sender.profileImageUrl : '/images/left-imgs/img-1.jpg'
        },
        connectoToSignalRHub() {
            const connection = new signalR.HubConnectionBuilder()
              .withUrl("/notifications-events")
              .configureLogging(signalR.LogLevel.Information)
              .withAutomaticReconnect()
              .build();
            connection.start()
              .then(() => { connection.on("RefreshNotifications", this.onRefreshNotifications); })
              .catch(this.onError);
          },
          onRefreshNotifications(){
            this.viewModel.bindNotificationAlerts();
          }
    }
};
