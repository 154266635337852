import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent'
import InstructorViewModel from '../../../../core/features/instructors/viewmodels/InstructorViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'InstructorsContent-Instructors',
            viewModel: null,
            updatePassword: false,
            permissions: null,
            modules: [],
            activeName: 'account',
            studentTypes: [],
            profileImageUrl: '/images/left-imgs/img-1.jpg',
            profileImageFile: null,
            isProfileImageTemp: false,
            rules: {
                'userProfile.userName': [
                    { required: true, message: "Usuario requerido.", trigger: "blur" },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    },
                ],
                'userProfile.email': [
                    { required: true, message: "Correo requerido." },
                    {
                        type: "email",
                        message: "Ingresar email valido",
                        trigger: "blur,change"
                    },
                ],
                'userProfile.firstName': [
                    { required: true, message: "Nombre(s) requerido(s).", trigger: "blur" }                    
                ],
                'userProfile.lastName': [
                    { required: true, message: "Apellido(s) requerido(s).", trigger: "blur" }
                ],
                'userProfile.password': [
                    {
                        required: this.isPasswordRequired(),
                        message: "Contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Valores permitidos: alfaNumerico @ . - _ ",
                        trigger: "change"
                    }
                ],
                'userProfile.checkPass': [
                    {
                        required: this.isPasswordRequired(),
                        message: "Verifiación de contraseña requerida."
                    },
                    {
                        min: 4,
                        message: "Se requieren más de 4 caracteres",
                        trigger: "blur,change"
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-ZñÑ.@_-]+$/g,
                        message: "Solo se aceptan valores alfabetico.",
                        trigger: "change"
                    },
                    { validator: this.validatePass }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
        showStatus: {
            type: Boolean,
            required: true,
        }
    },
    created() {
        this.viewModel = new InstructorViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedInstructor', null);
        },
        isPasswordRequired() {
            return this.updatePassword || !this.formData.id;
        },
        validatePass(rule, value, callback) {
            this.viewModel.verifyConfirmationPassword(value, callback)
        },
        onSuccessHandlerProfilePicture(data) {

        },
        async onBeforeUploadHandlerProfilePicture(file) {
            let dataUrl = await this.viewModel.convertFileToBase64(file)
            this.profileImageUrl = (dataUrl) ? dataUrl : '/images/left-imgs/img-1.jpg'
            this.profileImageFile = file;
            this.isProfileImageTemp = true;
        }
    }
}